import ClassNames from '@streamloots/classnames';
import trackEventManager from 'utils/event-manager';
import { useEffect } from 'react';
import { useAuthSelectors } from 'services/auth';
import AvatarImage, { ROUNDED_PLACEHOLDER_IMAGE } from 'components/avatar-image';
import { useTranslation } from 'utils/translation';
import Button from 'ui-library/button';
import { useSubscriptionsSelectors } from 'services/subscriptions';
import { usePageSelectors } from 'services/page';
import { SubscriptionStatus } from 'domain/subscription-status';
import theme from './subscription-packs.scss';
import { useSubscriptionPacks } from './useSubscriptionPacks';

const classNames = ClassNames(theme);

export interface SubscriptionPacksAnnouncementProps {
  onClick: (buttonId: string) => void;
}

export const SubscriptionPacksAnnouncement = ({ onClick }: SubscriptionPacksAnnouncementProps): JSX.Element | null => {
  const { subscription } = useSubscriptionsSelectors();
  const {
    page: { slug },
  } = usePageSelectors();
  const { isUserAuthenticated } = useAuthSelectors();
  const freePackInfo = useSubscriptionPacks();
  const { t } = useTranslation('collection');
  const isVisible = freePackInfo && !SubscriptionStatus.isActive(subscription);

  useEffect(() => {
    if (isVisible && isUserAuthenticated) {
      trackEventManager.trackEvent('packs_per_sub_visible', undefined, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isVisible || !freePackInfo) {
    return null;
  }

  const handleClick = () => {
    onClick('packs per sub announcement');
  };

  const { setName, lootImageUrl, quantity } = freePackInfo;

  return (
    <div className={classNames('announcement')}>
      <AvatarImage
        className={classNames('announcement__avatar')}
        src={lootImageUrl}
        alt={setName}
        fallbackSrc={ROUNDED_PLACEHOLDER_IMAGE}
      />
      <div className={classNames('announcement__info')}>
        <p
          className={classNames('announcement__text')}
          dangerouslySetInnerHTML={{
            __html: t('collection:packsPerSubAnnouncement', {
              slug,
              packs: t('common:freePacksCount', { count: quantity }),
              setName,
            }),
          }}
        />
        <div>
          <Button primary size="sm" onClick={handleClick}>
            {t('pageSubscription:checkout.button.subscribe')}
          </Button>
        </div>
      </div>
    </div>
  );
};
