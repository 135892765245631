import ClassNames from '@streamloots/classnames';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Currency, Languages } from '@streamloots/streamloots-types';
import { connect } from 'react-redux';
import { FixMe } from 'types/indexTS';
import { cultureSelectors } from 'services/culture';
import {
  CreateSubscriptionGiftPaypalOrder,
  ExecuteSubscriptionGifPaypalOrder,
  SubscriptionGiftOrderFormValues,
  subscriptionGiftsActions,
} from 'services/page-subscription-gifts';
import { usePaypalClientId } from 'hooks/use-paypal-client-id';
import { TranslateInterface } from 'utils/translation';
import Skeleton from 'ui-library/skeleton';
import { useReactAlert } from 'components/react-alert-provider';
import { useEffect, useRef } from 'react';
import theme from './paypal.scss';

interface PaypalSinglePurchaseProps extends TranslateInterface {
  currency: Currency;
  slug: string;
  nextStep: () => void;
  giftPayload: SubscriptionGiftOrderFormValues;
}

interface MapStateToProps {
  language: Languages;
}

interface MapDispatchToProps {
  createSubscriptionGiftPaypalOrder: CreateSubscriptionGiftPaypalOrder;
  executeSubscriptionGiftPaypalOrder: ExecuteSubscriptionGifPaypalOrder;
}

interface ExtendedProps extends PaypalSinglePurchaseProps, MapStateToProps, MapDispatchToProps {}

const classNames = ClassNames(theme);

const PaypalSinglePurchaseBase = ({
  currency,
  language,
  slug,
  giftPayload,
  createSubscriptionGiftPaypalOrder,
  executeSubscriptionGiftPaypalOrder,
  nextStep,
  t,
}: ExtendedProps): JSX.Element => {
  const iframePayload = useRef<SubscriptionGiftOrderFormValues>(giftPayload);
  const { showError } = useReactAlert();
  const { clientId } = usePaypalClientId({ currency });

  useEffect(() => {
    iframePayload.current = giftPayload;
  }, [giftPayload]);

  const onError = (error: FixMe) => {
    const errorTranslations = {
      SUBSCRIPTIONS_NOT_ENABLED: t('pageSubscription:checkout.errors.subscriptionsNotEnabled'),
      SELF_GIFT: t('pageSubscription:checkout.errors.selfGift'),
    };
    showError(errorTranslations[error] ?? t('pageSubscription:checkout.errors.unknownError'));
  };

  const createOrder = async (): Promise<string> => {
    try {
      const paymentOrder = await createSubscriptionGiftPaypalOrder({
        slug,
        ...iframePayload.current,
      });
      if (paymentOrder.error) {
        throw new Error(paymentOrder.metadata?.businessCode);
      }

      return paymentOrder.payload.ecToken;
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  const onApprove = async (data: FixMe): Promise<void> => {
    const response = await executeSubscriptionGiftPaypalOrder(data);
    if (response.error) {
      showError(response.payload.message);
    } else {
      nextStep();
    }
  };

  if (!clientId) {
    return <Skeleton height={40} />;
  }

  return (
    <div className={classNames('paypal')}>
      <PayPalScriptProvider
        options={{
          'client-id': clientId,
          'locale': language === Languages.Spanish ? 'es_ES' : 'en_US',
          'disable-funding': 'card',
          'currency': currency,
          'vault': false,
          'enable-funding': 'venmo,paylater',
        }}
      >
        <PayPalButtons
          style={{
            color: 'gold',
            label: 'pay',
            shape: 'rect',
            height: 40,
            tagline: false,
          }}
          createOrder={createOrder}
          onApprove={(data, actions) => {
            if (!actions.order) {
              return Promise.resolve();
            }

            return actions.order.capture().then(() => {
              onApprove(data);
            });
          }}
          onError={onError}
        />
      </PayPalScriptProvider>
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  language: cultureSelectors.language(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  createSubscriptionGiftPaypalOrder: subscriptionGiftsActions.createSubscriptionGiftPaypalOrder,
  executeSubscriptionGiftPaypalOrder: subscriptionGiftsActions.executeSubscriptionGiftPaypalOrder,
};

export const PaypalSinglePurchase = connect<MapStateToProps, MapDispatchToProps, PaypalSinglePurchaseProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PaypalSinglePurchaseBase);
