import { ChangeEvent, FC, ReactNode, useMemo } from 'react';
import ClassNames from '@streamloots/classnames';
import { uniqueId } from 'lodash';
import { RadioButtonsContext } from './context';
import theme from './radio-buttons.scss';
import formTheme from '../form.scss';

export interface RadioInputButtonGroupProps {
  children: ReactNode;
  name?: string;
  value: string;
  label?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const classNames = ClassNames(theme);
const formClassNames = ClassNames(formTheme);

export const RadioInputButtonGroup: FC<RadioInputButtonGroupProps> = ({
  children,
  name,
  value,
  onChange,
  label,
}: RadioInputButtonGroupProps): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const inputName = useMemo(() => {
    return name ?? uniqueId();
  }, [name]);

  return (
    <fieldset className={classNames('radio-buttons')}>
      {label && <legend className={formClassNames('label')}>{label}</legend>}
      <RadioButtonsContext.Provider value={{ value, handleChange, inputName }}>{children}</RadioButtonsContext.Provider>
    </fieldset>
  );
};
