import ClassNames from '@streamloots/classnames';
import type { PageType } from '@streamloots/streamloots-types';
import { useTranslation } from 'utils/translation';
import { PageBadge } from './PageBadge';
import theme from './page-type-badge.scss';

const classNames = ClassNames(theme);

interface PageBadgeWithLabelProps {
  badgeClassName?: string;
  className?: string;
  type: PageType;
  badgeOnRight?: boolean;
  ambassador: boolean;
}

export const PageBadgeWithLabel = ({
  badgeClassName = '',
  className = '',
  ambassador,
  badgeOnRight,
  type,
}: PageBadgeWithLabelProps): JSX.Element => {
  const { t } = useTranslation('common');
  const lowerCaseType = type.toLowerCase();

  return (
    <div
      className={classNames({
        'badge': true,
        'badge--ambassador': ambassador,
        [`badge--${lowerCaseType}`]: true,
        [className]: Boolean(className),
      })}
    >
      {!badgeOnRight && (
        <PageBadge
          className={classNames({ badge__img: true, [badgeClassName]: Boolean(badgeClassName) })}
          type={type}
          ambassador={ambassador}
        />
      )}
      {ambassador ? t('common:ambassador') : t(`common:pageType.${lowerCaseType}`)}
      {badgeOnRight && (
        <PageBadge
          className={classNames({ badge__img: true, [badgeClassName]: Boolean(badgeClassName) })}
          type={type}
          ambassador={ambassador}
        />
      )}
    </div>
  );
};
