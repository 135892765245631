import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import type { OwnerCard, PublicPredefinedCard, UserCard } from 'model/indexTS';
import { userSetsSelectors } from 'services/user-sets';
import { overloadedContentActions, OpenOverloadedContent } from 'services/overloaded-content';
import { userSetCardsSelectors } from 'services/user-set-cards';
import { CardInformationCarousel } from 'components/card-information';
import MetaTags from 'components/meta-tags';
import { appSelectors } from 'services/app';
import { pageSelectors } from 'services/page';
import { Powers } from 'components/powers';
import { useHistory, useLocation } from 'react-router-dom';

type OpenCheckoutFromLoginProps = {
  slug: string;
  path: string;
  openOverloadedContent: OpenOverloadedContent;
  cards: Array<UserCard>;
  card?: UserCard;
};

type TypeCards = UserCard | PublicPredefinedCard | OwnerCard;

export const ConnectedOpenCardFromUrl = ({
  slug,
  path,
  openOverloadedContent,
  cards,
  card,
}: OpenCheckoutFromLoginProps): JSX.Element | null => {
  // Image is never passed: see CardPanel component call to this function.
  function handleCardClick(userCard: TypeCards) {
    openOverloadedContent({
      component: CardInformationCarousel,
      componentProps: {
        itemId: userCard._id,
        itemList: cards,
        hasInfinitePagination: false,
        // eslint-disable-next-line
        onClickEffectSelector: handleClickEffectSelector,
        openFromSource: 'Direct link',
      },
      wrapperProps: {
        opacity: true,
        type: 'large',
        fullOnSmall: false,
        overflowH: true,
      },
    });
  }

  // This function is responsible for returning to the last card viewed when
  // alert effects selector is closed.
  function handleSelectEffect(userCard: TypeCards) {
    return function () {
      handleCardClick(userCard);
    };
  }

  function handleClickEffectSelector(userCard: TypeCards) {
    openOverloadedContent({
      component: Powers,
      componentProps: {
        onSelectEffect: handleSelectEffect(userCard),
        isPowersPage: false,
      },
      wrapperProps: {
        opacity: true,
        type: 'fullscreen',
        overflowH: true,
        onClose: handleSelectEffect(userCard),
      },
    });
  }
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (!card || !location.search?.includes('cardId')) {
      return;
    }

    history.replace({
      pathname: path,
    });

    handleCardClick(card);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card, history, location.search, openOverloadedContent, slug, path]);

  if (card) {
    return <MetaTags imageUrl={card.imageUrl} title={card.name} description={card.description} />;
  }

  return null;
};

const mapStateToProps = state => {
  const setId = userSetsSelectors.setId(state);
  const params = appSelectors.baseRequestQuery(state);
  return {
    slug: pageSelectors.slug(state),
    path: appSelectors.currentPathname(state),
    cards: userSetCardsSelectors.filteredCards(state, setId),
    ...(params?.cardId && {
      card: userSetCardsSelectors.card(state, setId, params?.cardId),
    }),
  };
};

export const OpenCardFromUrl = compose(
  connect(mapStateToProps, {
    openOverloadedContent: overloadedContentActions.openOverloadedContent,
  }),
)(ConnectedOpenCardFromUrl) as React.FunctionComponent;
