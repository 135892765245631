import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import CardStatusIcon from 'components/card-information/components/card-status-icon';
import Countdown from 'react-countdown-now';
import { TranslateInterface, withTranslation } from 'utils/translation';
import theme from './subscription-countdown.scss';

export interface SubscriptionCountdownProps {
  seconds?: number;
  onComplete: () => void;
}

interface ExtendedProps extends SubscriptionCountdownProps, TranslateInterface {}

const classNames = ClassNames(theme);

const SubscriptionCountdownBase = ({ seconds = 60, onComplete, t }: ExtendedProps): JSX.Element => {
  const date = React.useMemo(() => Date.now() + seconds * 1000, [seconds]);

  return (
    <CardStatusIcon type={CardStatusIcon.TYPES.NEGATIVE_COOLDOWN} iconPosition={CardStatusIcon.ICON_POSITION.RIGHT}>
      <span className={classNames('cooldown')}>{t('collection:subscriptionsAlert.cooldown')}</span>
      <Countdown date={date} onComplete={onComplete} />
    </CardStatusIcon>
  );
};

export const SubscriptionCountdown = withTranslation('collection')(SubscriptionCountdownBase);
