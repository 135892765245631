import * as React from 'react';
import type { TranslateInterface } from 'utils/translation';
import ClassNames from '@streamloots/classnames';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import Button from 'ui-library/button';
import { InputNumber } from 'ui-library/form-elements';
import Spinner from 'ui-library/loading-spinner';
import type { OptionI18nKeys } from '../../types';
import { CraftingModeAmountSymbol } from './constants';
import theme from './card-crafting-view.scss';

const classNames = ClassNames(theme);

interface CraftingViewOptionPanelProps extends TranslateInterface {
  amountSymbol: CraftingModeAmountSymbol;
  cost: number;
  i18nKeys: OptionI18nKeys;
  isBlocked: boolean;
  isLoading: boolean;
  isDisenchantingMode: boolean;
  max: number;
  onActionClick: () => void;
  onValueChange: (newAmount: number | '') => void;
  showConfirmation: boolean;
  toggleConfirmation: () => void;
  value: number;
}

const CraftingViewOptionPanel = ({
  amountSymbol,
  cost,
  i18nKeys,
  isBlocked,
  isLoading,
  max,
  onActionClick,
  onValueChange,
  showConfirmation,
  t,
  isDisenchantingMode,
  toggleConfirmation,
  value,
}: CraftingViewOptionPanelProps): JSX.Element => {
  const countCost = cost * value;

  return (
    <div
      className={classNames({
        'crafting-view__option': true,
        'crafting-view__option--destroying': isDisenchantingMode,
      })}
    >
      {isBlocked && (
        <div className={classNames('crafting-view__blocked')}>
          <p>{t(i18nKeys.block)}</p>
        </div>
      )}
      <div>
        <p className={classNames('crafting-view__option__label')}>{t(i18nKeys.title)}</p>
        <InputNumber
          value={value}
          min={1}
          max={max}
          grey
          small
          onChange={onValueChange}
          wrapperClassName={classNames('crafting-view__option__input')}
        />
        <div className={classNames('crafting-view__option__resources')}>
          {`${amountSymbol}${countCost}`}
          <DisenchantResourceSymbol />
        </div>
        <Button
          onClick={toggleConfirmation}
          className={classNames('crafting-view__action')}
          label={t(i18nKeys.button)}
          small
          block
          dangerous={isDisenchantingMode}
          safe={!isDisenchantingMode}
        />
      </div>
      <div
        className={classNames({
          'crafting-view__confirmation': true,
          'crafting-view__confirmation--show': showConfirmation,
        })}
      >
        {isLoading && <Spinner />}
        <p className={classNames('crafting-view__confirmation__label')}>{t(i18nKeys.summaryAction)}</p>
        <p className={classNames('crafting-view__confirmation__value')}>{t(i18nKeys.itemCount, { count: value })}</p>
        <p className={classNames('crafting-view__confirmation__label')}>{t(i18nKeys.summaryResult)}</p>
        <p className={classNames('crafting-view__confirmation__value')}>
          {countCost} <DisenchantResourceSymbol />
        </p>
        <div className="margin-bottom-s">
          <Button
            className={classNames('crafting-view__action')}
            block
            small
            label={t('common:confirm')}
            onClick={onActionClick}
            dangerous={isDisenchantingMode}
            safe={!isDisenchantingMode}
          />
        </div>
        <Button weird small block label={t('common:cancel')} onClick={toggleConfirmation} />
      </div>
    </div>
  );
};

export default CraftingViewOptionPanel;
