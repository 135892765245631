import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuctionEnded,
  FetchAuctions,
  FetchSingleAuction,
  ExecuteBidOrder,
  CreateBidOrder,
  PostBidAuction,
  CreateAuction,
  UpdateAuction,
  PatchAuction,
  AuctionsState,
} from './types';
import { auctionSelectors } from './selectors';
import { auctionActions } from './actions';

export type UseAuctionsSelectors = AuctionsState;

export interface UseAuctionsActions {
  fetchAuctions: FetchAuctions;
  fetchSingleAuction: FetchSingleAuction;
  postBid: PostBidAuction;
  auctionEnded: AuctionEnded;
  executeBidOrder: ExecuteBidOrder;
  createBidOrder: CreateBidOrder;
  createAuction: CreateAuction;
  updateAuction: UpdateAuction;
  patchAuction: PatchAuction;
}

export const useAuctionsActions = (): UseAuctionsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchAuctions: slug => dispatch(auctionActions.fetchAuctions(slug)),
      postBid: (slug, auctionId, params) => dispatch(auctionActions.postBid(slug, auctionId, params)),
      fetchSingleAuction: (slug, auctionId) => dispatch(auctionActions.fetchSingleAuction(slug, auctionId)),
      auctionEnded: auctionId => dispatch(auctionActions.auctionEnded(auctionId)),
      executeBidOrder: params => dispatch(auctionActions.executeBidOrder(params)),
      createBidOrder: params => dispatch(auctionActions.createBidOrder(params)),
      createAuction: (parameters, slug) => dispatch(auctionActions.createAuction(parameters, slug)),
      updateAuction: (parameters, slug, auctionId) =>
        dispatch(auctionActions.updateAuction(parameters, slug, auctionId)),
      patchAuction: (parameters, slug, auctionId) => dispatch(auctionActions.patchAuction(parameters, slug, auctionId)),
    }),
    [dispatch],
  );
};

export const useAuctionsSelectors = (): UseAuctionsSelectors => {
  return useSelector(auctionSelectors.allAuctionsState);
};
