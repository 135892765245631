import { useEffect } from 'react';
import { useOverloadedContentActions } from 'services/overloaded-content';
import MetaTags from 'components/meta-tags';
import { useLocation } from 'react-router-dom';
import { useAppSelectors } from 'services/app';
import { usePageSelectors } from 'services/page';
import { ShareRecapDialogBody } from './ShareRecapDialogBody';

const BASE_IMAGE_URL = `https://static.streamloots.com/30dd898c-33cf-436e-bd6a-023928d218d8/media/`;

export const OpenShareRecapFromUrl = (): JSX.Element | null => {
  const { openOverloadedContent } = useOverloadedContentActions();
  const { baseRequestQuery } = useAppSelectors();
  const {
    page: { slug },
  } = usePageSelectors();
  const location = useLocation();

  const isShareRecap = location.pathname.endsWith('/share-recap');
  const imageUrl = `${BASE_IMAGE_URL}${baseRequestQuery?.imgPath}`;

  useEffect(() => {
    if (isShareRecap) {
      openOverloadedContent({
        component: ShareRecapDialogBody,
        componentProps: {
          imageUrl,
          slug,
        },
        wrapperProps: {
          type: 'fullscreen',
          showBlurBackground: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isShareRecap) {
    const isSpanish = baseRequestQuery?.lang === 'es';
    return (
      <MetaTags
        imageUrl={imageUrl}
        title={isSpanish ? 'Mi resumen semanal' : 'My Weekly Recap'}
        description={isSpanish ? 'Mi resumen semanal' : 'My Weekly Recap'}
      />
    );
  }

  return null;
};
