import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { I18nType, TranslateInterface, withTranslation } from 'utils/translation';
import { authSelectors } from 'services/auth';
import { getUrlWithParams, pages } from 'services/routing';
import { pageSelectors, PageState } from 'services/page';
import { GetSubscriptionPlanSelectorOutput, pageSubscriptionPlansSelectors } from 'services/page-subscription-plans';
import { CleanDialogContent } from 'ui-library/dialog';
import { FixedCloseButton } from 'ui-library/fixed-close-button';
import { AuthDialog } from 'scenes/auth';
import { SubscriptionCheckoutFromLoginLocations } from 'scenes/public-user-page/components/open-subscription-checkout-from-login';
import {
  FetchSubscriptionGifts,
  SubscriptionGiftCommunityOrderFormValues,
  subscriptionGiftsActions,
  SubscriptionGiftUserOrderFormValues,
} from 'services/page-subscription-gifts';
import { GiftSubscriptionType } from '@streamloots/streamloots-types';
import { PaymentGiftStep } from './components/payment-step';
import { LastGiftStep } from './components/last-step';

interface SubscriptionGiftCheckoutDialogProps {
  onClose: () => void;
}

interface MapStateToProps {
  isUserAuthenticated: boolean;
  slug: string;
  page: PageState;
  isOwner: boolean;
  subscriptionPlan: GetSubscriptionPlanSelectorOutput;
}

interface DispatchToPros {
  fetchSubcriptionGifts: FetchSubscriptionGifts;
}

interface ExtendedProps
  extends SubscriptionGiftCheckoutDialogProps,
    MapStateToProps,
    DispatchToPros,
    TranslateInterface {
  i18n: I18nType;
}

const SubscriptionGiftCheckoutDialogBase = ({
  isUserAuthenticated,
  slug,
  subscriptionPlan,
  page,
  isOwner,
  i18n,
  onClose,
  fetchSubcriptionGifts,
  t,
}: ExtendedProps): JSX.Element | null => {
  const [step, setStep] = React.useState(1);
  const [giftType, setGiftType] = React.useState<GiftSubscriptionType>(GiftSubscriptionType.COMMUNITY);
  const [
    communityGiftFormValues,
    setCommunityGiftFormValues,
  ] = React.useState<SubscriptionGiftCommunityOrderFormValues>({
    type: GiftSubscriptionType.COMMUNITY,
    quantity: 1,
  });

  const [userGiftFormValues, setUserGiftFormValues] = React.useState<SubscriptionGiftUserOrderFormValues>({
    type: GiftSubscriptionType.USER,
    giftee: '',
  });

  const handleGiftTypeChange = (newGiftType: GiftSubscriptionType) => {
    setGiftType(newGiftType);
  };

  const handleCommunityGiftQuantityChange = (quantity: number) => {
    setCommunityGiftFormValues({ ...communityGiftFormValues, quantity });
  };

  const handleCommunityGiftPrepaidMonthsChange = (totalPrepaidMonths: number | undefined) => {
    setCommunityGiftFormValues({ ...communityGiftFormValues, totalPrepaidMonths });
  };

  const handleUserGiftGifteeChange = (giftee: string) => {
    setUserGiftFormValues({ ...userGiftFormValues, giftee });
  };

  const handlePaymentCompleted = () => {
    if (giftType === GiftSubscriptionType.COMMUNITY) {
      fetchSubcriptionGifts(slug);
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  if (!subscriptionPlan) {
    return null;
  }

  if (!isUserAuthenticated) {
    return (
      <AuthDialog
        onClose={onClose}
        componentKey={SubscriptionCheckoutFromLoginLocations.GiftCheckout}
        redirectTo={`${getUrlWithParams(pages.USER_PAGE_SUBSCRIPTION, {
          slug,
        })}?startLocation=${SubscriptionCheckoutFromLoginLocations.GiftCheckout}`}
        location="subscription gift checkout"
      />
    );
  }

  return (
    <CleanDialogContent>
      <FixedCloseButton onClose={onClose} />
      {step <= 1 ? (
        <PaymentGiftStep
          avatarImageUrl={page.owner?.profile.avatarImageUrl || ''}
          i18n={i18n}
          onPaymentCompleted={handlePaymentCompleted}
          slug={slug}
          subscriptionPlan={subscriptionPlan}
          t={t}
          isOwner={isOwner}
          communityGiftFormData={communityGiftFormValues}
          userGiftFormData={userGiftFormValues}
          giftType={giftType}
          onGiftTypeChange={handleGiftTypeChange}
          onCommunityGiftQuantityChange={handleCommunityGiftQuantityChange}
          onUserGiftGifteeChange={handleUserGiftGifteeChange}
          onCommunityGiftPrepaidMonthsChange={handleCommunityGiftPrepaidMonthsChange}
          userGiftee={userGiftFormValues.giftee}
        />
      ) : (
        <LastGiftStep
          giftType={giftType}
          onClose={onClose}
          slug={slug}
          onPurchaseAgain={handlePreviousStep}
          t={t}
          totalSubscriptions={giftType === GiftSubscriptionType.COMMUNITY ? communityGiftFormValues.quantity : 1}
        />
      )}
    </CleanDialogContent>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    slug: pageSelectors.slug(state),
    page: pageSelectors.page(state),
    subscriptionPlan: pageSubscriptionPlansSelectors.getSubscriptionPlan(state),
    isOwner: pageSelectors.isOwner(state),
  };
};

const mapDispatchToProps: DispatchToPros = {
  fetchSubcriptionGifts: subscriptionGiftsActions.fetchSubscriptionGifts,
};

export const SubscriptionGiftCheckoutDialog = compose<React.FunctionComponent<SubscriptionGiftCheckoutDialogProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('pageSubscription'),
)(SubscriptionGiftCheckoutDialogBase);
