import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FetchPageEmotes,
  GetPageEmotesSelectorOutput,
  PageEmotesPlay,
  PageEmotesState,
  FilterPageEmotes,
} from './types';
import { pageEmotesActions } from './actions';
import { pageEmotesSelectors } from './selectors';

// ----- Types -----
interface UsePageEmotesActions {
  playPageEmotes: PageEmotesPlay;
  fetchPageEmotes: FetchPageEmotes;
  filterPageEmotes: FilterPageEmotes;
}

interface UsePageEmotesSelectors extends PageEmotesState {
  filteredCollections: GetPageEmotesSelectorOutput;
}

// ----- Actions -----
export const usePageEmotesActions = (): UsePageEmotesActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPageEmotes: slug => dispatch(pageEmotesActions.fetchPageEmotes(slug)),
      playPageEmotes: (pageId, emotes) => dispatch(pageEmotesActions.playPageEmotes(pageId, emotes)),
      filterPageEmotes: searchString => dispatch(pageEmotesActions.filterPageEmotes(searchString)),
    }),
    [dispatch],
  );
};

// ----- Selectors -----
export const usePageEmotesSelectors = (): UsePageEmotesSelectors => {
  const { searchString = '', isLoading = false, collections, error } = useSelector(pageEmotesSelectors.pageEmotes);
  const filteredCollections = useSelector(pageEmotesSelectors.getFilteredEmotesCollections);

  return {
    searchString,
    isLoading,
    collections,
    error,
    filteredCollections,
  };
};
