import { createSelector } from 'reselect';
import { GetPageEmotesSelectorOutput, PageEmotesReduxState, PageEmotesState } from './types';

const pageEmotes = (state: PageEmotesReduxState): PageEmotesState => state.pageEmotes ?? {};

const getPageEmotesCollections = createSelector<PageEmotesReduxState, PageEmotesState, GetPageEmotesSelectorOutput>(
  pageEmotes,
  (pageEmotesState: PageEmotesState): GetPageEmotesSelectorOutput => pageEmotesState?.collections ?? null,
);

const getFilteredEmotesCollections = createSelector<PageEmotesReduxState, PageEmotesState, GetPageEmotesSelectorOutput>(
  pageEmotes,
  (pageEmotesState: PageEmotesState): GetPageEmotesSelectorOutput => {
    const { collections, searchString } = pageEmotesState;
    if (!collections) {
      return null;
    }

    if (!searchString) {
      return collections;
    }

    const collectionsWithFilteredEmotes = collections.map(emoteCollection => {
      return { ...emoteCollection, emotes: emoteCollection.emotes.filter(emote => emote.name.includes(searchString)) };
    });
    return collectionsWithFilteredEmotes.filter(emoteCollection => emoteCollection.emotes.length > 0);
  },
);

const isFetchingPageEmotes = (state: PageEmotesReduxState): boolean => state.pageEmotes?.isLoading ?? true;

export const pageEmotesSelectors = {
  pageEmotes,
  isFetchingPageEmotes,
  getPageEmotesCollections,
  getFilteredEmotesCollections,
};
