import { ChangeEvent, createContext, InputHTMLAttributes } from 'react';
import { noop } from 'lodash';

export interface RadioButtonsContextReturn {
  value?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputName: string;
}

export const RadioButtonsContext = createContext<RadioButtonsContextReturn>({
  value: undefined,
  handleChange: noop,
  inputName: '',
});
