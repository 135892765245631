import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import Icon from 'ui-library/icons/Icons';
import theme from './intro-banner.scss';

const classNames = ClassNames(theme);

export const SUBSCRIPTION_OPTIONS_TOOGLER_ID = 'cancel-subscription-option-toogle';

export interface CancelSubscriptionOptionsProps extends TranslateInterface {
  onClick: () => void;
}

export const CancelSubscriptionOptions = ({ onClick, t }: CancelSubscriptionOptionsProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const closeMenuOnOutsideClick = event => {
      if (event.target.id !== SUBSCRIPTION_OPTIONS_TOOGLER_ID) {
        setOpen(false);
      }
    };
    document.addEventListener('click', closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, []);

  const toogleMenu = () => {
    setOpen(!open);
  };

  return (
    <div
      className={classNames({
        'intro-banner__container__ctas__subscribed': true,
        'intro-banner__container__ctas__subscribed--open': open,
      })}
    >
      <button id={SUBSCRIPTION_OPTIONS_TOOGLER_ID} type="button" onClick={toogleMenu}>
        <span>{t('pageSubscription:checkout.button.subscribed')} </span>
        <Icon id={open ? 'chevron-up' : 'chevron-down'} />
      </button>

      <div
        role="menu"
        className={classNames({
          'intro-banner__container__ctas__subscribed__options': true,
          'intro-banner__container__ctas__subscribed__options--hide': !open,
        })}
      >
        <button type="button" onClick={onClick}>
          {t('pageSubscription:checkout.button.cancelSubscription')}
        </button>
      </div>
    </div>
  );
};
