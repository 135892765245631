import { FC, useContext } from 'react';
import ClassNames from '@streamloots/classnames';
import { RadioButtonIcon } from 'ui-library/radio-button-card/RadioButtonIcon';
import Icon from 'ui-library/icons';
import { RadioButtonsContext } from './context';
import theme from './radio-buttons.scss';

export interface RadioInputButtonProps {
  label: string;
  value: string;
  icon?: string;
}

const classNames = ClassNames(theme);

export const RadioInputButton: FC<RadioInputButtonProps> = ({
  label,
  value: inputValue,
  icon,
}: RadioInputButtonProps): JSX.Element => {
  const { value, handleChange, inputName } = useContext(RadioButtonsContext);

  const isSelected = value === inputValue;
  const inputId = `${inputName}.${inputValue}`;

  return (
    <div className={classNames({ 'radio-buttons__radio': true, 'radio-buttons__radio--selected': isSelected })}>
      <input
        name={inputName}
        id={inputId}
        type="radio"
        checked={isSelected}
        value={inputValue}
        onChange={handleChange}
      />
      <label htmlFor={inputId}>
        <div className={classNames('radio-buttons__radio__check')}>
          <RadioButtonIcon selectable checked={isSelected} />
        </div>
        <div className={classNames('radio-buttons__radio__content')}>
          {icon && (
            <div className={classNames('radio-buttons__radio__content__icon')}>
              <Icon id={icon} />
            </div>
          )}
          <div
            className={classNames({
              'radio-buttons__radio__content__label': true,
              'radio-buttons__radio__content__label--selected': isSelected,
            })}
          >
            {label}
          </div>
        </div>
      </label>
    </div>
  );
};
