import { createSelector } from 'reselect';
import { CommunityGiftsSelectorOutput, CommunityGiftsState, CommunityGiftsStoreState } from './types';

const communityState = (state: CommunityGiftsStoreState): CommunityGiftsState => state.communityGift || {};

const getCommunityGifts = createSelector<CommunityGiftsStoreState, CommunityGiftsState, CommunityGiftsSelectorOutput>(
  [communityState],
  (state: CommunityGiftsState): CommunityGiftsSelectorOutput => {
    const { communityGifts, isLoading } = state;

    if (isLoading || !communityGifts) {
      return null;
    }

    return communityGifts;
  },
);

const isLoadingData = createSelector<CommunityGiftsStoreState, CommunityGiftsState, boolean>(
  [communityState],
  (state: CommunityGiftsState): boolean => {
    const { isLoading } = state;

    if (isLoading) {
      return isLoading;
    }

    return false;
  },
);

const getClamingId = createSelector<CommunityGiftsStoreState, CommunityGiftsState, string | undefined>(
  [communityState],
  (state: CommunityGiftsState): string | undefined => {
    const { clamingId } = state;

    if (clamingId) {
      return clamingId;
    }

    return undefined;
  },
);

const getReconcile = createSelector<CommunityGiftsStoreState, CommunityGiftsState, boolean>(
  [communityState],
  (state: CommunityGiftsState): boolean => {
    const { reconcile } = state;

    if (reconcile) {
      return reconcile;
    }

    return false;
  },
);

export const communityGiftsSelectors = {
  getCommunityGifts,
  isLoadingData,
  getClamingId,
  getReconcile,
};
