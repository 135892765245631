import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Icon from 'ui-library/icons';
import { pageStatisticsSelectors, TotalSelectorOutput } from 'services/page-statistics';
import { usePageSelectors } from 'services/page';
import { cultureSelectors } from 'services/culture';
import TooltipHelpIndicatorWrapper from 'components/help-tooltip';
import theme from './social-proof.scss';

const classNames = ClassNames(theme);

interface OwnProps {
  shorter?: boolean;
}
interface MapStateToProps {
  total: TotalSelectorOutput;
}

interface ExtendedProps extends OwnProps, MapStateToProps, TranslateInterface {}

const ConnectedSocialProof = ({ total, t, shorter = false }: ExtendedProps) => {
  const {
    page: { slug },
  } = usePageSelectors();
  if (!total) {
    return null;
  }

  return (
    <div className={classNames('social-proof')}>
      <TooltipHelpIndicatorWrapper tooltip={t('collection:socialProofTooltip', { slug })}>
        <div className={classNames('social-proof__icon-wrapper')}>
          <Icon id="heart" />
        </div>
      </TooltipHelpIndicatorWrapper>
      <div className={classNames({ 'social-proof__info': true, 'social-proof__info--shorter': shorter })}>
        {total.totalFormatted}
        <span className={classNames('social-proof__explain')}>
          {shorter ? t('collection:socialProofNavShorter') : t('collection:socialProofNav')}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  const language = cultureSelectors.language(state);
  return {
    total: pageStatisticsSelectors.total(state, language),
  };
};

export const SocialProof = compose(
  withTranslation('collection'),
  connect<MapStateToProps, null>(mapStateToProps),
)(ConnectedSocialProof) as React.FunctionComponent<OwnProps>;
