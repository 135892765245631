import { Field, WrappedFieldProps } from 'redux-form';
import { StrictOmit } from '@streamloots/streamloots-types';
import { FocusEvent } from 'react';
import { FixMe } from 'types/indexTS';
import { Dropdown, DropdownProps } from '../form-elements';
import { FieldSharedProps } from './typesTS';

interface DropdownFieldProps extends StrictOmit<DropdownProps, 'onChange' | 'onBlur'> {
  onChange?: (value: FixMe) => void; // the internal dropdown onChange returns the value, but the dropdown field wrapper doesn't work
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
}

interface DropdownFieldWrappedProps extends WrappedFieldProps, DropdownProps {}

const DropdownWrappedField = ({ label, input, meta, ...rest }: DropdownFieldWrappedProps) => {
  const { error, touched } = meta;
  return <Dropdown {...input} label={label} error={touched && error ? error : ''} {...rest} />;
};

export const DropdownField = (props: FieldSharedProps<DropdownFieldProps>) => (
  <Field component={DropdownWrappedField} {...props} />
);
