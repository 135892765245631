import * as React from 'react';
import { connect } from 'react-redux';
import trackEventManager from 'utils/event-manager';
import { subscriptionsActions, subscriptionSelectors } from 'services/subscriptions';
import { FetchSubscriptions, GetSubscriptionOutput } from 'services/subscriptions/types';
import { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { overloadedContentActions } from 'services/overloaded-content';
import { GetSubscriptionPlanSelectorOutput, pageSubscriptionPlansSelectors } from 'services/page-subscription-plans';
import { SubscriptionGiftCheckoutDialog } from 'scenes/subscription-checkout';
import { authSelectors } from 'services/auth';
import { pageSelectors, PageState } from 'services/page';
import { SubscriptionAlerts } from './components/subscription-alerts';
import { OpenSubscriptionCheckoutFromLogin } from './components/open-subscription-checkout-from-login';
import { SubscriptionClaim } from './components/susbcription-claim/SubscriptionClaim';
import { SubscriptionGifts } from './components/subscription-gifts';
import { SubscriptionPackClaim } from './components/subscription-packs';

interface OwnProps {
  slug: string;
  isSubscribed: boolean;
  onSubscribe: (cta: string, name?: string) => void;
}

interface MapStateToProps {
  subscriptionPlan: GetSubscriptionPlanSelectorOutput;
  subscription: GetSubscriptionOutput;
  isSubscribed: boolean;
  isUserAuthenticated: boolean;
  page: PageState;
}

interface DispatchProps {
  fetchSubscriptions: FetchSubscriptions;
  openOverloadedContent: OpenOverloadedContent;
}

interface ExtendedProps extends OwnProps, DispatchProps, MapStateToProps {}

const PublicUserPageSubscriptionBase = ({
  isSubscribed = false,
  slug,
  subscription,
  subscriptionPlan,
  isUserAuthenticated,
  page,
  fetchSubscriptions,
  onSubscribe,
  openOverloadedContent,
}: ExtendedProps): JSX.Element | null => {
  React.useEffect(() => {
    if (!isUserAuthenticated || !slug) {
      return;
    }

    fetchSubscriptions(slug);
  }, [fetchSubscriptions, isUserAuthenticated, slug]);

  if (!subscriptionPlan || !page.modules?.subscriptions.enabled) {
    return null;
  }

  const handleOnSubscriptionGifts = () => {
    trackEventManager.subscriptionPurchasedClick({ location: 'give_subs' });
    openOverloadedContent({
      component: SubscriptionGiftCheckoutDialog,
      wrapperProps: {
        type: 'large',
        opacity: true,
        preventCloseOnOutsideClick: true,
      },
    });
  };

  const initAlertCooldown = !subscription
    ? 0
    : (new Date(subscription.nextAvailableAlertDate).getTime() - Date.now()) / 1000;

  return (
    <>
      <SubscriptionGifts onClick={handleOnSubscriptionGifts} />
      <SubscriptionClaim isSubscribed={isSubscribed} />
      {isUserAuthenticated && isSubscribed && <SubscriptionPackClaim />}
      <SubscriptionAlerts
        slug={slug}
        isSubscribed={isSubscribed}
        onSubscribe={onSubscribe}
        remainingAlerts={subscription?.remainingAlerts ?? 0}
        initAlertCooldown={initAlertCooldown}
      />
      <OpenSubscriptionCheckoutFromLogin />
    </>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  subscriptionPlan: pageSubscriptionPlansSelectors.getSubscriptionPlan(state),
  subscription: subscriptionSelectors.getSubscription(state),
  isSubscribed: subscriptionSelectors.isSubscribed(state),
  page: pageSelectors.page(state),
});

const dispatchProps: DispatchProps = {
  fetchSubscriptions: subscriptionsActions.fetchSubscriptions,
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
};

export const PublicUserPageSubscription = connect(
  mapStateToProps,
  dispatchProps,
)(PublicUserPageSubscriptionBase) as React.FunctionComponent<OwnProps>;
