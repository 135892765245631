import React from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import theme from './mobile-nav.scss';

const classNames = ClassNames(theme);

type MobileNavItemProps = {
  iconId?: string;
  label: string;
  onClick?: () => void;
  cssClass?: string;
  render?: () => React.ReactNode;
  children?: React.ReactNode;
};

function MobileNavItem({ onClick, iconId, label, cssClass = '', render, children, ...props }: MobileNavItemProps) {
  return (
    <div
      onClick={onClick}
      className={classNames({ 'm-nav__item': true, [cssClass]: cssClass })}
      role="menuitem"
      {...props}
    >
      {/* Status streamer indicator */}
      {render ? render() : <Icon id={iconId} />}
      <span className={classNames('m-nav__item__label')}>{label}</span>
      {children}
    </div>
  );
}

export default MobileNavItem;
