import { useEffect } from 'react';
import {
  PageEmotesPlay,
  GetPageEmotesSelectorOutput,
  usePageEmotesSelectors,
  usePageEmotesActions,
  FilterPageEmotes,
  FetchPageEmotes,
} from 'services/page-emotes';
import { Languages } from '@streamloots/streamloots-types';
import { useCultureSelectors } from 'services/culture';
import { usePageSelectors } from 'services/page';
import { usePageSubscriptionPlansSelectors } from 'services/page-subscription-plans';

interface UseSubscriptionAlertsEmotes {
  isLoading: boolean;
  collections: GetPageEmotesSelectorOutput;
  language: Languages;
  playEmotes: PageEmotesPlay;
  pageId: string | undefined;
  searchString: string;
  filterPageEmotes: FilterPageEmotes;
  emoteLinkCopied: boolean;
  fetchPageEmotes: FetchPageEmotes;
  slug: string;
}

export const useSubscriptionAlertsEmotes = (): UseSubscriptionAlertsEmotes => {
  const {
    page: { _id: pageId, slug },
  } = usePageSelectors();
  const { filteredCollections, isLoading = false, searchString = '' } = usePageEmotesSelectors();
  const { language } = useCultureSelectors();
  const { fetchPageEmotes, playPageEmotes: playEmotes, filterPageEmotes } = usePageEmotesActions();
  const { subscriptionPlan } = usePageSubscriptionPlansSelectors();

  useEffect(() => {
    return () => filterPageEmotes('');
  }, [filterPageEmotes]);

  return {
    isLoading,
    collections: filteredCollections,
    language,
    playEmotes,
    pageId,
    searchString,
    filterPageEmotes,
    emoteLinkCopied: subscriptionPlan?.emoteLinkCopied ?? false,
    fetchPageEmotes,
    slug,
  };
};
