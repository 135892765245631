import { Auction, Auctions } from 'model/auction';
import { AuctionsState, AuctionReduxState } from './types';

const allAuctionsState = (state: AuctionReduxState): AuctionsState => state.auctions || {};

const oneAuction = (state: AuctionReduxState, auctionId: string): Auction | null => {
  const auctionsDataMap = state.auctions.singleAuctions;
  if (auctionsDataMap) {
    return auctionsDataMap[auctionId] || null;
  }
  return null;
};

const allAuctions = (state: AuctionReduxState): Auctions | undefined => {
  return allAuctionsState(state).data;
};

export const auctionSelectors = {
  allAuctions,
  allAuctionsState,
  oneAuction,
};
