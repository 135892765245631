import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { TranslateInterface } from 'utils/translation';
import { useUserSetLootsSelectors } from 'services/user-set-loots';
import theme from './public-user-page-nav.scss';

const classNames = ClassNames(theme);

interface PublicUserPageNavTreasureProps extends TranslateInterface {
  onOpenLoots: () => void;
}

export const PublicUserPageNavTreasure = ({ onOpenLoots, t }: PublicUserPageNavTreasureProps): JSX.Element => {
  const { totalUnopenedLootsCount } = useUserSetLootsSelectors();

  return (
    <button
      className={classNames({
        'pup-nav__button-action': true,
        'pup-nav__button-action--highlighted': totalUnopenedLootsCount > 0,
      })}
      type="button"
      onClick={onOpenLoots}
    >
      <Icon id="treasure" />
      <span className={classNames('pup-nav__button-action__label')}>{t('collection:myPacks')}</span>
      {totalUnopenedLootsCount > 0 && (
        <span className={classNames('pup-nav__badge')}>
          {totalUnopenedLootsCount > 9 ? '9+' : totalUnopenedLootsCount}
        </span>
      )}
    </button>
  );
};
