export enum CraftingModeAmountSymbol {
  CRAFTING = '-',
  DISENCHANTING = '+',
}

export enum CraftingViewModes {
  CRAFTING = 'crafting',
  DISENCHANTING = 'disenchanting',
}

export const optionsPanelProps = {
  crafting: {
    amountSymbol: CraftingModeAmountSymbol.CRAFTING,
  },
  disenchanting: {
    amountSymbol: CraftingModeAmountSymbol.DISENCHANTING,
  },
};
