import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import {
  FetchPageSubscriptionRewards,
  GET_PAGE_SUBSCRIPTION_REWARDS_ERROR_ACTION,
  GET_PAGE_SUBSCRIPTION_REWARDS_SUCCESS_ACTION,
  PlayPageSubscriptionReward,
  PLAY_REWARD_ERROR_ACTION,
  PLAY_REWARD_SUCCESS_ACTION,
} from './types';
import { PageSubscriptionRewardsActionTypes } from './actionTypes';

// Get Rewards.
// -----
const getFetchPageSubscriptionRewardsError = ({ errorData }): GET_PAGE_SUBSCRIPTION_REWARDS_ERROR_ACTION =>
  actionCreator.createErrorAction(PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_ERROR, errorData);

const getFetchPageSubscriptionRewardsSuccess = ({ data }): GET_PAGE_SUBSCRIPTION_REWARDS_SUCCESS_ACTION =>
  actionCreator.createAction(PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_SUCCESS, data);

const fetchPageSubscriptionRewards: FetchPageSubscriptionRewards = (pageId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTION_REWARDS.GET,
    method: 'get',
    urlParameters: { pageId },
    loadingAction: PageSubscriptionRewardsActionTypes.GET_PAGE_SUBSCRIPTION_REWARDS_REQUEST,
    errorBinding: getFetchPageSubscriptionRewardsError,
    successBinding: getFetchPageSubscriptionRewardsSuccess,
  });

// Play reward.
// -----
const playPlaySubscriptionRewardError = ({ errorData }): PLAY_REWARD_ERROR_ACTION =>
  actionCreator.createErrorAction(PageSubscriptionRewardsActionTypes.PLAY_REWARD_ERROR, errorData);

const playPlaySubscriptionRewardSuccess = ({ data }): PLAY_REWARD_SUCCESS_ACTION =>
  actionCreator.createAction(PageSubscriptionRewardsActionTypes.PLAY_REWARD_SUCCESS, data);

const playPageSubscriptionReward: PlayPageSubscriptionReward = (rewardId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTION_REWARDS.PLAY,
    method: 'post',
    urlParameters: { rewardId },
    loadingAction: PageSubscriptionRewardsActionTypes.PLAY_REWARD_REQUEST,
    errorBinding: playPlaySubscriptionRewardError,
    successBinding: playPlaySubscriptionRewardSuccess,
  });

export const pageSubscriptionRewardsActions = {
  fetchPageSubscriptionRewards,
  playPageSubscriptionReward,
};
