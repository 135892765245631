import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { GiftSubscriptionType } from '@streamloots/streamloots-types';
import { TranslateInterface } from 'utils/translation';
import { SubscriptionGiftCommunityOrderFormValues } from 'services/page-subscription-gifts';
import { RadioItem } from './RadioItem';
import { CommunityGiftForm } from './CommunityGiftForm';
import theme from './gift-options.scss';
import { UserGiftForm } from './UserGiftForm';

const classNames = ClassNames(theme);

export interface GiftOptionsProps extends TranslateInterface {
  communityGiftFormData: SubscriptionGiftCommunityOrderFormValues;
  giftType: GiftSubscriptionType;
  onGiftTypeChange: (giftType: GiftSubscriptionType) => void;
  onCommunityGiftQuantityChange: (quantity: number) => void;
  onUserGiftGifteeChange: (giftee: string) => void;
  userGiftee: string;
}

export const GiftOptions = ({
  communityGiftFormData,
  giftType,
  onGiftTypeChange,
  onCommunityGiftQuantityChange,
  onUserGiftGifteeChange,
  t,
  userGiftee,
}: GiftOptionsProps): JSX.Element => (
  <React.Fragment>
    <div className={classNames('gift-options')}>
      <RadioItem
        label={t('pageSubscription:checkout.giftStep.purchaseAsACommunityGift')}
        icon="base_community-users"
        value={GiftSubscriptionType.COMMUNITY}
        selected={giftType === GiftSubscriptionType.COMMUNITY}
        onSelect={() => onGiftTypeChange(GiftSubscriptionType.COMMUNITY)}
      />
      <RadioItem
        label={t('pageSubscription:checkout.giftStep.purchaseAsAUserGift')}
        icon="user"
        value={GiftSubscriptionType.USER}
        selected={giftType === GiftSubscriptionType.USER}
        onSelect={() => onGiftTypeChange(GiftSubscriptionType.USER)}
      />
    </div>
    {giftType === GiftSubscriptionType.COMMUNITY ? (
      <CommunityGiftForm formValues={communityGiftFormData} t={t} onQuantityChange={onCommunityGiftQuantityChange} />
    ) : (
      <UserGiftForm t={t} onUserGiftGifteeChange={onUserGiftGifteeChange} userGiftee={userGiftee} />
    )}
  </React.Fragment>
);
