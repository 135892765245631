import { FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import Button from 'ui-library/button';
import { useTranslation } from 'utils/translation';
import theme from './subscription-rewards.scss';

export interface SubscriptionRewardsItemProps {
  _id: string;
  name: string;
  image: string;
  onClick: (name: string) => void;
}

const classNames = ClassNames(theme);

export const SubscriptionRewardsItem: FunctionComponent<SubscriptionRewardsItemProps> = ({
  _id,
  name,
  image,
  onClick,
}: SubscriptionRewardsItemProps): JSX.Element => {
  const { t } = useTranslation('pageSubscription');

  return (
    <div className={classNames('rewards')}>
      <img className={classNames('rewards__poster')} src={image} alt={name} />
      <div className={classNames('rewards__data')}>
        <div className={classNames('rewards__data__label')}>{t('pageSubscription:rewards.title')}</div>
        <div className={classNames('rewards__data__title')}>{name}</div>
      </div>
      <Button primary onClick={() => onClick(_id)} small>
        {t('pageSubscription:rewards.cta')}
      </Button>
    </div>
  );
};
