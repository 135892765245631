import { useEffect } from 'react';
import type { UserSet } from 'model/indexTS';
import type { CouponRedemptionStatus } from 'services/coupons';
import { AsyncActionError } from 'types/indexTS';
import ILLUSTRATIONS from 'constants/illustrations';
import { withTranslation, TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import Dialog, { DialogContentImageColumn } from 'ui-library/dialog';
import { Alert } from 'ui-library/alert';
import LoadingSpinner from 'ui-library/loading-spinner';
import { PublicSetsDropdown } from 'components/public-sets-dropdown';
import { AuthDialog, useAuthTrackingEvents } from 'scenes/auth';
import { RedeemCouponResponseDialog } from './RedeemCouponResponseDialog';

interface RedeemCouponDialogProps extends CouponRedemptionStatus, TranslateInterface {
  isUserAuthenticated: boolean;
  onClose: (invalidateCoupon?: boolean) => void;
  showDialog: boolean;
  slug: string;
  onRedeemClick: () => void;
  onSetIdChange: (setId: string) => void;
  selectedSetId: string;
  couponUserSets: Array<UserSet>;
  errorCoupon?: string;
  isLoadingCoupon?: boolean;
}

const RedeemCouponDialogContent = (props: RedeemCouponDialogProps) => {
  const {
    couponExpired,
    isUserAuthenticated,
    chestsReceived,
    isLoading,
    error,
    slug,
    t,
    isLoadingCoupon,
    onRedeemClick,
    selectedSetId,
    onSetIdChange,
    couponUserSets,
    errorCoupon,
    onClose,
  } = props;

  const componentKey = 'couponRedemption';

  const { openModalTrackingEvent, closeModalTrackingEvent } = useAuthTrackingEvents();

  useEffect(() => {
    if (!isUserAuthenticated) {
      openModalTrackingEvent(componentKey);
    }
  }, [isUserAuthenticated, openModalTrackingEvent]);

  if (!isUserAuthenticated) {
    return (
      <AuthDialog
        componentKey={componentKey}
        location="free pack coupon redemption"
        onClose={() => {
          closeModalTrackingEvent(componentKey);
          onClose();
        }}
      >
        <Alert>{t('coupons:mustLogIn')}</Alert>
      </AuthDialog>
    );
  }

  if (couponExpired || errorCoupon || chestsReceived) {
    return (
      <RedeemCouponResponseDialog
        onClose={onClose}
        t={t}
        slug={slug}
        selectedSetId={selectedSetId}
        succeeded={!!chestsReceived}
        chestsReceived={chestsReceived}
      />
    );
  }

  return (
    <DialogContentImageColumn
      imageUrl={ILLUSTRATIONS.COUPONS}
      title={t('redeemCoupon')}
      renderFooter={() => <Button primary label={t('redeemCoupon')} loading={isLoading} onClick={onRedeemClick} />}
      onClose={onClose}
    >
      <p className="margin-bottom">{t('redeemCouponInfo', { slug })}</p>
      {isLoadingCoupon && <LoadingSpinner fixed />}
      <PublicSetsDropdown name="sets-picker" onChange={onSetIdChange} value={selectedSetId} sets={couponUserSets} />
      {error && <Alert type={Alert.ALERT_TYPES.ERROR}>{error.message}</Alert>}
    </DialogContentImageColumn>
  );
};

// This component it's in the same file for simplicity, since its responsibility is just to show the Dialog.
const RedeemCouponDialog = (props: RedeemCouponDialogProps) => {
  const { showDialog, isUserAuthenticated } = props;

  return (
    <Dialog
      fullOnSmall={!isUserAuthenticated}
      active={showDialog}
      type={isUserAuthenticated ? 'normal' : 'auth'}
      renderDialogBody={() => <RedeemCouponDialogContent {...props} />}
    />
  );
};

export default withTranslation('coupons')(RedeemCouponDialog);
