import { DateUtils } from './date-utils';

interface NormalizedDate {
  date: string;
  time: string;
}

export const normalizeDate = (date: Date | string): NormalizedDate => {
  return {
    date: DateUtils.formatDate(date),
    time: DateUtils.formatTime(date),
  };
};
