import { useMemo, useState } from 'react';
import ClassNames from '@streamloots/classnames';
import ScrollBarPanel from 'components/scrollbar-panel';
import { useUserSetsSelectors } from 'services/user-sets';
import { useUserSetLootsSelectors } from 'services/user-set-loots';
import { useReactAlert } from 'components/react-alert-provider';
import { usePageSubscriptionPlansSelectors } from 'services/page-subscription-plans';
import { useSubscriptionsActions, useSubscriptionsSelectors } from 'services/subscriptions';
import { usePageSelectors } from 'services/page';
import { useOpenLoots } from 'scenes/open-collection-loot';
import { useSetsActions } from 'services/sets';
import trackEventManager from 'utils/event-manager';
import { useTranslation } from 'utils/translation';
import { isActionError } from 'types/indexTS';
import { SubscriptionPacks } from './SubscriptionPacks';
import theme from './subscription-packs.scss';

const classNames = ClassNames(theme);

export const SubscriptionPackClaim = (): JSX.Element | null => {
  const [isClaiming, setIsClaiming] = useState(false);
  const {
    page: { slug },
  } = usePageSelectors();
  const { changeSelectedSet } = useSetsActions();
  const { claimSubscriptionPack, fetchSubscriptions } = useSubscriptionsActions();
  const { subscription } = useSubscriptionsSelectors();
  const { subscriptionPlan } = usePageSubscriptionPlansSelectors();
  const { showError } = useReactAlert();
  const { userSets } = useUserSetsSelectors();
  const { allCollectionLoots } = useUserSetLootsSelectors();
  const { openLoots } = useOpenLoots();
  const { t } = useTranslation(['pageSubscription', 'common']);

  const freePackInfo = useMemo(() => {
    if (!subscriptionPlan?.packsEnabled) {
      return null;
    }

    if (!subscription || !userSets) {
      return null;
    }

    const { enabledPacks } = subscriptionPlan;
    if (!enabledPacks?.length) {
      return null;
    }

    const { remainingPacks } = subscription;
    if (!remainingPacks?.length) {
      return null;
    }

    const enabledPack = enabledPacks[0];
    const firstRemainingPack = remainingPacks.find(remainingPack => remainingPack.setId === enabledPack.setId);
    const firstRemainingPackQuantity = firstRemainingPack?.quantity ?? 0;
    const userSet = userSets.find(set => set._id === enabledPack.setId);

    if (!firstRemainingPack || firstRemainingPackQuantity <= 0 || !userSet) {
      return null;
    }

    return {
      lootImageUrl: allCollectionLoots.find(loot => loot.set._id === enabledPack.setId)?.lootImageUrl || '',
      setName: userSet.name,
      ...firstRemainingPack,
    };
  }, [subscriptionPlan, subscription, userSets, allCollectionLoots]);

  if (!freePackInfo?.quantity) {
    return null;
  }

  const handleOnClaimGift = async (setId: string, amount: number) => {
    setIsClaiming(true);
    changeSelectedSet(setId);
    const response = await claimSubscriptionPack(slug, setId, amount);
    setIsClaiming(false);

    if (isActionError(response)) {
      showError(response.payload.message);
    } else {
      const { cards } = response.payload;
      openLoots({ cardsReceived: cards });

      trackEventManager.trackEvent('subscription_pack_claimed', { amount }, true);
      fetchSubscriptions(slug);
    }
  };

  return (
    <>
      <h2>{t('pageSubscription:packs.title')}</h2>
      <div className={classNames('subscription-claim')}>
        <ScrollBarPanel
          autoHeight
          autoHeightMin={38}
          autoHeightMax={150}
          contentClassName={classNames('subscription-claim__panel')}
        >
          <SubscriptionPacks
            imageUrl={freePackInfo.lootImageUrl}
            setId={freePackInfo.setId}
            setName={freePackInfo.setName}
            onClaim={handleOnClaimGift}
            amount={freePackInfo.quantity}
            t={t}
            isClaiming={isClaiming}
          />
        </ScrollBarPanel>
      </div>
    </>
  );
};
