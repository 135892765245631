import { useMemo, useState } from 'react';
import { GiftSubscriptionType, PaymentMethods } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { PageSubscriptionPlan, RenewalPeriod } from 'model/indexTS';
import { I18nType, Trans, TranslateInterface } from 'utils/translation';
import {
  SubscriptionGiftCommunityOrderFormValues,
  SubscriptionGiftUserOrderFormValues,
} from 'services/page-subscription-gifts';
import { PanelWithoutHeader } from 'ui-library/panel';
import { Alert } from 'ui-library/alert';
import AvatarImage from 'components/avatar-image';
import { PaymentSummary } from './subscription-info';
import { Stripe, StripePropsGiftProps } from './stripe';
import { GiftOptions } from './gift-options';
import { PaymentOptions } from './options';
import { PaypalSinglePurchase } from './paypal';
import { MonthsSelector } from './months-selector/MonthsSelector';
import theme from './payment-step.scss';

const classNames = ClassNames(theme);

export interface PaymentGiftStepProps extends TranslateInterface {
  giftType: GiftSubscriptionType;
  avatarImageUrl: string;
  slug: string;
  subscriptionPlan: PageSubscriptionPlan;
  i18n: I18nType;
  isOwner: boolean;
  onPaymentCompleted: () => void;
  onGiftTypeChange: (giftType: GiftSubscriptionType) => void;
  onCommunityGiftQuantityChange: (quantity: number) => void;
  onUserGiftGifteeChange: (giftee: string) => void;
  onCommunityGiftPrepaidMonthsChange: (totalPrepaidMonths: number | undefined) => void;
  communityGiftFormData: SubscriptionGiftCommunityOrderFormValues;
  userGiftFormData: SubscriptionGiftUserOrderFormValues;
  userGiftee: string;
}

export const PaymentGiftStep = ({
  avatarImageUrl,
  slug,
  subscriptionPlan,
  i18n,
  isOwner,
  communityGiftFormData,
  userGiftFormData,
  giftType,
  onGiftTypeChange,
  onCommunityGiftQuantityChange,
  onUserGiftGifteeChange,
  onCommunityGiftPrepaidMonthsChange,
  onPaymentCompleted,
  t,
  userGiftee,
}: PaymentGiftStepProps): JSX.Element => {
  const [processor, setProcessor] = useState(PaymentMethods.STRIPE);

  const payload: StripePropsGiftProps = {
    purchaseAsGift: true,
    giftPayload: giftType === GiftSubscriptionType.COMMUNITY ? communityGiftFormData : userGiftFormData,
  };

  const renewalPeriodsByMonths: Map<number, RenewalPeriod> = useMemo(() => {
    return subscriptionPlan.renewalPeriods.reduce((acc, period) => {
      return acc.set(period.totalPrepaidMonths, period);
    }, new Map());
  }, [subscriptionPlan.renewalPeriods]);

  const handleOnChangeMethod = (method: PaymentMethods): void => {
    setProcessor(method);
  };

  const handleRenewalPeriodChange = (newRenewalPeriod: RenewalPeriod | undefined) => {
    onCommunityGiftPrepaidMonthsChange(newRenewalPeriod?.totalPrepaidMonths);
  };

  return (
    <div className="p-5">
      <PanelWithoutHeader className="my-6" color="black-mid">
        <div className="flex items-center gap-3 justify-center">
          <AvatarImage src={avatarImageUrl} bordered rounded size="md" />
          <span className={classNames('payment-step__title')}>
            <Trans i18nKey="pageSubscription:checkout.giftTitle">
              {'Estás regalando suscripciones de '}
              <strong>{slug}</strong>
            </Trans>
          </span>
        </div>
      </PanelWithoutHeader>

      <GiftOptions
        t={t}
        giftType={giftType}
        onGiftTypeChange={onGiftTypeChange}
        onCommunityGiftQuantityChange={onCommunityGiftQuantityChange}
        onUserGiftGifteeChange={onUserGiftGifteeChange}
        communityGiftFormData={communityGiftFormData}
        userGiftee={userGiftee}
      />
      {giftType === GiftSubscriptionType.COMMUNITY && (
        <MonthsSelector
          renewalPeriod={
            'totalPrepaidMonths' in payload.giftPayload && payload.giftPayload.totalPrepaidMonths !== undefined
              ? renewalPeriodsByMonths.get(payload.giftPayload.totalPrepaidMonths)
              : undefined
          }
          onRenewalPeriodChange={handleRenewalPeriodChange}
          availableRenewalPeriods={subscriptionPlan.renewalPeriods}
        />
      )}
      <PaymentSummary
        quantity={giftType === GiftSubscriptionType.USER ? 1 : communityGiftFormData.quantity}
        subscriptionPlan={subscriptionPlan}
        renewalPeriod={
          'totalPrepaidMonths' in payload.giftPayload && payload.giftPayload.totalPrepaidMonths !== undefined
            ? renewalPeriodsByMonths.get(payload.giftPayload.totalPrepaidMonths)
            : undefined
        }
        t={t}
      />
      {isOwner ? (
        <Alert type={Alert.ALERT_TYPES.ERROR}>{t('pageSubscription:checkout.giftStep.ownerGift')}</Alert>
      ) : (
        <>
          <PaymentOptions t={t} method={processor} onChange={handleOnChangeMethod} />
          {processor === PaymentMethods.PAYPAL ? (
            <PaypalSinglePurchase
              currency={subscriptionPlan.currency}
              slug={slug}
              nextStep={onPaymentCompleted}
              giftPayload={payload.giftPayload}
              t={t}
            />
          ) : (
            <Stripe t={t} i18n={i18n} slug={slug} nextStep={onPaymentCompleted} {...payload} />
          )}
        </>
      )}
    </div>
  );
};
