import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PublicPageEmptyMessage, PublicPageEmptyMessageProps } from './PublicPageEmptyMessage';

interface PublicPageEmptyMessageWithRedirectionProps extends PublicPageEmptyMessageProps {
  redirectTo: string;
}

export const PublicPageEmptyMessageWithRedirection = ({
  redirectTo,
  ...rest
}: PublicPageEmptyMessageWithRedirectionProps): JSX.Element => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedirect(true);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (redirect) {
    return <Redirect to={redirectTo} />;
  }

  return <PublicPageEmptyMessage {...rest} />;
};
