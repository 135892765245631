import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { PageSubscriptionAlert } from 'model/indexTS';
import Icon from 'ui-library/icons/Icons';
import Button, { ButtonIcon } from 'ui-library/button';
import trackEventManager from 'utils/event-manager';
import mediaOptimizer from 'utils/media-optimizer';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { FileTypeFilter, Languages } from '@streamloots/streamloots-types';
import { getTypeFromUri } from 'utils/fileTypeChecker';
import theme from './subscription-alerts.scss';
import { SubscriptionCountdown } from '../subscription-countdown/SubscriptionCountdown';

export interface SubscriptionAlertsItemProps {
  alert: PageSubscriptionAlert;
  isSubscribed?: boolean;
  isReactionPlaying: boolean;
  remainingAlerts: number;
  onClick: (_id: string) => void;
  onFinishCountDown: () => void;
  cooldownSeconds: number;
  language: Languages;
  onSubscribe: (cta: string, name?: string) => void;
}

interface ExtendedProps extends SubscriptionAlertsItemProps, TranslateInterface {}

const classNames = ClassNames(theme);

const SubscriptionAlertsItemBase: React.FunctionComponent<ExtendedProps> = ({
  alert,
  remainingAlerts,
  isSubscribed = false,
  isReactionPlaying,
  language,
  onClick,
  onSubscribe,
  onFinishCountDown,
  cooldownSeconds,
  t,
}: ExtendedProps): JSX.Element => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);

  const localizedName =
    language.toLowerCase() === Languages.Spanish ? alert.locales.titles.es : alert.locales.titles.en;

  const handleOnPause = () => {
    if (!videoRef.current) {
      return;
    }

    setIsVideoPlaying(false);
    videoRef.current.load();
  };

  const handlePlay = (source: string) => {
    if (!videoRef.current) {
      return;
    }

    if (isVideoPlaying) {
      videoRef.current.pause();
    } else {
      const playPromise = videoRef.current.play();
      trackEventManager.trackEvent('subscriptionAlertPreviewed', { name: alert.name, source });
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          if (!RegExp(/The play\(\) request was interrupted/i).test(error.message)) {
            throw error;
          }
        });
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  const isImage = getTypeFromUri(alert.videoUrl) === FileTypeFilter.IMAGES;

  return (
    <div className={classNames('subs__alerts__item')}>
      <div className={classNames('subs__alerts__item__image')}>
        <video
          key={alert.name}
          ref={videoRef}
          title={`Video: ${alert.name}`}
          preload="none"
          poster={mediaOptimizer.optimizeImage(alert.imageUrl)}
          onPause={handleOnPause}
          onClick={() => handlePlay('video')}
          playsInline
        >
          <source src={mediaOptimizer.optimizeVideo(alert.videoUrl, { width: 248, quality: 100 })} type="video/webm" />
        </video>
        {!isVideoPlaying && !isImage && (
          <div className={classNames('subs__alerts__item__image--play')} onClick={() => handlePlay('button')}>
            <Icon id="circle_play" ariaLabel="Play" />
          </div>
        )}
      </div>
      <div className={classNames('subs__alerts__item__content')}>
        <div className={classNames('subs__alerts__item__title')}>{localizedName}</div>
        <div className={classNames('subs__alerts__item__footer')}>
          {!isSubscribed && (
            <Button primary onClick={() => onSubscribe('ctaAlert', alert.name)}>
              {t('collection:subscriptionsAlert.ctaSubscribe')}
            </Button>
          )}
          {isSubscribed && remainingAlerts > 0 && (
            <ButtonIcon
              ariaLabel={t('collection:subscriptionsAlert.ariaPlay', { name: localizedName })}
              label={t('collection:subscriptionsAlert.ctaPlay')}
              icon="play"
              primary
              small
              onClick={() => onClick(alert._id)}
              disabled={isReactionPlaying}
            />
          )}
          {isSubscribed && isReactionPlaying && (
            <SubscriptionCountdown onComplete={onFinishCountDown} seconds={cooldownSeconds} />
          )}
          {isSubscribed && !isReactionPlaying && remainingAlerts === 0 && t('collection:subscriptionsAlert.noAlerts')}
        </div>
      </div>
    </div>
  );
};

export const SubscriptionAlertsItem = withTranslation('collection')(
  SubscriptionAlertsItemBase,
) as React.FunctionComponent<SubscriptionAlertsItemProps>;
