import { useState, useCallback, useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { PageBadge } from 'components/page-type-badge';
import AvatarImage, { ROUNDED_PLACEHOLDER_IMAGE } from 'components/avatar-image';
import BuyLootPacksPicker from 'components/buy-loot-packs-picker';
import Button from 'ui-library/button';
import { PageType } from '@streamloots/streamloots-types';
import { SocialProof } from '../social-proof';
import theme from './floating-header.scss';

const classNames = ClassNames(theme);

interface FloatingPublicHeaderProps extends TranslateInterface {
  avatarImageUrl: string;
  slug: string;
  onSubscribe: (cta: string, name?: string) => void;
  showSubscribeButton?: boolean;
  showBuyPacks?: boolean;
  ambassador: boolean;
  pageType: PageType;
}

export const FloatingPublicHeader = ({
  avatarImageUrl,
  slug,
  onSubscribe,
  t,
  showSubscribeButton = false,
  showBuyPacks = false,
  pageType,
  ambassador,
}: FloatingPublicHeaderProps): JSX.Element | null => {
  const [mergedHeader, setMergedHeader] = useState(false);

  const handleScroll = useCallback(() => {
    const topPixels = 230;
    const current = window.scrollY;
    if (!mergedHeader && current >= topPixels) {
      setMergedHeader(true);
    } else if (mergedHeader && current < topPixels) {
      setMergedHeader(false);
    }
  }, [mergedHeader]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (!mergedHeader || (!showBuyPacks && !showSubscribeButton)) {
    return null;
  }

  return (
    <div className={classNames('public-header')}>
      <div className={classNames('public-header__page')} data-testid="public-user-page-header">
        <AvatarImage
          src={avatarImageUrl}
          alt="avatar"
          className={classNames('public-header__avatar')}
          fallbackSrc={ROUNDED_PLACEHOLDER_IMAGE}
        />
        <PageBadge
          type={pageType}
          className={classNames('public-header__badge')}
          showRegular={false}
          ambassador={ambassador}
        />

        <div className={classNames('public-header__slug')}>
          <h1 className={classNames('public-header__title')}>{slug}</h1>
          <SocialProof shorter />
        </div>
      </div>
      <div className={classNames('public-header__actions')}>
        {showSubscribeButton && (
          <Button color="info" variant="filled" onClick={() => onSubscribe('merged')}>
            {t('collection:newViewersSteps.subscribe')}
          </Button>
        )}
        {showBuyPacks && (
          <BuyLootPacksPicker buttonId="FixHeaderStreamerPage" label={t('collection:newViewersSteps.getPacks')} />
        )}
      </div>
    </div>
  );
};
