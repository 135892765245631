import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import Icon from 'ui-library/icons/Icons';
import { useOverloadedContentActions } from 'services/overloaded-content';
import { DialogBuyLootPacksPicker } from 'components/buy-loot-packs-picker';
import { LootOrderType } from '@streamloots/streamloots-types';
import theme from './public-user-page-nav.scss';

const classNames = ClassNames(theme);

export interface PublicUserPageNavCommunityGiftProps extends TranslateInterface {
  className?: string;
}

export const PublicUserPageNavCommunityGift = ({
  className = '',
  t,
}: PublicUserPageNavCommunityGiftProps): JSX.Element => {
  const { openOverloadedContent } = useOverloadedContentActions();

  const handleCommunityGiftClick = () => {
    trackEventManager.buyPacksStarted({
      location: 'streamerNavCommunityGift',
      type: LootOrderType.RANDOM_COMMUNITY_GIFT,
    });

    openOverloadedContent({
      component: DialogBuyLootPacksPicker,
      wrapperProps: {
        type: 'large',
        opacity: true,
        overflowH: true,
        noCentered: true,
      },
      componentProps: {
        openAsGift: true,
        startLocation: 'streamerNavCommunityGift',
      },
    });
  };

  return (
    <button
      className={classNames({
        'pup-nav__button-action': true,
        'pup-nav__community-gift': true,
        [className]: Boolean(className),
      })}
      type="button"
      onClick={handleCommunityGiftClick}
    >
      <Icon className={classNames('public-header__icon')} id="gift" />
      <span>{t('collection:communitySteps.getPacks')}</span>
      <Icon className={classNames('public-header__icon pup-nav__community-gift__icon')} id="chevron-right" />
    </button>
  );
};
