import { Link } from 'react-router-dom';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import stringUtils from 'helpers/stringFormat';
import { urls } from 'services/routing';
import { PublicPageEmptyMessageWithRedirection } from 'components/public-page-empty-message';
import { PageModulesValues } from 'model/indexTS';

interface NoModulesEnabledMessageProps extends TranslateInterface {
  moduleKey: PageModulesValues;
  slug: string;
}

export const NoModulesEnabledMessage = ({ moduleKey, slug, t }: NoModulesEnabledMessageProps): JSX.Element => {
  const capitalizedSlug = stringUtils.capitalizeFirstLetter(slug);

  return (
    <PublicPageEmptyMessageWithRedirection
      icon="chat-alert"
      message={t('publicUserPage:noModulesEnabled.description', { slug: capitalizedSlug })}
      title={t(`publicUserPage:noModulesEnabled.${moduleKey}`)}
      redirectTo={urls().USER_PAGE}
    >
      <Button component={Link} to={urls().USER_PAGE} label={t('publicUserPage:noModulesOwner.cta')} primary />
    </PublicPageEmptyMessageWithRedirection>
  );
};
