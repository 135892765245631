import ClassNames from '@streamloots/classnames';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageSelectors } from 'services/page';
import { pageSubscriptionRewardsActions, pageSubscriptionRewardsSelectors } from 'services/page-subscription-rewards';
import Skeleton from 'ui-library/skeleton';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { cultureSelectors } from 'services/culture';
import { Languages } from '@streamloots/streamloots-types';
import { isActionError } from 'types/indexTS';
import { useReactAlert } from 'components/react-alert-provider';
import { SubscriptionRewardsItem } from './SubscriptionRewardsItem';
import theme from './subscription-rewards.scss';

export interface SubscriptionRewardsProps {
  hasAlertsEnabled: boolean;
  isSubscribed: boolean;
}

const classNames = ClassNames(theme);

export const SubscriptionRewards: FunctionComponent<SubscriptionRewardsProps> = ({
  hasAlertsEnabled,
  isSubscribed,
}: SubscriptionRewardsProps): JSX.Element | null => {
  const { showError, showSuccess } = useReactAlert();
  const language = useSelector(cultureSelectors.language);
  const pageId = useSelector(pageSelectors.pageId);
  const pageSubscriptionRewards = useSelector(pageSubscriptionRewardsSelectors.getPageSubscriptionRewards);
  const dispatch = useDispatch();
  const { t } = useTranslation('pageSubscription');

  useEffect(() => {
    if (isSubscribed) {
      dispatch(pageSubscriptionRewardsActions.fetchPageSubscriptionRewards(pageId));
    }
  }, [dispatch, isSubscribed, pageId]);

  if (!hasAlertsEnabled || !isSubscribed || !pageSubscriptionRewards.rewards) {
    return null;
  }

  if (pageSubscriptionRewards.isLoading) {
    return <Skeleton height={50} className={classNames('rewards__skeleton')} />;
  }

  const handleOnClick = (_id: string) => {
    dispatch(pageSubscriptionRewardsActions.playPageSubscriptionReward(_id))
      .then(res => {
        if (isActionError(res)) {
          showError(res);
          return;
        }
        showSuccess(t('pageSubscription:rewards.success'));
        trackEventManager.trackEvent('im_subscribed_played', { alertId: _id });
      })
      .catch(err => {
        showError(err);
      });
  };

  return (
    <div className={classNames('rewards-container')}>
      {pageSubscriptionRewards.rewards
        .filter(reward => reward.status !== 'used')
        .map(reward => (
          <SubscriptionRewardsItem
            key={reward._id}
            _id={reward._id}
            name={language === Languages.Spanish ? reward.metadata.spanishTitle : reward.metadata.englishTitle}
            image={reward.metadata.imageUrl}
            onClick={handleOnClick}
          />
        ))}
    </div>
  );
};
