import { CloseOverloadedContent } from 'services/overloaded-content';
import { useTranslation } from 'react-i18next';

import Button from 'ui-library/button';
import { CleanDialogContent } from 'ui-library/dialog';
import ClassNames from '@streamloots/classnames';
import theme from './open-share-recap-from-url.scss';

const classNames = ClassNames(theme);

interface ShareRecapDialogBodyProps {
  onClose: CloseOverloadedContent;
  imageUrl: string;
  slug: string;
}

export const ShareRecapDialogBody = ({ imageUrl, onClose, slug }: ShareRecapDialogBodyProps): JSX.Element | null => {
  const { t } = useTranslation('common');

  return (
    <CleanDialogContent onClose={onClose}>
      <div className={classNames('stats-banner')}>
        <img src={imageUrl} alt="stats-banner" />
      </div>
      <div className={classNames('visit-page-btn')}>
        <Button onClick={onClose} color="info" variant="filled" size="lg">
          {t('common:shareRecapClose', { slug })}
        </Button>
      </div>
    </CleanDialogContent>
  );
};
