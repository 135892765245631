import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import useMedia from 'hooks/use-media';
import { USE_MEDIA_BREAKPOINTS } from 'constants/stylesBreakpoints';
import { PublicUserPageNaveRanking } from './PublicUserPageNavRanking';
import { PublicUserPageNavTreasure } from './PublicUserPageNavTreasure';
import { PublicUserPageNavCommunityGift } from './PublicUserPageNavCommunityGift';
import theme from './public-user-page-nav.scss';

interface OwnProps extends TranslateInterface {
  onOpenLoots: () => void;
}

const classNames = ClassNames(theme);

export const PublicUserPageNav = ({ onOpenLoots, t }: OwnProps): JSX.Element => {
  const isMobile = useMedia(USE_MEDIA_BREAKPOINTS.maxWidthString.mobile);

  if (isMobile) {
    return (
      <div className={classNames('pup-nav pup-nav__mobile')}>
        <PublicUserPageNavCommunityGift t={t} className={classNames('pup-nav__left__gift')} />
      </div>
    );
  }

  return (
    <div className={classNames('pup-nav')}>
      <div className={classNames('pup-nav__left')}>
        <PublicUserPageNavCommunityGift t={t} className={classNames('pup-nav__left__gift')} />
      </div>
      <div className={classNames('pup-nav__right')}>
        <PublicUserPageNavTreasure onOpenLoots={onOpenLoots} t={t} />
        <PublicUserPageNaveRanking />
      </div>
    </div>
  );
};
