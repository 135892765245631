import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { PAGE_EMOTES_FILTERED_ACTION } from './types';
import { PageEmotesActionTypes } from './actionTypes';

// ----- Fetch Emotes -----
const fetchEmotesError = ({ errorData }) =>
  actionCreator.createErrorAction(PageEmotesActionTypes.FETCH_PAGE_EMOTES_ERROR, errorData);

const fetchEmotesSuccess = ({ data }) =>
  actionCreator.createAction(PageEmotesActionTypes.FETCH_PAGE_EMOTES_SUCCESS, data);

const fetchPageEmotes = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_EMOTES.GET,
    method: 'get',
    urlParameters: { slug },
    loadingAction: PageEmotesActionTypes.FETCH_PAGE_EMOTES_LOADING,
    errorBinding: fetchEmotesError,
    successBinding: fetchEmotesSuccess,
  });

// ----- Play Emotes -----
const playPageEmotesError = ({ errorData }) =>
  actionCreator.createErrorAction(PageEmotesActionTypes.PLAY_PAGE_EMOTES_ERROR, errorData);

const playPageEmotesSuccess = ({ data }) =>
  actionCreator.createAction(PageEmotesActionTypes.PLAY_PAGE_EMOTES_SUCCESS, data);

const playPageEmotes = (pageId: string, emotes: string[]) => {
  const emoteData = emotes.map(emote => ({ name: emote }));

  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_EMOTES.PLAY,
    method: 'post',
    urlParameters: { pageId },
    parameters: [...emoteData],
    errorBinding: playPageEmotesError,
    successBinding: playPageEmotesSuccess,
  });
};

const filterPageEmotes = (searchString: string): PAGE_EMOTES_FILTERED_ACTION =>
  actionCreator.createAction(PageEmotesActionTypes.PAGE_EMOTES_FILTERED, { searchString });

// ----- Module exports ------
export const pageEmotesActions = { fetchPageEmotes, playPageEmotes, filterPageEmotes };
