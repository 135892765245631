import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { Link } from 'react-router-dom';
import Icon from 'ui-library/icons/Icons';
import trackEventManager from 'utils/event-manager';
import { getUrlWithParams } from 'services/routing';
import { NewLabel } from 'ui-library/new-indicator';
import { PageModulesValues } from 'model/indexTS';
import theme from './nav-tabs.scss';

export interface OwnProps {
  tabId: PageModulesValues;
  icon: string;
  label: ReactNode;
  slug: string;
  isSelected: boolean;
  isNew?: boolean;
  unreadCount?: number;
  unreadTitle?: string;
  link: string;
}

const classNames = ClassNames(theme);

export const NavTabsItem = ({
  icon,
  unreadCount = 0,
  label,
  slug,
  link,
  isNew,
  isSelected,
  unreadTitle,
}: OwnProps): JSX.Element => {
  const url = getUrlWithParams(link, { slug });

  return (
    <Link to={url} className={classNames('nav-tabs__item')}>
      <Icon id={icon} className={classNames('nav-tabs__item__icon')} />
      {label}
      {unreadCount > 0 && (
        <span className={classNames('nav-tabs__count')} title={unreadTitle}>
          {unreadCount}
        </span>
      )}
      {isNew && (
        <div data-testid="new-label" className={classNames('nav-tabs__item__new')}>
          <NewLabel />
        </div>
      )}
      {isSelected && <div data-testid="selected-tab" className={classNames('nav-tabs__item--selected')} />}
    </Link>
  );
};
