import { Link } from 'react-router-dom';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import stringUtils from 'helpers/stringFormat';
import { urls } from 'services/routing';
import { PublicPageEmptyMessage } from 'components/public-page-empty-message';

export interface NoModulesMessageProps extends TranslateInterface {
  slug: string;
  isOwner?: boolean;
}

export const NoModulesMessage = ({ isOwner, slug, t }: NoModulesMessageProps): JSX.Element => {
  const capitalizedSlug = stringUtils.capitalizeFirstLetter(slug);

  if (isOwner) {
    return (
      <PublicPageEmptyMessage
        icon="chat-alert"
        title={t('publicUserPage:noModulesOwner.title')}
        message={t('publicUserPage:noModulesOwner.description')}
      >
        <Button component={Link} to={urls().DASHBOARD} label={t('publicUserPage:noModulesOwner.cta')} primary />
      </PublicPageEmptyMessage>
    );
  }

  return (
    <PublicPageEmptyMessage
      icon="drama-masks"
      title={t('publicUserPage:noModules.title', { slug: capitalizedSlug })}
      message={t('publicUserPage:noModules.description', { slug: capitalizedSlug })}
    >
      <Button component={Link} to={urls().STREAMERS} label={t('publicUserPage:noModules.cta')} primary />
    </PublicPageEmptyMessage>
  );
};
