import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface, withTranslation } from 'utils/translation';
import Icon from 'ui-library/icons/Icons';
import theme from './subscription-gifts.scss';

export interface SubscriptionGiftsProps {
  onClick: () => void;
}

interface ExtendedProps extends SubscriptionGiftsProps, TranslateInterface {}

const classNames = ClassNames(theme);

const SubscriptionGiftsBase = ({ t, onClick }: ExtendedProps): JSX.Element => {
  return (
    <div className={classNames('subscription-gift')}>
      <button
        type="button"
        className={classNames('subscription-gift__action subscription-gift__cta')}
        onClick={onClick}
      >
        <Icon className={classNames('subscription-gift__cta__icon')} id="gift" />
        <span>{t('pageSubscription:publicPage.giftCta')}</span>
        <Icon className={classNames('public-header__cta__icon')} id="chevron-right" />
      </button>
    </div>
  );
};

export const SubscriptionGifts = withTranslation('pageSubscription')(SubscriptionGiftsBase);
