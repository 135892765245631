import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { PanelWithoutHeader } from 'ui-library/panel';
import Icon from 'ui-library/icons/Icons';
import theme from './subscription-cancel.scss';

const classNames = ClassNames(theme);

interface SecondStepProps extends TranslateInterface {
  slug: string;
  onClose: () => void;
}

export const SecondStep = ({ slug, onClose, t }: SecondStepProps): JSX.Element => {
  return (
    <PanelWithoutHeader
      className={classNames('subscription-cancel__first-step')}
      contentClassName={classNames('subscription-cancel__second-step__content')}
    >
      <button className={classNames('subscription-cancel__second-step__close')} type="button" onClick={onClose}>
        <Icon id="close" />
      </button>
      <div className={classNames('subscription-cancel__second-step__content--child')}>
        <div className={classNames('subscription-cancel__second-step__title')}>
          {t('pageSubscription:cancelSubscription.secondStep.success')}
        </div>
        <div className={classNames('subscription-cancel__second-step__description')}>
          {t('pageSubscription:cancelSubscription.secondStep.successDetail', { slug })}
        </div>
      </div>
      <div className={classNames('subscription-cancel__second-step__buttons')}>
        <Button onClick={onClose} cta>
          {t('pageSubscription:cancelSubscription.secondStep.understood')}
        </Button>
      </div>
    </PanelWithoutHeader>
  );
};
