import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import { PagesModules } from '@streamloots/streamloots-types';
import type { PageModules } from 'model/indexTS';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { userSetLootsSelectors } from 'services/user-set-loots';
import { userSetsSelectors } from 'services/user-sets';
import Button from 'ui-library/button';
import { GetSubscriptionOutput } from 'services/subscriptions';
import { PageModulesChecker } from 'components/page-modules-checker';
import { setIdToView } from 'domain/public-page';
import { CreatorInfo } from './CreatorInfo';
import { SubscriptionButton } from './SubscriptionButton';
import theme from './intro-banner.scss';

const classNames = ClassNames(theme);

export interface CreatorHeaderProps {
  onCollectionPurchase: (location: string) => void;
  onSubscribe: (cta: string, name?: string) => void;
  onCancelSubscription: () => void;
  hasUserSets?: boolean;
  subscription: GetSubscriptionOutput;
  modules: PageModules | undefined;
}

export const CreatorHeader = ({
  hasUserSets,
  subscription,
  onCollectionPurchase,
  onSubscribe,
  onCancelSubscription,
  modules,
}: CreatorHeaderProps): JSX.Element => {
  const { t } = useTranslation(['collection', 'pageSubscription']);
  // TODO: Migrate to hooks services
  const lootImageUrl: string = useSelector(state => {
    const selectedSetId = userSetsSelectors.setId(state);
    const marathonSpecialSet = userSetsSelectors.marathonSpecialSet(state);
    const setId = setIdToView(modules, marathonSpecialSet, selectedSetId);
    return userSetLootsSelectors.lootImageUrl(state, setId);
  });

  const handlePurchaseOpen = (event: MouseEvent) => {
    trackEventManager.buyPacksStarted({
      location: event.currentTarget.id,
      type: null,
    });

    onCollectionPurchase(event.currentTarget.id);
  };

  return (
    <div className={classNames('intro-banner')}>
      <div className={classNames('intro-banner__container')}>
        <CreatorInfo />
        <div className={classNames('intro-banner__container__ctas')}>
          <PageModulesChecker modulesRequired={PagesModules.Subscriptions}>
            <SubscriptionButton
              buttonId="header"
              subscription={subscription}
              onSubscriptionClick={onSubscribe}
              onCancelSubscription={onCancelSubscription}
              t={t}
            />
          </PageModulesChecker>
          <PageModulesChecker modulesRequired={[PagesModules.Cards, PagesModules.Marathons]}>
            {hasUserSets && (
              <Button
                color="checkout"
                variant="filled"
                id="streamerMessageButton"
                aria-label={t('collection:newViewersSteps.getPacks')}
                onClick={handlePurchaseOpen}
                className={classNames(
                  'intro-banner__container__ctas__btn-txt intro-banner__container__ctas__buy-packs',
                )}
              >
                {t('collection:newViewersSteps.getPacks')}
                {lootImageUrl && (
                  <span className={classNames('intro-banner__container__ctas__buy-packs__pack')}>
                    <img src={lootImageUrl} alt="chest" />
                  </span>
                )}
              </Button>
            )}
          </PageModulesChecker>
        </div>
      </div>
    </div>
  );
};
