import { usePageSelectors } from 'services/page';
import useLocalStorage from 'use-local-storage';

type Serializer<T> = (object: T | undefined) => string;
type Parser<T> = (val: string) => T | undefined;
type Options<T> = Partial<{
  serializer: Serializer<T>;
  parser: Parser<T>;
  logger: (error: any) => void;
  syncData: boolean;
}>;

export const useLocalStorageBySlug = <T>(storageKey: string, defaultValue, options?: Options<T>) => {
  const {
    page: { slug },
  } = usePageSelectors();

  const [storedValue, setStoredValue] = useLocalStorage(`${storageKey}.${slug}`, defaultValue, options);

  return {
    storedValue,
    setStoredValue,
  };
};
