import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import Countdown, { zeroPad } from 'react-countdown-now';
import { TranslateInterface, Trans } from 'utils/translation';
import Button from 'ui-library/button';
import AvatarImage, { ROUNDED_PLACEHOLDER_IMAGE } from 'components/avatar-image';
import Tooltip, { TooltipPlacement } from 'ui-library/tooltip';
import { GifteerUser } from 'model/indexTS';
import theme from './claim-community-gift.scss';

const classNames = ClassNames(theme);

interface UserGifterProps extends TranslateInterface {
  claimGift: (pageId: string, giftCommunityId: string) => void;
  giftCommunity: GifteerUser;
  pageId: string;
  isClaiming: boolean;
  isDisabled: boolean;
  showCountdown: boolean;
  nextTimeAvailable?: number | null;
  onCompleteCountdown: () => void;
}

const timerRenderer = ({ minutes, seconds }): string => {
  return `${minutes}:${zeroPad(seconds)}`;
};

export const UserGifter = ({
  giftCommunity,
  claimGift,
  t,
  pageId,
  isDisabled,
  isClaiming,
  showCountdown,
  nextTimeAvailable,
  onCompleteCountdown,
}: UserGifterProps): JSX.Element => {
  const handleClaim = () => {
    claimGift(pageId, giftCommunity._id);
  };

  return (
    <div
      className={classNames({
        'claim-community-gift__container': true,
      })}
      key={giftCommunity._id}
    >
      <AvatarImage
        className={classNames('claim-community-gift__avatar')}
        src={giftCommunity.gifterImageUrl}
        alt={giftCommunity.gifterUsername}
        fallbackSrc={ROUNDED_PLACEHOLDER_IMAGE}
      />
      <div className={classNames('claim-community-gift__description')}>
        <p>
          <Trans i18nKey="collection:claimGift.description">
            <span className={classNames('claim-community-gift__username')}>{giftCommunity.gifterUsername}</span>
            {' is gifting '}
            {t('collection:claimGift.pack', { count: giftCommunity.purchasedLootsQuantity })}
          </Trans>{' '}
        </p>
        {showCountdown && nextTimeAvailable ? (
          <Tooltip placement={TooltipPlacement.TOP} tooltip={t('collection:claimGift.tooltip')}>
            <div>
              <Button type="button" size="sm" primary disabled>
                <Countdown onComplete={onCompleteCountdown} date={nextTimeAvailable} renderer={timerRenderer} />
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button onClick={handleClaim} primary loading={isClaiming} disabled={isDisabled} size="sm">
            {t('collection:claimGift.claim')}
          </Button>
        )}
      </div>
      <p className={classNames('claim-community-gift__remain')}>
        <Trans i18nKey="remainingText">
          <span>{String(giftCommunity.remainingLootsQuantity)}</span>{' '}
          {t('collection:claimGift.remaining', { count: giftCommunity.remainingLootsQuantity })}
        </Trans>
      </p>
    </div>
  );
};
