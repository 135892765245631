import { PageType } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { usePageSelectors } from 'services/page';
import AvatarImage from 'components/avatar-image';
import { PageBadgeWithLabel } from 'components/page-type-badge';
import { AchievementBadges } from 'components/achievement-badge';
import { ExternalCreatorLink } from './ExternalCreatorLink';
import { SocialProof } from '../social-proof';
import { StreamLink } from './StreamLink';
import theme from './intro-avatar-thumbnail.scss';

const classNames = ClassNames(theme);

export const CreatorInfo = (): JSX.Element | null => {
  const {
    page: { slug, owner, ambassador = false, type = PageType.REGULAR, achievementBadges },
  } = usePageSelectors();

  if (!owner) {
    return null;
  }

  const {
    profile: { avatarImageUrl },
    streamingId,
    primaryAuthProvider,
    twitterAcount: twitterAccount,
    discordServer,
  } = owner;

  return (
    <div className={classNames('creator-info')}>
      <div>
        <AvatarImage src={avatarImageUrl} alt="Avatar" className={classNames('creator-info__avatar')} />
      </div>
      <div>
        {type !== PageType.REGULAR && (
          <div className={classNames('creator-info__badges')}>
            {achievementBadges && <AchievementBadges achievementBadges={achievementBadges} size="md" />}
            <PageBadgeWithLabel type={type} ambassador={ambassador} className={classNames('creator-info__badge')} />
          </div>
        )}
        <h2 className={classNames('creator-info__username')}>{slug}</h2>
        <div className={classNames('creator-info__links')}>
          <StreamLink primaryAuthProvider={primaryAuthProvider} streamingId={streamingId} />
          {twitterAccount && (
            <ExternalCreatorLink url={twitterAccount} icon="twitter" eventId="twitter" title="Twitter" />
          )}
          {discordServer && (
            <ExternalCreatorLink url={discordServer} icon="discord" eventId="discord" title="Discord server" />
          )}
          <SocialProof shorter />
        </div>
      </div>
    </div>
  );
};
