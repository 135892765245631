import { FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import theme from './subscription-alerts.scss';

const classNames = ClassNames(theme);

export interface SubscriptionAlertsHeaderProps extends TranslateInterface {
  hasAlertsEnabled: boolean;
  isSubscribed: boolean;
  totalAlerts: number;
}

export const SubscriptionAlertsHeader: FunctionComponent<SubscriptionAlertsHeaderProps> = ({
  hasAlertsEnabled,
  isSubscribed,
  totalAlerts,
  t,
}: SubscriptionAlertsHeaderProps): JSX.Element => {
  const showMustSubscribe = hasAlertsEnabled && !isSubscribed;
  const showAlerts = hasAlertsEnabled && isSubscribed;

  return (
    <>
      <div className={classNames('subs__title')}>
        <div>
          {isSubscribed
            ? t('collection:subscriptionsAlert.title.subscribed')
            : t('collection:subscriptionsAlert.title.nonSubscribed')}
        </div>
        {showAlerts && <div className={classNames('subs__title__star')}>{totalAlerts}</div>}
      </div>
      <div className={classNames('subs__need')}>
        {!hasAlertsEnabled && t('collection:subscriptionsAlert.noOfferAlerts')}
        {showMustSubscribe && t('collection:subscriptionsAlert.text.nonSubscribed')}
        {showAlerts && t('collection:subscriptionsAlert.text.subscribed', { count: totalAlerts })}
      </div>
    </>
  );
};
