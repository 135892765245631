import { FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { Emote } from 'model/emotesTS';
import Tooltip from 'ui-library/tooltip';
import { isEmpty } from 'lodash';
import theme from './subscription-alerts.scss';

export interface SubscriptionAlertsEmotesCollectionProps {
  title: string;
  emotes: Emote[];
  onClick: (emote: Emote) => void;
}

const classNames = ClassNames(theme);

export const SubscriptionAlertsEmotesCollection: FunctionComponent<SubscriptionAlertsEmotesCollectionProps> = ({
  title,
  emotes,
  onClick,
}: SubscriptionAlertsEmotesCollectionProps): JSX.Element | null => {
  if (isEmpty(emotes) || isEmpty(title)) {
    return null;
  }

  return (
    <div className={classNames('subs__emotes__collection')}>
      <div className={classNames('subs__emotes__collection__name')}>{title}</div>
      <div className={classNames('subs__emotes__collection__tiles')}>
        {emotes.map(emote => (
          <Tooltip
            tooltip={emote.publicName}
            key={emote.name}
            className={classNames('subs__emotes__collection__tooltip')}
            placement="top"
          >
            <div
              className={classNames('subs__emotes__collection__tiles__tile')}
              onClick={() => onClick(emote)}
              role="button"
            >
              <img src={emote.imageUrl} alt={emote.name} />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
