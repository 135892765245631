import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { UserCard } from 'model/cardTS';
import { withTranslation, TranslateInterface } from 'utils/translation';
import { CraftingModeProviderContext } from 'contexts/crafting-mode-provider';
import { userSetsSelectors } from 'services/user-sets';
import { userSetCardsSelectors } from 'services/user-set-cards';
import { craftingModeSelectors } from 'services/crafting-mode';
import Dialog, { CleanDialogContent } from 'ui-library/dialog';
import { CardCrafting } from './CardCrafting';

interface MapStateToProps {
  card: UserCard;
  dustBalance: number;
}

interface ConnectedCraftingCardOwnProps {
  cardId: string;
}

interface ConnectedCraftingCardProps extends MapStateToProps, TranslateInterface {}

const ConnectedCraftingCardInternal = ({ card, dustBalance, t }: ConnectedCraftingCardProps): JSX.Element | null => {
  const { closeCraftingModeCardView } = React.useContext(CraftingModeProviderContext);

  if (!card) {
    return null;
  }

  return (
    <Dialog
      type="large"
      showBlurBackground
      onClose={closeCraftingModeCardView}
      onEscKeyDown={closeCraftingModeCardView}
      onOverlayClick={closeCraftingModeCardView}
      renderDialogBody={() => (
        <CleanDialogContent onClose={closeCraftingModeCardView}>
          <CardCrafting card={card} dustBalance={dustBalance} t={t} />
        </CleanDialogContent>
      )}
      active
    />
  );
};

const mapStateToProps = (state, props: ConnectedCraftingCardOwnProps): MapStateToProps => {
  const setId = userSetsSelectors.setId(state);
  return {
    card: userSetCardsSelectors.card(state, setId, props.cardId),
    dustBalance: craftingModeSelectors.dustBalance(state),
  };
};

export const ConnectedCraftingCard = compose<React.FunctionComponent<ConnectedCraftingCardOwnProps>>(
  connect(mapStateToProps),
  withTranslation('disenchant'),
)(ConnectedCraftingCardInternal);
