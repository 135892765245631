import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ClassNames from '@streamloots/classnames';
import { Emote } from '@streamloots/streamloots-types';
import { TranslateInterface } from 'utils/translation';
import Skeleton from 'ui-library/skeleton';
import { SearchInput } from 'ui-library/search-input';
import { useSubscriptionAlertsEmotes } from './useSubscriptionAlertsEmotes';
import { SubscriptionAlertsEmotesCollection } from './SubscriptionAlertsEmotesCollection';
import { SubscriptionAlertsEmotesFooter } from './SubscriptionAlertsEmotesFooter';
import theme from './subscription-alerts.scss';

export interface SubscriptionAlertsEmotesProps extends TranslateInterface {
  isSubscribed: boolean;
  onSubscribe: (cta: string, name: string) => void;
}

const classNames = ClassNames(theme);

export const SubscriptionAlertsEmotes: FunctionComponent<SubscriptionAlertsEmotesProps> = ({
  t,
  isSubscribed,
  onSubscribe,
}: SubscriptionAlertsEmotesProps): JSX.Element | null => {
  const emotesDiv = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    collections,
    language,
    searchString,
    filterPageEmotes,
    fetchPageEmotes,
    slug,
  } = useSubscriptionAlertsEmotes();
  const [currentEmotes, setCurrentEmotes] = useState<Emote[]>([]);
  const [searchFilter, setSearchFilter] = useState(searchString);

  useEffect(() => {
    if (slug) {
      fetchPageEmotes(slug);
    }
  }, [fetchPageEmotes, slug]);

  useEffect(() => {
    if (emotesDiv.current) {
      emotesDiv.current.scrollTop = emotesDiv.current.scrollHeight;
    }
  }, [currentEmotes]);

  const handleOnClick = (emote: Emote) => {
    if (currentEmotes.length < 5) {
      setCurrentEmotes([...currentEmotes, emote]);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchFilter(value);
  };

  if (isLoading) {
    return (
      <div className={classNames('subs__emotes subs__emotes--loading')}>
        <Skeleton height="100%" />
      </div>
    );
  }

  // it could be error. In that case it shouldn't show anything
  if (!collections) {
    return null;
  }

  return (
    <div className={classNames('subs__emotes')}>
      <div className={classNames('subs__emotes__header')}>
        <h3 className={classNames('subs__emotes__header-title')}>{t('collection:subscriptionsAlert.emotes.title')}</h3>
        <SearchInput
          className={classNames('subs__emotes__search')}
          value={searchFilter}
          onChange={handleSearchChange}
          onSearch={filterPageEmotes}
          placeholder={t('collection:subscriptionsAlert.emotes.search')}
          debounceDelay={100}
        />
      </div>
      <div className={classNames('subs__emotes__content')}>
        {collections.map(collection => (
          <SubscriptionAlertsEmotesCollection
            key={collection.locales.name.en}
            title={collection.locales.name[language]}
            emotes={collection.emotes}
            onClick={handleOnClick}
          />
        ))}
      </div>
      <div className={classNames('subs__emotes__footer')}>
        <SubscriptionAlertsEmotesFooter
          isSubscribed={isSubscribed}
          currentEmotes={currentEmotes}
          setCurrentEmotes={setCurrentEmotes}
          onSubscribe={onSubscribe}
          emotesDiv={emotesDiv}
        />
      </div>
    </div>
  );
};
