import actionCreator from 'helpers/actionCreator';
import type { CommunityGifts } from 'model/indexTS';
import { CancelToken } from 'types/indexTS';
import type {
  FetchCommunityGifts,
  CommunityGiftsActionSuccess,
  CommunityGiftsActionError,
  ClaimCommunityGifts,
  ClaimCommunityGiftsActionSuccess,
  ClaimCommunityGiftsActionError,
} from './types';
import endpoints from '../endpoints';
import { CommunityGiftsActionTypes } from './actionTypes';

const communityGiftsReceived = ({ data }: { data: CommunityGifts }): CommunityGiftsActionSuccess =>
  actionCreator.createAction(CommunityGiftsActionTypes.RECEIVED, data);

const communityGiftsNotReceived = ({ errorData }): CommunityGiftsActionError =>
  actionCreator.createErrorAction(CommunityGiftsActionTypes.ERROR, errorData);

const fetchCommunityGifts: FetchCommunityGifts = (pageId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.GET_COMMUNITY_GIFTS,
    errorBinding: communityGiftsNotReceived,
    loadingAction: CommunityGiftsActionTypes.FETCHING,
    urlParameters: { pageId },
    method: 'get',
    successBinding: communityGiftsReceived,
  });

const claimCommunityGiftsReceived = (communityGiftId: string) => (): ClaimCommunityGiftsActionSuccess =>
  actionCreator.createAction(CommunityGiftsActionTypes.CLAIMED, { communityGiftId });

const claimCommunityGiftsNotReceived = ({ errorData }): ClaimCommunityGiftsActionError =>
  actionCreator.createErrorAction(CommunityGiftsActionTypes.ERROR_CLAIMING, errorData);

const createClamingIdWaiting = (communityGiftId: string, cancelToken: CancelToken) =>
  actionCreator.createAction(CommunityGiftsActionTypes.CLAIMING, {
    communityGiftId,
    cancelToken,
  });

const claimCommunityGifts: ClaimCommunityGifts = (pageId: string, communityGiftId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.CLAIM_COMMUNITY_GIFTS,
    errorBinding: claimCommunityGiftsNotReceived,
    loadingAction: (cancelToken: CancelToken) => createClamingIdWaiting(communityGiftId, cancelToken),
    urlParameters: { pageId, communityGiftId },
    method: 'post',
    successBinding: claimCommunityGiftsReceived(communityGiftId),
  });

export const communityGiftActions = {
  fetchCommunityGifts,
  claimCommunityGifts,
};
