import { useLocalStorageBySlug } from 'hooks/useLocalStorageBySlug';
import { useEffect, useMemo } from 'react';
import { LOCAL_STORAGE_KEYS_BY_SLUG } from 'constants/local-storage-keys';
import { usePageSelectors } from 'services/page';
import { usePageFeedActions, usePageFeedSelectors } from 'services/page-feed';
import { useAuthSelectors } from 'services/auth';
import { useLocation } from 'react-router-dom';
import { urls } from 'services/routing';

export const useNewFeedIndicator = () => {
  const { pathname } = useLocation();
  const { isUserAuthenticated } = useAuthSelectors();
  const { data } = usePageFeedSelectors();
  const { fetchPageFeed } = usePageFeedActions();
  const { storedValue: lastVisit, setStoredValue } = useLocalStorageBySlug<string | undefined>(
    LOCAL_STORAGE_KEYS_BY_SLUG.LAST_FEED_VISITED,
    undefined,
  );
  const {
    page: { slug, isOwner },
  } = usePageSelectors();

  const newFeedsCount = useMemo(() => {
    if (!data?.length || !isUserAuthenticated) {
      return 0;
    }

    if (!lastVisit) {
      return data.length;
    }

    const lastVisitMs = Date.parse(lastVisit);

    if (Number.isNaN(lastVisitMs)) {
      return data.length;
    }

    return data.reduce((count, feed) => {
      if (lastVisitMs < feed.createdAt.getTime()) {
        return count + 1;
      }
      return count;
    }, 0);
  }, [data, lastVisit, isUserAuthenticated]);

  useEffect(() => {
    if (!slug || isOwner || !isUserAuthenticated || pathname === urls().USER_PAGE_FEED) {
      return;
    }

    fetchPageFeed(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname !== urls().USER_PAGE_FEED || !newFeedsCount) {
      return;
    }

    setStoredValue(new Date().toISOString());
  }, [pathname, newFeedsCount, setStoredValue]);

  return {
    newFeedsCount,
  };
};
