import cookies from 'js-cookie';
import config from 'config';
import { ReqQuery, ResType } from 'types/indexTS';
import { RESERVED_QUERY_PARAMS } from 'constants/reserved-query-params';

export const COUPON_COOKIE = 'COUPON_CODE';

const setCouponCookie = (query: ReqQuery, res: ResType, domain: string) => {
  const couponCode = query[RESERVED_QUERY_PARAMS.GIFT_PACK_COUPON_CODE];
  if (!couponCode) {
    return;
  }
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  res.cookie(COUPON_COOKIE, couponCode, {
    expires: tomorrow,
    domain,
    encode: String,
  });
};

const getCouponCode = (reqCookies?: ReqQuery): string | undefined =>
  reqCookies ? reqCookies[COUPON_COOKIE] : cookies.get(COUPON_COOKIE);

const removeCouponCode = () => {
  cookies.remove(COUPON_COOKIE, { domain: config.streamloots.domain });
};

export default {
  getCouponCode,
  removeCouponCode,
  setCouponCookie,
};
