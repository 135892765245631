import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import trackEventManager from 'utils/event-manager';
import theme from './intro-avatar-thumbnail.scss';

const classNames = ClassNames(theme);

interface ExternalCreatorLinkProps {
  icon: string;
  eventId: string;
  title: string;
  url: string;
}

export const ExternalCreatorLink = ({ url, eventId, title, icon }: ExternalCreatorLinkProps): JSX.Element => {
  const handleClick = () => {
    trackEventManager.publicPageExternalLinkClicked({ button: eventId });
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      className={classNames('creator-info__link')}
      title={title}
    >
      <Icon id={icon} />
    </a>
  );
};
