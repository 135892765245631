import { useState } from 'react';
import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { Subscription } from 'model/indexTS';
import { useSubscriptionsActions, useSubscriptionsSelectors } from 'services/subscriptions';
import { usePageSelectors } from 'services/page';
import { CleanDialogContent } from 'ui-library/dialog';
import { useReactAlert } from 'components/react-alert-provider';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import theme from './subscription-cancel.scss';

const classNames = ClassNames(theme);

interface SubscriptionCancelProps {
  onClose: () => void;
  slug?: string;
  avatarImageUrl?: string;
  expirationDate?: string | Date;
  subscriptionMinimalData?: Pick<Subscription, '_id' | 'expirationDate'>;
}

export const SubscriptionCancel = ({
  onClose,
  slug,
  avatarImageUrl,
  subscriptionMinimalData,
}: SubscriptionCancelProps): JSX.Element | null => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { showError } = useReactAlert();
  const { t } = useTranslation('pageSubscription');
  const { page } = usePageSelectors();
  const { subscription: subscriptionSelector } = useSubscriptionsSelectors();
  const subscription = subscriptionMinimalData || subscriptionSelector;
  const { cancelSubscription } = useSubscriptionsActions();
  const slugOfStreamer = slug || page.slug;

  const handleCancelSubscription = async () => {
    if (!subscription) {
      return;
    }
    setLoading(true);
    const response = await cancelSubscription(subscription._id);
    if (response.error) {
      showError(t('pageSubscription:cancelSubscription.error'));
    } else {
      setStep(2);
    }
    setLoading(false);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  if (!slugOfStreamer || !page) {
    return null;
  }

  return (
    <CleanDialogContent className={classNames('subscription-cancel')}>
      {step <= 1 ? (
        <FirstStep
          slug={slugOfStreamer}
          avatarImageUrl={avatarImageUrl || page.owner?.profile.avatarImageUrl || ''}
          subscription={subscription as Subscription}
          onStaySubscribed={handleClose}
          onCancelSubscription={handleCancelSubscription}
          t={t}
          loading={loading}
        />
      ) : (
        <SecondStep slug={slugOfStreamer} t={t} onClose={handleClose} />
      )}
    </CleanDialogContent>
  );
};
