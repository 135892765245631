import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import { isActionError } from 'types/indexTS';
import { CommunityGifts } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import {
  CommunityGiftsSelectorOutput,
  FetchCommunityGifts,
  communityGiftActions,
  communityGiftsSelectors,
  ClaimCommunityGifts,
} from 'services/community-gift';
import { authSelectors } from 'services/auth';
import type { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { overloadedContentActions } from 'services/overloaded-content';
import ScrollBarPanel from 'components/scrollbar-panel';
import { useReactAlert } from 'components/react-alert-provider';
import { AuthDialog } from 'scenes/auth';
import trackEventManager from 'utils/event-manager';
import { UserGifter } from './UserGifter';
import { gitfCommunityCookieManager, TIME_TO_CLAIM_GIFT_AGAIN } from './claimCommunityGiftCookieManager';
import theme from './claim-community-gift.scss';

const classNames = ClassNames(theme);

interface ExternalProps extends TranslateInterface {
  pageId: string;
  slug: string;
}

interface MapStateToProps {
  activeCommunityGifts?: CommunityGiftsSelectorOutput;
  isLoading: boolean;
  claimingId?: string;
  reconcile: boolean;
  isUserAuthenticated: boolean;
}

interface Actions {
  fetchCommunityGifts: FetchCommunityGifts;
  claimGift: ClaimCommunityGifts;
  openOverloadedContent: OpenOverloadedContent;
}

interface ClaimCommunityGiftProps extends MapStateToProps, ExternalProps, Actions {}

const generateAmplitudeEvent = (slug: string, activeCommunityGifts: CommunityGifts) => {
  return {
    page: slug,
    available_packs: activeCommunityGifts.reduce((acc, gift) => {
      return acc + gift.remainingLootsQuantity;
    }, 0),
    total_packs: activeCommunityGifts.reduce((acc, gift) => {
      return acc + gift.purchasedLootsQuantity;
    }, 0),
  };
};

enum EventStates {
  NO_OP = 'NO_OP',
  VIEWED = 'VIEWED',
  CLAIMED = 'CLAIMED',
}

const ConnectedClaimCommunityGift = ({
  activeCommunityGifts,
  claimGift,
  pageId,
  t,
  fetchCommunityGifts,
  isLoading,
  claimingId,
  slug,
  reconcile,
  isUserAuthenticated,
  openOverloadedContent,
}: ClaimCommunityGiftProps) => {
  const [nextClaimGiftTime, setNextClaimGiftTime] = React.useState({
    claiming: gitfCommunityCookieManager.getNextClaimGiftAtTime(slug),
    sendAmplitudeEventState: EventStates.NO_OP,
  });
  const { showError, showWarning } = useReactAlert();

  React.useEffect(() => {
    if ((!isLoading && activeCommunityGifts === null) || reconcile) {
      fetchCommunityGifts(pageId).then(response => {
        if (!isActionError(response)) {
          setNextClaimGiftTime({ ...nextClaimGiftTime, sendAmplitudeEventState: EventStates.VIEWED });
        }
      });
    }
  }, [activeCommunityGifts, fetchCommunityGifts, isLoading, nextClaimGiftTime, pageId, reconcile]);

  React.useEffect(() => {
    if (
      activeCommunityGifts &&
      activeCommunityGifts.length > 0 &&
      nextClaimGiftTime.sendAmplitudeEventState === EventStates.VIEWED
    ) {
      trackEventManager.trackEvent('community_gift_claim_viewed', {
        ...generateAmplitudeEvent(slug, activeCommunityGifts),
        total_gifters: activeCommunityGifts.length,
      });
      setNextClaimGiftTime({ ...nextClaimGiftTime, sendAmplitudeEventState: EventStates.NO_OP });
    }

    if (activeCommunityGifts && nextClaimGiftTime.sendAmplitudeEventState === EventStates.CLAIMED) {
      trackEventManager.trackEvent('community_gift_claimed', generateAmplitudeEvent(slug, activeCommunityGifts));
      setNextClaimGiftTime({ ...nextClaimGiftTime, sendAmplitudeEventState: EventStates.NO_OP });
    }
  }, [activeCommunityGifts, nextClaimGiftTime, slug]);

  if (!activeCommunityGifts || activeCommunityGifts === []) {
    return <div className={classNames('claim-community-gift--wrapper')} />;
  }

  const handleClaim = (pageIdHandle: string, giftCommunityIdHandle: string) => {
    if (!isUserAuthenticated) {
      const componentKey = 'communityGiftClaim';
      trackEventManager.trackEvent('loginBtnClick', {
        componentKey,
      });
      openOverloadedContent({
        component: AuthDialog,
        componentProps: {
          componentKey,
          location: 'community gift pack claim',
        },
      });
      return;
    }

    trackEventManager.trackEvent('community_gift_claim_clicked', generateAmplitudeEvent(slug, activeCommunityGifts));

    claimGift(pageIdHandle, giftCommunityIdHandle).then(response => {
      if (isActionError(response)) {
        const { metadata } = response;
        if (metadata.businessCode === 'COMMUNITY_GIFT_TOO_SOON') {
          showError(t('collection:claimGift.errorClaim'));
        }
        if (metadata.businessCode === 'COMMUNITY_GIFT_DEPLETED') {
          fetchCommunityGifts(pageId);
          showWarning(t('collection:claimGift.notMoreGift'));
        }
      } else {
        const actualTime = nextClaimGiftTime.claiming || new Date().getTime();
        setNextClaimGiftTime({
          claiming: actualTime + TIME_TO_CLAIM_GIFT_AGAIN,
          sendAmplitudeEventState: EventStates.CLAIMED,
        });
        gitfCommunityCookieManager.gitfClaimed(slug, actualTime + TIME_TO_CLAIM_GIFT_AGAIN);
      }
    });
  };

  const onCompleteCountdown = () => {
    setNextClaimGiftTime({ ...nextClaimGiftTime, claiming: null });
  };

  const showCountdown = nextClaimGiftTime.claiming !== null && new Date().getTime() < nextClaimGiftTime.claiming;

  return (
    <div className={classNames('claim-community-gift--wrapper')}>
      {activeCommunityGifts && activeCommunityGifts.length > 0 && (
        <ScrollBarPanel
          contentClassName={classNames('claim-community-gift')}
          autoHeight
          autoHeightMin={38}
          autoHeightMax={150}
        >
          {activeCommunityGifts.map(giftCommunity => (
            <UserGifter
              key={giftCommunity._id}
              pageId={pageId}
              giftCommunity={giftCommunity}
              claimGift={handleClaim}
              t={t}
              isDisabled={claimingId !== undefined}
              showCountdown={showCountdown}
              isClaiming={claimingId === giftCommunity._id}
              nextTimeAvailable={nextClaimGiftTime.claiming}
              onCompleteCountdown={onCompleteCountdown}
            />
          ))}
        </ScrollBarPanel>
      )}
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    activeCommunityGifts: communityGiftsSelectors.getCommunityGifts(state),
    isLoading: communityGiftsSelectors.isLoadingData(state),
    claimingId: communityGiftsSelectors.getClamingId(state),
    reconcile: communityGiftsSelectors.getReconcile(state),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  };
};

export const ClaimCommunityGift = connect(mapStateToProps, {
  claimGift: communityGiftActions.claimCommunityGifts,
  fetchCommunityGifts: communityGiftActions.fetchCommunityGifts,
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
})(ConnectedClaimCommunityGift) as React.FunctionComponent<ExternalProps>;
