import ClassNames from '@streamloots/classnames';
import AvatarImage, { ROUNDED_PLACEHOLDER_IMAGE } from 'components/avatar-image';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { Trans } from 'react-i18next';
import theme from './subscription-claim.scss';

const classNames = ClassNames(theme);

export interface SubscriptionGifterProps extends TranslateInterface {
  subscriptionGiftId: string;
  avatarUrl: string;
  username: string;
  onClaimGift: (subscriptionGiftId: string) => void;
  remainingSubscriptionsQuantity: number;
  purchasedSubscriptionsQuantity: number;
  totalPrepaidMonths: number;
  isSubscribed: boolean;
  isClaiming: boolean;
}

export const SubscriptionGifter = ({
  subscriptionGiftId,
  avatarUrl,
  username,
  onClaimGift,
  t,
  remainingSubscriptionsQuantity,
  purchasedSubscriptionsQuantity,
  totalPrepaidMonths,
  isSubscribed,
  isClaiming,
}: SubscriptionGifterProps): JSX.Element => (
  <div className={classNames('subscription-gifter')}>
    <AvatarImage
      className={classNames('subscription-gifter__avatar')}
      src={avatarUrl}
      alt={username}
      fallbackSrc={ROUNDED_PLACEHOLDER_IMAGE}
    />
    <div className={classNames('subscription-gifter__text')}>
      <div className={classNames('subscription-gifter__text--name')}>{username}</div>
      <p className={classNames('subscription-gifter__text--description')}>
        <Trans i18nKey="pageSubscription:gifts.numberOfGifts.message">
          is gifting {t('pageSubscription:gifts.numberOfGifts.subsAmount', { count: purchasedSubscriptionsQuantity })}{' '}
          for{' '}
          <span
            className={classNames({
              'subscription-gifter__text--description__months': true,
              'subscription-gifter__text--description__months--multiple': totalPrepaidMonths > 1,
            })}
          >
            {t('pageSubscription:gifts.numberOfGifts.monthsAmount', { count: totalPrepaidMonths })}
          </span>
        </Trans>
      </p>
      <div>
        <Button
          primary
          onClick={() => onClaimGift(subscriptionGiftId)}
          disabled={isSubscribed}
          loading={isClaiming}
          size="sm"
        >
          {isSubscribed ? t('pageSubscription:gifts.alreadySubscribed') : t('pageSubscription:gifts.cta')}
        </Button>
      </div>
    </div>
    <div className={classNames('subscription-gifter__counter')}>
      <div className={classNames('subscription-gifter__counter--number')}>{remainingSubscriptionsQuantity}</div>
      <div className={classNames('subscription-gifter__counter--label')}>{t('pageSubscription:gifts.boxLeft')}</div>
    </div>
  </div>
);
