import { useEffect, useMemo } from 'react';
import { History } from 'history';
import { RouteConfig } from 'react-router-config';
import { PagesModules, PageType } from '@streamloots/streamloots-types';
import { PageModules, PageModulesValues, PublicPageOwnerInfo, UserSet } from 'model/indexTS';
import { userSetsToView } from 'domain/public-page';
import trackEventManager from 'utils/event-manager';
import { useTranslation } from 'utils/translation';
import { CraftingModeProvider } from 'contexts/crafting-mode-provider';
import { useOverloadedContentActions } from 'services/overloaded-content';
import { PageState } from 'services/page';
import { usePageSubscriptionPlansActions } from 'services/page-subscription-plans';
import { useSubscriptionsActions, useSubscriptionsSelectors } from 'services/subscriptions';
import { getUrlWithParams, pages } from 'services/routing';
import { useOpenLoots } from 'scenes/open-collection-loot';
import { SubscriptionCheckoutDialog } from 'scenes/subscription-checkout';
import { useAuthTrackingEvents } from 'scenes/auth/useAuthTrackingEvents';
import { AuthDialog } from 'scenes/auth';
import { SubscriptionCancel } from 'scenes/subscription-cancel';
import { DialogBuyLootPacksPicker } from 'components/buy-loot-packs-picker';
import { PublicAuctionListPage } from 'scenes/public-auction-list';
import { PageModulesChecker } from 'components/page-modules-checker';
import { NoModulesMessage } from 'scenes/public-user-page/NoModulesMessage';
import { NoModulesEnabledMessage } from 'scenes/public-user-page/NoModuleEnabledMessage';
import { FullLayout } from 'scenes/layout';
import { useUserSetsSelectors } from 'services/user-sets';
import { PublicFeedPage } from 'scenes/public-page-feed';
import { useNewFeedIndicator } from 'components/feed-indicators';
import { FloatingPublicHeader } from './components/floating-header';
import { CreatorHeader } from './components/creator-header';
import { NavTabs } from './components/nav-tabs';
import { PublicUserPageCollections } from './PublicUserPageCollections';
import { PublicUserPageSubscription } from './PublicUserPageSubscription';
import { getModulesRequired, getModuleToRender } from './page-module-checker';
import { SubscriptionPacksAnnouncement } from './components/subscription-packs';
import { useActiveAuctionsCount } from './useActiveAuctionsCount';
import { OpenShareRecapFromUrl } from './components/open-share-recap-from-url';

interface PublicUserPageProps {
  currentPathname: string;
  route: RouteConfig;
  page: PageState;
  history: History;
  isUserAuthenticated: boolean;
  userSets?: UserSet[];
}

const shouldDisplayModule = (
  modules: PageModules | undefined,
  marathonSet: UserSet | null,
  moduleToRender: PageModulesValues | null,
): boolean | null => {
  if (!modules) {
    return false;
  }

  if (marathonSet && modules.marathons.enabled) {
    return true;
  }

  return moduleToRender && modules[moduleToRender]?.enabled;
};

const PublicUserPageBase = ({
  currentPathname,
  isUserAuthenticated,
  page,
  route,
  userSets,
  history,
}: PublicUserPageProps): JSX.Element => {
  const activeAuctionsCount = useActiveAuctionsCount();
  const { newFeedsCount } = useNewFeedIndicator();
  const { t } = useTranslation(['common', 'collection']);
  const { isSubscribed, subscription } = useSubscriptionsSelectors();
  const { openOverloadedContent, closeOverloadedContent } = useOverloadedContentActions();
  const { fetchSubscriptions } = useSubscriptionsActions();
  const { fetchPageSubscriptionPlans } = usePageSubscriptionPlansActions();
  const { openLoots } = useOpenLoots();
  const { openModalTrackingEvent, closeModalTrackingEvent } = useAuthTrackingEvents();
  const { marathonSpecialSet } = useUserSetsSelectors();
  const { _id = '', slug, modules, isOwner, ambassador = false, type = PageType.REGULAR } = page;
  const {
    profile: { avatarImageUrl },
  } = page.owner as PublicPageOwnerInfo;
  const hasSubscriptionPlan = page?.modules?.subscriptions.enabled ?? false;
  const userSetsToDisplay = userSetsToView(userSets, modules, marathonSpecialSet);
  const hasUserSets = userSetsToDisplay.length > 0;

  const moduleToRender = useMemo(() => {
    return getModuleToRender(currentPathname, modules, {
      hasMarathonSpecialSet: !!marathonSpecialSet,
    });
  }, [currentPathname, marathonSpecialSet, modules]);

  const isModuleToRenderEnabled = shouldDisplayModule(modules, marathonSpecialSet, moduleToRender);

  useEffect(() => {
    if (!modules?.subscriptions.enabled) {
      return;
    }

    fetchPageSubscriptionPlans(slug);

    if (isUserAuthenticated) {
      fetchSubscriptions(slug);
    }
  }, [slug, fetchPageSubscriptionPlans, fetchSubscriptions, isUserAuthenticated, modules]);

  const handleOpenLoots = () => {
    if (!isUserAuthenticated) {
      const componentKey = 'openLoots';
      openModalTrackingEvent(componentKey);
      openOverloadedContent({
        component: AuthDialog,
        componentProps: {
          componentKey,
          location: 'open loots',
        },
        wrapperProps: {
          type: 'auth',
          onClose: () => {
            closeOverloadedContent();
            closeModalTrackingEvent(componentKey);
          },
        },
      });
      return;
    }

    openLoots();
  };

  const handlePurchaseOpen = (location: string) => {
    if (hasSubscriptionPlan) {
      history.replace({
        pathname: getUrlWithParams(pages.USER_PAGE_COLLECTIONS, { slug }),
      });
    }

    openOverloadedContent({
      component: DialogBuyLootPacksPicker,
      wrapperProps: {
        type: 'large',
        opacity: true,
        noCentered: true,
      },
      componentProps: {
        startLocation: location,
      },
    });
  };

  const handleSubscribe = (buttonId: string, alertName?: string) => {
    trackEventManager.subscriptionPurchasedClick({
      location: `${buttonId}${!alertName ? '' : `_${alertName}`}`,
    });

    history.replace({
      pathname: getUrlWithParams(pages.USER_PAGE_SUBSCRIPTION, { slug }),
    });

    openOverloadedContent({
      component: SubscriptionCheckoutDialog,
      wrapperProps: {
        type: 'large',
        opacity: true,
        preventCloseOnOutsideClick: true,
      },
    });
  };

  const handleCancelSubscription = () => {
    trackEventManager.cancelSubscription();

    openOverloadedContent({
      component: SubscriptionCancel,
      wrapperProps: {
        type: 'large',
        opacity: true,
        preventCloseOnOutsideClick: true,
      },
    });
  };

  return (
    <>
      <FullLayout>
        <PageModulesChecker modulesRequired={getModulesRequired()}>
          <FloatingPublicHeader
            avatarImageUrl={avatarImageUrl}
            slug={slug}
            onSubscribe={handleSubscribe}
            t={t}
            showSubscribeButton={hasSubscriptionPlan && !isSubscribed}
            showBuyPacks={modules?.cards.enabled && hasUserSets}
            pageType={type}
            ambassador={ambassador}
          />
        </PageModulesChecker>
        <CreatorHeader
          onCollectionPurchase={handlePurchaseOpen}
          onSubscribe={handleSubscribe}
          onCancelSubscription={handleCancelSubscription}
          hasUserSets={hasUserSets}
          subscription={subscription}
          modules={modules}
        />

        <PageModulesChecker modulesRequired={[PagesModules.Cards]}>
          <SubscriptionPacksAnnouncement onClick={handleSubscribe} />

          {/* {isSeasonTime() && <PublicPageRankingBanner />} */}
        </PageModulesChecker>
        <NavTabs
          hasSpecialMarathonCollection={!!marathonSpecialSet}
          slug={slug}
          activeAuctionsCount={activeAuctionsCount}
          newFeedsCount={newFeedsCount}
          moduleRendered={moduleToRender}
          modules={modules}
        />
        {!moduleToRender && <NoModulesMessage isOwner={isOwner} slug={slug} t={t} />}
        {moduleToRender && !isModuleToRenderEnabled && (
          <NoModulesEnabledMessage t={t} moduleKey={moduleToRender} slug={slug} />
        )}
        {isModuleToRenderEnabled && (
          <>
            {moduleToRender === PagesModules.Subscriptions && (
              <PublicUserPageSubscription slug={slug} onSubscribe={handleSubscribe} isSubscribed={isSubscribed} />
            )}
            {moduleToRender === PagesModules.Auctions && <PublicAuctionListPage />}
            {moduleToRender === PagesModules.Cards && (
              <CraftingModeProvider>
                <PublicUserPageCollections
                  _id={_id}
                  isUserAuthenticated={isUserAuthenticated}
                  handleOpenLoots={handleOpenLoots}
                  openOverloadedContent={openOverloadedContent}
                  route={route}
                  slug={slug}
                  t={t}
                  userSets={userSetsToDisplay}
                />
              </CraftingModeProvider>
            )}
            {moduleToRender === PagesModules.Feeds && <PublicFeedPage />}
          </>
        )}
        <OpenShareRecapFromUrl />
      </FullLayout>
    </>
  );
};

export default PublicUserPageBase;
