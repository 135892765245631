import { Link } from 'react-router-dom';
import ClassNames from '@streamloots/classnames';
import type { TranslateInterface } from 'utils/translation';
import ILLUSTRATIONS from 'constants/illustrations';
import { getUrlWithParams, pages } from 'services/routing';
import Button from 'ui-library/button';
import { DialogContentImageColumn } from 'ui-library/dialog';
import { useOpenLoots } from 'scenes/open-collection-loot';
import theme from './coupons-redemption.scss';

const classNames = ClassNames(theme);

interface RedeemCloseButtonProps extends TranslateInterface {
  onClose: () => void;
  chestsReceived?: number;
  selectedSetId: string;
  slug: string;
  succeeded: boolean;
}

const getResponseProps = ({ succeeded, t, chestsReceived, slug }) => {
  if (succeeded) {
    return {
      imageUrl: ILLUSTRATIONS.VIEWER_POSITIVE_FLAG,
      title: t('coupons:redeemed'),
      message: t('coupons:lootsReceived', { count: chestsReceived, slug }),
      buttonLabel: t('publicUserPage:packs.openPacks', { count: 1 }),
    };
  }

  return {
    imageUrl: ILLUSTRATIONS.VIEWER_NEGATIVE_FLAG,
    title: t('coupons:invalidCouponTitle'),
    message: t('coupons:invalidCoupon'),
    buttonLabel: t('common:close'),
  };
};

export const RedeemCouponResponseDialog = ({
  onClose,
  chestsReceived,
  selectedSetId,
  slug,
  succeeded,
  t,
}: RedeemCloseButtonProps): JSX.Element => {
  const { openLoots } = useOpenLoots();
  const { buttonLabel, title, imageUrl, message } = getResponseProps({
    chestsReceived,
    succeeded,
    slug,
    t,
  });

  const handleClick = () => {
    if (succeeded) {
      openLoots();
    }
    onClose();
  };

  return (
    <DialogContentImageColumn
      imageUrl={imageUrl}
      imageClassName={classNames('coupon-redemption__response-img')}
      title={title}
      renderFooter={() => (
        <Button
          component={Link}
          onClick={handleClick}
          primary
          label={buttonLabel}
          to={getUrlWithParams(pages.USER_PAGE_COLLECTION, {
            setId: selectedSetId,
            slug,
          })}
        />
      )}
    >
      <p className="margin-bottom-x2">{message}</p>
    </DialogContentImageColumn>
  );
};
