import { useEffect, useMemo } from 'react';
import { usePageSelectors } from 'services/page';
import { useAuctionsActions, useAuctionsSelectors } from 'services/auction';
import { isAfter } from 'date-fns';

export const useActiveAuctionsCount = () => {
  const { fetchAuctions } = useAuctionsActions();
  const { data: auctions, isLoading } = useAuctionsSelectors();
  const { page } = usePageSelectors();
  const canInitialFetch = !auctions && !isLoading;

  useEffect(() => {
    const { modules, slug } = page;
    if (!canInitialFetch || !modules?.auctions.enabled || !slug) {
      return;
    }

    fetchAuctions(slug);
  }, [fetchAuctions, page, canInitialFetch]);

  const activeAuctionsCount = useMemo(() => {
    if (!auctions) {
      return 0;
    }

    const activeAuctions = auctions.filter(streamerAuction => {
      const { startDate, active, isEnded } = streamerAuction;

      const hasStarted = startDate ? isAfter(Date.now(), startDate) : true;
      return active && !isEnded && hasStarted;
    });
    return activeAuctions.length;
  }, [auctions]);

  return activeAuctionsCount;
};
