import { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import type { UserSet } from 'model/indexTS';
import ClassNames from '@streamloots/classnames';
import type { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import trackEventManager from 'utils/event-manager';
import { Powers } from 'components/powers';
import { AuthDialog } from 'scenes/auth';
import { SetList } from 'components/set-list';
import { TranslateInterface } from 'utils/translation';
import { usePowerActions, usePowerSelectors } from 'services/alert';
import MobileNavItem from './MobileNavItem';
import { ConnectedPublicRanking } from '../public-ranking';
import BuyPacksButton from './components/buy-packs-button';
import theme from './mobile-nav.scss';

const classNames = ClassNames(theme);

interface MobileNavProps extends TranslateInterface {
  onOpenLoots: () => void;
  openOverloadedContent: OpenOverloadedContent;
  userSets: UserSet[];
  unopenedLootsCount: number;
  isUserAuthenticated: boolean;
}

export const MobileNav = (props: MobileNavProps): JSX.Element => {
  const { onOpenLoots, openOverloadedContent, userSets, unopenedLootsCount, isUserAuthenticated, t } = props;
  const { fetchPowers } = usePowerActions();
  const { powers, activePowers } = usePowerSelectors();
  const [delay, setDelay] = useState(0.5);
  const { open } = useContext(SidebarOpenerContext);

  // Idea is delay only applies on first render and not then when the menu is toggled.
  useEffect(() => setDelay(0), []);

  useEffect(() => {
    if (powers !== undefined || !isUserAuthenticated) {
      return;
    }
    fetchPowers();
  }, [fetchPowers, powers, isUserAuthenticated]);

  function handleOpenRanking() {
    open({
      component: <ConnectedPublicRanking />,
      attributes: {
        sidebarContentClassName: classNames('tabs__padding'),
      },
    });

    trackEventManager.trackEvent('publicRankingButtonClicked', {
      source: 'mobilePageMenu',
    });
  }

  function handlePowersClick() {
    if (!isUserAuthenticated) {
      openOverloadedContent({
        component: AuthDialog,
        componentProps: {
          componentKey: 'Mobile Menu Powers',
          location: 'mobile menu powers button',
        },
      });
      return;
    }

    openOverloadedContent({
      component: Powers,
      componentProps: {
        isPowersPage: false,
      },
      wrapperProps: {
        opacity: true,
        type: 'fullscreen',
      },
    });
  }

  function handleOpenCollectionSwitcher() {
    open({
      component: <SetList sets={userSets} hidePublishBug />,
      attributes: {
        title: t('adminSets:selectCollection'),
      },
    });
  }

  return (
    <motion.nav
      className={classNames('m-nav')}
      initial={{ y: '100%' }}
      animate={{ y: 0 }}
      transition={{
        delay,
        x: { type: 'spring', stiffness: 100 },
        default: { duration: 0.2 },
      }}
      data-testid="mobile-streamer-menu"
    >
      <MobileNavItem
        iconId="cards"
        label={t('collection:userPageMobileNav.collections')}
        onClick={handleOpenCollectionSwitcher}
      />
      <MobileNavItem iconId="treasure" label={t('collection:userPageMobileNav.myPacks')} onClick={onOpenLoots}>
        {unopenedLootsCount > 0 && (
          <div className={classNames('m-nav__total-packs-number')}>
            {unopenedLootsCount > 9 ? '9+' : unopenedLootsCount}
          </div>
        )}
      </MobileNavItem>
      <BuyPacksButton />
      <MobileNavItem iconId="cup" label="Ranking" onClick={handleOpenRanking} />
      {activePowers.length > 0 && (
        <MobileNavItem iconId="flash" label={t('collection:userPageMobileNav.powers')} onClick={handlePowersClick} />
      )}
    </motion.nav>
  );
};
