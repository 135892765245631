import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import type { UserCard } from 'model/indexTS';
import type { TranslateInterface } from 'utils/translation';
import { DisenchantResourceSymbol } from 'components/disenchant-resources';
import { CardSymbols, cardImageUrlGetter } from 'components/card';
import { CraftingViewModes } from './constants';
import { ConnectedCraftingViewOptionPanel } from './ConnectedCraftingViewOptionPanel';
import theme from './card-crafting-view.scss';

const classNames = ClassNames(theme);

interface CardPreviewProps extends TranslateInterface {
  card: UserCard;
  dustBalance: number;
}

export const CardCrafting = ({ card, dustBalance, t }: CardPreviewProps): JSX.Element => {
  const { fragmented, fragments, imageUrl, count, craftingCost, disenchantingReward } = card;

  const { availableFragments, redeemable } = count;
  const availableValues = fragmented ? `${availableFragments}/${fragments}` : redeemable;

  return (
    <div className={classNames('crafting-view')}>
      <ConnectedCraftingViewOptionPanel
        dustBalance={dustBalance}
        card={card}
        mode={CraftingViewModes.DISENCHANTING}
        cost={disenchantingReward}
      />
      <div className={classNames('crafting-view__card-content')}>
        <div
          className={classNames({
            'crafting-view__addition': true,
            'crafting-view__addition--top': true,
          })}
        >
          <span className={classNames('crafting-view__addition__label')}>
            {t(fragmented ? 'disenchant:availableFragments' : 'disenchant:availableCards')}
          </span>
          <strong className={classNames('crafting-view__addition__number')}>{availableValues}</strong>
        </div>
        <div className={classNames('crafting-view__card')}>
          <img src={cardImageUrlGetter.getCardImageUrl(imageUrl)} alt="name" />
          <CardSymbols className="no-margin-imp" card={card} />
        </div>
        <div
          className={classNames({
            'crafting-view__addition': true,
            'crafting-view__addition--bottom': true,
          })}
        >
          <DisenchantResourceSymbol className={classNames('crafting-view__addition__number')} includeBalance />
        </div>
      </div>
      <ConnectedCraftingViewOptionPanel
        dustBalance={dustBalance}
        card={card}
        mode={CraftingViewModes.CRAFTING}
        cost={craftingCost}
      />
    </div>
  );
};
