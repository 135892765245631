import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { SubscriptionStatus as SubscriptionStatusEnum } from '@streamloots/streamloots-types';
import { TranslateInterface } from 'utils/translation';
import { GetSubscriptionOutput } from 'services/subscriptions';
import Button from 'ui-library/button';
import { normalizeDate } from 'domain/normalize-date';
import { SubscriptionStatus } from 'domain/subscription-status';
import { CancelSubscriptionOptions } from './CancelSubscriptionOptions';
import theme from './intro-banner.scss';

const classNames = ClassNames(theme);

export interface SubscriptionButtonProps extends TranslateInterface {
  buttonId: string;
  subscription: GetSubscriptionOutput;
  onSubscriptionClick: (cta: string) => void;
  onCancelSubscription: () => void;
}

export const SubscriptionButton = ({
  buttonId,
  subscription,
  onSubscriptionClick,
  onCancelSubscription,
  t,
}: SubscriptionButtonProps): JSX.Element | null => {
  const handleClick = () => {
    onSubscriptionClick(buttonId);
  };

  if (subscription?.status === SubscriptionStatusEnum.Active) {
    return <CancelSubscriptionOptions onClick={onCancelSubscription} t={t} />;
  }

  if (
    subscription &&
    subscription.status === SubscriptionStatusEnum.Cancelled &&
    SubscriptionStatus.isActive(subscription)
  ) {
    const normalizedDate = normalizeDate(new Date(subscription.expirationDate || subscription.renovationDate));

    return (
      <div data-testid="subscribed-until" className={classNames('intro-banner__container__ctas__subscribed-until')}>
        <div className={classNames('align-center intro-banner__container__ctas__subscribed-until--first-child')}>
          {t('pageSubscription:checkout.button.subcribedUntil')}
        </div>
        <div className="align-center">{normalizedDate.date}</div>
      </div>
    );
  }

  return (
    <Button
      color="info"
      variant="filled"
      onClick={handleClick}
      className={classNames('intro-banner__container__ctas__subscribe')}
    >
      {t('pageSubscription:checkout.button.subscribe')}
    </Button>
  );
};
