import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import { SubscriptionGiftCommunityOrderFormValues } from 'services/page-subscription-gifts';
import { InputNumber } from 'ui-library/form-elements';
import theme from './gift-options.scss';

const classNames = ClassNames(theme);

export interface CommunityGiftFormProps extends TranslateInterface {
  formValues: SubscriptionGiftCommunityOrderFormValues;
  onQuantityChange: (quantity: number) => void;
}

export const CommunityGiftForm = ({ formValues, onQuantityChange, t }: CommunityGiftFormProps): JSX.Element => {
  return (
    <div className={classNames('gift-options__quantity')}>
      <InputNumber
        id="quantity"
        name="quantity"
        min={1}
        label={t('pageSubscription:checkout.giftStep.quantity')}
        labelClassName={classNames('gift-options__quantity__label')}
        onChange={value => onQuantityChange(value || 1)}
        value={formValues.quantity}
      />
    </div>
  );
};
