import { createSelector } from 'reselect';
import { SubscriptionGiftsState, SubscriptionGiftsStoreState, SubscriptionGiftsSelectorOutput } from './types';

// Selectors.
const subscriptionState = (state: SubscriptionGiftsStoreState): SubscriptionGiftsState => state.subscriptionGifts || {};

const getSubscriptionGifts = createSelector<
  SubscriptionGiftsStoreState,
  SubscriptionGiftsState,
  SubscriptionGiftsSelectorOutput
>(
  [subscriptionState],
  (state: SubscriptionGiftsState): SubscriptionGiftsSelectorOutput => {
    if (state.isLoading || !state.subscriptionGifts) {
      return null;
    }
    return state.subscriptionGifts;
  },
);

const isLoadingData = createSelector<SubscriptionGiftsStoreState, SubscriptionGiftsState, boolean>(
  [subscriptionState],
  // (state: SubscriptionGiftsState): boolean => (state.isLoading ? state.isLoading : false),
  (state: SubscriptionGiftsState): boolean => state.isLoading ?? false,
);

export const subscriptionGiftsSelectors = {
  getSubscriptionGifts,
  isLoadingData,
};
