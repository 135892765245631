import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { Subscription } from 'model/subscription';
import { normalizeDate } from 'domain/normalize-date';
import { Trans, TranslateInterface } from 'utils/translation';
import Panel, { PanelWithoutHeader } from 'ui-library/panel';
import Button from 'ui-library/button';
import { FixedCloseButton } from 'ui-library/fixed-close-button';
import AvatarImage from 'components/avatar-image';
import trackEventManager from 'utils/event-manager';
import theme from './subscription-cancel.scss';

const classNames = ClassNames(theme);

interface FirstStepProps extends TranslateInterface {
  loading: boolean;
  avatarImageUrl: string;
  slug: string;
  subscription: Subscription;
  onStaySubscribed: () => void;
  onCancelSubscription: () => void;
}

export const FirstStep = ({
  loading,
  avatarImageUrl,
  slug,
  subscription,
  t,
  onCancelSubscription,
  onStaySubscribed,
}: FirstStepProps): JSX.Element => {
  const { date } = normalizeDate(new Date(subscription.expirationDate || subscription.renovationDate));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) {
      return;
    }

    const buttonId = (event.target as HTMLButtonElement).id;
    trackEventManager.cancelSubscriptionStep({
      button: buttonId,
      step: 'confirmation',
    });

    if (buttonId === 'Cancel') {
      onCancelSubscription();
    } else {
      onStaySubscribed();
    }
  };

  return (
    <React.Fragment>
      <FixedCloseButton onClose={onStaySubscribed} />
      <PanelWithoutHeader
        className={classNames('subscription-cancel__first-step')}
        contentClassName={classNames('subscription-cancel__first-step__content')}
      >
        <AvatarImage
          className={classNames('subscription-cancel__first-step__avatar')}
          src={avatarImageUrl}
          alt={slug}
        />
        <span className={classNames('subscription-cancel__first-step__title')}>
          <Trans i18nKey="pageSubscription:cancelSubscription.firstStep.stopSupporting">
            {'You are going to stop supporting '}
            <strong>{slug}</strong>
          </Trans>
        </span>
      </PanelWithoutHeader>
      <Panel
        className={classNames('subscription-cancel__first-step')}
        contentClassName={classNames(
          'subscription-cancel__first-step__content subscription-cancel__first-step__content__detail',
        )}
        title={t('pageSubscription:cancelSubscription.firstStep.cancel')}
      >
        <div>
          <strong>{t('pageSubscription:cancelSubscription.firstStep.ifYouCancel')}</strong>
        </div>
        <div>{t('pageSubscription:cancelSubscription.firstStep.noSupport', { slug })}</div>
        <div>{t('pageSubscription:cancelSubscription.firstStep.subscriptionsEnds', { date })}</div>
        <div>{t('pageSubscription:cancelSubscription.firstStep.sad')}</div>
      </Panel>
      <div className={classNames('subscription-cancel__first-step__buttons')}>
        <Button id="Cancel" onClick={handleClick} loading={loading} dangerous>
          {t('pageSubscription:cancelSubscription.firstStep.cancel')}
        </Button>
        <Button id="Continue" onClick={handleClick} loading={loading} color="checkout" variant="filled">
          {t('pageSubscription:cancelSubscription.firstStep.staySubscribed')}
        </Button>
      </div>
    </React.Fragment>
  );
};
