import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import theme from './dialog.scss';

const classNames = ClassNames(theme);

interface DialogContentImageColumnProps {
  className?: string;
  children: ReactNode;
  imageClassName?: string;
  bodyClassName?: string;
  closeButtonClassName?: string;
  imageUrl: string;
  title?: string;
  onClose?: () => void;
  preventClose?: boolean;
  renderFooter?: () => ReactNode;
}

export const DialogContentImageColumn = ({
  className = '',
  children,
  imageUrl,
  imageClassName = '',
  bodyClassName = '',
  closeButtonClassName = '',
  onClose,
  preventClose,
  title,
  renderFooter,
}: DialogContentImageColumnProps): JSX.Element => {
  const dialogPanelClassNames = classNames({
    'dialog-image-column': true,
    [className]: Boolean(className),
  });

  return (
    <div className={dialogPanelClassNames}>
      <div
        className={classNames({
          'dialog-image-column__image': true,
          [imageClassName]: Boolean(imageClassName),
        })}
      >
        <img src={imageUrl} alt="" />
      </div>
      <div className={classNames('dialog-image-column__content')}>
        {onClose && !preventClose && (
          <ButtonIcon
            className={classNames({
              dialog__close: true,
              [closeButtonClassName]: Boolean(closeButtonClassName),
            })}
            onClick={onClose}
            icon="close"
            ariaLabel="close"
            simple
          />
        )}
        <div
          className={classNames({
            'dialog-image-column__body': true,
            [bodyClassName]: Boolean(bodyClassName),
          })}
        >
          {title && <h2 className={classNames('dialog-image-column__title')}>{title}</h2>}
          {children}
        </div>
        {renderFooter && <div className="align-right padding-top">{renderFooter()}</div>}
      </div>
    </div>
  );
};
