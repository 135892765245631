import ClassNames from '@streamloots/classnames';
import AvatarImage, { ROUNDED_PLACEHOLDER_IMAGE } from 'components/avatar-image';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { Trans } from 'react-i18next';
import theme from './subscription-packs.scss';

const classNames = ClassNames(theme);

export interface SubscriptionPacksProps extends TranslateInterface {
  setId: string;
  imageUrl: string;
  setName: string;
  onClaim: (setId: string, amount: number) => void;
  amount: number;
  isClaiming: boolean;
}

export const SubscriptionPacks = ({
  setId,
  imageUrl,
  setName,
  onClaim,
  amount,
  isClaiming,
  t,
}: SubscriptionPacksProps): JSX.Element => {
  return (
    <div className={classNames('subscription-gifter')}>
      <AvatarImage
        className={classNames('subscription-gifter__avatar')}
        src={imageUrl}
        alt={setName}
        fallbackSrc={ROUNDED_PLACEHOLDER_IMAGE}
      />
      <div className={classNames('subscription-gifter__text')}>
        <p className={classNames('subscription-gifter__text--name')}>{setName}</p>
        <Button primary onClick={() => onClaim(setId, amount)} loading={isClaiming} size="sm">
          {t('publicUserPage:packs.openPacks', { count: amount })}
        </Button>
      </div>
      <div className={classNames('subscription-gifter__counter')}>
        <div className={classNames('subscription-gifter__counter--number')}>{amount}</div>
        <div className={classNames('subscription-gifter__counter--label')}>{t('common:packs')}</div>
      </div>
    </div>
  );
};
