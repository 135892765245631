import { FunctionComponent, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { User } from 'model/indexTS';
import urlUtils from 'utils/urlUtils';
import { SubscriptionStatus } from 'domain/subscription-status';
import { overloadedContentActions } from 'services/overloaded-content';
import { userSelectors } from 'services/user';
import { getUrlWithParams, pages } from 'services/routing';
import { pageSelectors, PageState } from 'services/page';
import { SubscriptionCheckoutDialog, SubscriptionGiftCheckoutDialog } from 'scenes/subscription-checkout';
import { authSelectors } from 'services/auth';
import { GetSubscriptionOutput, subscriptionSelectors } from 'services/subscriptions';
import { SubscriptionCheckoutFromLoginLocations } from './constants';

interface MapStateToProps {
  isUserAuthenticated: boolean;
  slug: string;
  isOwner: boolean;
  subscription: GetSubscriptionOutput;
  user: User | null;
  hasPage: boolean;
  page: PageState;
}

interface DispatchToProps {
  openOverloadedContent: OpenOverloadedContent;
}

interface OpenSubscriptionCheckoutFromLoginProps extends MapStateToProps, DispatchToProps {}

const OpenSubscriptionCheckoutFromLoginBase = ({
  isUserAuthenticated,
  isOwner,
  slug,
  user,
  hasPage,
  subscription,
  page,
  openOverloadedContent,
}: OpenSubscriptionCheckoutFromLoginProps): null => {
  const userMustSign = useMemo(() => {
    const account = user?.settings?.account ?? {
      ACCEPTED_PRIVACY_POLICY: false,
      ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: false,
    };

    const { ACCEPTED_PRIVACY_POLICY, ACCEPTED_STREAMER_TERMS_AND_CONDITIONS } = account;
    return (
      !isUserAuthenticated || ACCEPTED_PRIVACY_POLICY === false || (hasPage && !ACCEPTED_STREAMER_TERMS_AND_CONDITIONS)
    );
  }, [hasPage, isUserAuthenticated, user]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const validLocations = Object.values(SubscriptionCheckoutFromLoginLocations);
    const { startLocation } = urlUtils.urlToParams(location.search) || {};
    const cannotSubscribe = userMustSign || isOwner || SubscriptionStatus.isActive(subscription);
    if (
      cannotSubscribe ||
      !validLocations.includes(startLocation as SubscriptionCheckoutFromLoginLocations) ||
      !page.modules?.subscriptions.enabled
    ) {
      return;
    }

    history.replace({
      pathname: getUrlWithParams(pages.USER_PAGE_SUBSCRIPTION, { slug }),
    });

    openOverloadedContent({
      component:
        startLocation === SubscriptionCheckoutFromLoginLocations.Checkout
          ? SubscriptionCheckoutDialog
          : SubscriptionGiftCheckoutDialog,
      componentProps: {
        showCheckout: true,
      },
      wrapperProps: {
        type: 'large',
        opacity: true,
      },
    });
  }, [history, isOwner, location.search, openOverloadedContent, page, slug, subscription, userMustSign]);
  return null;
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    slug: pageSelectors.slug(state),
    user: userSelectors.user(state),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    hasPage: !userSelectors.showOnboarding(state),
    isOwner: pageSelectors.isOwner(state),
    subscription: subscriptionSelectors.getSubscription(state),
    page: pageSelectors.page(state),
  };
};

const dispatchToProps: DispatchToProps = {
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
};

export const OpenSubscriptionCheckoutFromLogin = compose<FunctionComponent>(connect(mapStateToProps, dispatchToProps))(
  OpenSubscriptionCheckoutFromLoginBase,
);
