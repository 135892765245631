import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import ILLUSTRATIONS from 'constants/illustrations';
import urlUtils from 'utils/urlUtils';
import { TranslateInterface } from 'utils/translation';
import { urls } from 'services/routing';
import { PanelWithoutHeader } from 'ui-library/panel';
import Button from 'ui-library/button';
import { ShareOnTwitterButtonIcon } from 'components/social';
import { GiftSubscriptionType } from '@streamloots/streamloots-types';
import theme from './last-step.scss';

const classNames = ClassNames(theme);

export interface LastGiftStepProps extends TranslateInterface {
  slug: string;
  totalSubscriptions: number;
  giftType: GiftSubscriptionType;
  onClose: () => void;
  onPurchaseAgain: () => void;
}

export const LastGiftStep = ({
  giftType,
  slug,
  totalSubscriptions,
  onClose,
  onPurchaseAgain,
  t,
}: LastGiftStepProps): JSX.Element => {
  return (
    <PanelWithoutHeader
      className={classNames('last-step__container')}
      contentClassName={classNames('last-step__container--content')}
    >
      <div className={classNames('last-step')}>
        <div className={classNames('last-step__area')}>
          <img className={classNames('last-step__img')} src={ILLUSTRATIONS.WELCOME_STREAMERS} alt="" />
        </div>
        <div className={classNames('last-step__area align-left')}>
          <p className="margin-bottom">
            {t(`pageSubscription:checkout.lastStep.thanksGift.${giftType}`, {
              count: totalSubscriptions,
              slug,
            })}
          </p>
          <p className="margin-bottom">
            {t('pageSubscription:checkout.lastStep.sharePurchaseText')}
            <br />
            <ShareOnTwitterButtonIcon
              url={urlUtils.buildDomainUrl(urls().USER_PAGE)}
              text={t('pageSubscription:checkout.lastStep.tweetPurchase')}
              label={t('pageSubscription:checkout.lastStep.sharePurchase')}
              asLink
            />
          </p>
          <div className={classNames('last-step__gift-butons')}>
            <Button
              label={t('pageSubscription:checkout.lastStep.close')}
              onClick={onClose}
              className="margin-bottom-s"
            />
            <Button
              className="margin-bottom-s"
              label={t('pageSubscription:checkout.lastStep.getAnotherSubscription')}
              onClick={onPurchaseAgain}
              primary
              cta
            />
          </div>
        </div>
      </div>
    </PanelWithoutHeader>
  );
};
