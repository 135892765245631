import { useMemo } from 'react';
import { Dropdown } from 'ui-library/form-elements';
import ClassNames from '@streamloots/classnames';
import { isOfType } from 'types/indexTS';
import { Dropdown as DropdownField } from 'ui-library/forms';
import { SetStatus } from 'components/set';
import { Set, UserSet } from 'model/setsTS';
import theme from './public-sets-dropdown.scss';

const classNames = ClassNames(theme);

interface BaseProps {
  name: string;
  label?: string;
  sets?: Array<UserSet | Set>;
  includeStatus?: boolean;
}

interface NoFieldProps extends BaseProps {
  isField?: false;
  onChange: (userSetId: string) => void;
  value: string;
}
interface FieldProps extends BaseProps {
  isField: true;
  validate?: Array<(value: string) => string | undefined> | ((value: string) => string | undefined);
}

interface NormalizedSet extends Pick<Set, '_id' | 'name' | 'imageUrl' | 'published' | 'paused'> {
  value: string;
  label: string;
}

type PublicSetsDropdownProps = FieldProps | NoFieldProps;

const getRenderView = (set: NormalizedSet, includeStatus = false): JSX.Element => {
  if (!includeStatus) {
    return (
      <>
        <img className={theme.option__image} src={set.imageUrl} alt="" /> {set.name}
      </>
    );
  }
  return (
    <div className={classNames('option')}>
      <img className={theme.option__image} src={set.imageUrl} alt="" />
      <div>
        <strong className={classNames('option__name')}> {set.name}</strong>
        <SetStatus paused={set.paused} published={set.published} className="font-small" />
      </div>
    </div>
  );
};

export const PublicSetsDropdown = ({
  name,
  sets,
  includeStatus = false,
  label,
  ...rest
}: PublicSetsDropdownProps): JSX.Element => {
  const normalizedSets = useMemo(() => {
    return !sets
      ? []
      : sets.map(set => ({
          _id: set._id,
          value: set._id,
          name: set.name,
          imageUrl: set.imageUrl,
          label: set.name,
          published: isOfType<Set>(set, 'published') ? set.published : true,
          paused: set.paused,
        }));
  }, [sets]);

  if (rest.isField) {
    return (
      <DropdownField
        id={`${name}-public-sets-picker`}
        className={classNames({
          'input--status': includeStatus,
        })}
        name={name}
        options={normalizedSets}
        renderOption={set => getRenderView(set, includeStatus)}
        label={label}
        required
        {...rest}
      />
    );
  }

  return (
    <Dropdown
      id={`${name}-public-sets-picker`}
      name={name}
      className={classNames({
        'input--status': includeStatus,
      })}
      options={normalizedSets}
      renderOption={set => getRenderView(set, includeStatus)}
      label={label}
      required
      {...rest}
    />
  );
};
