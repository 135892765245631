import { pages } from 'services/routing';
import { NewPagesModules, PageModulesValues } from 'model/indexTS';

export interface PublicPageNavTab {
  key: PageModulesValues;
  icon: string;
  isNew?: boolean;
  label: string;
  link: string;
}

interface NavTabs {
  [NewPagesModules.Cards]: PublicPageNavTab;
  [NewPagesModules.Subscriptions]: PublicPageNavTab;
  [NewPagesModules.Auctions]: PublicPageNavTab;
  [NewPagesModules.Donations]: PublicPageNavTab;
  [NewPagesModules.Feeds]: PublicPageNavTab;
}

export const NAV_TABS: NavTabs = {
  [NewPagesModules.Cards]: {
    key: NewPagesModules.Cards,
    icon: 'cards',
    label: 'collection:navBar.collections',
    link: pages.USER_PAGE_COLLECTIONS,
  },
  [NewPagesModules.Subscriptions]: {
    key: NewPagesModules.Subscriptions,
    icon: 'star',
    label: 'collection:navBar.subscription',
    link: pages.USER_PAGE_SUBSCRIPTION,
  },
  [NewPagesModules.Auctions]: {
    key: NewPagesModules.Auctions,
    icon: 'auction',
    label: 'collection:navBar.auctions',
    link: pages.ALL_AUCTIONS,
  },
  [NewPagesModules.Donations]: {
    key: NewPagesModules.Donations,
    icon: 'star',
    label: 'collection:navBar.donations',
    link: pages.USER_PAGE_DONATIONS,
    isNew: true,
  },
  [NewPagesModules.Feeds]: {
    key: NewPagesModules.Feeds,
    icon: 'feedback',
    label: 'collection:navBar.feed',
    link: pages.USER_PAGE_FEED,
  },
};
