import { useContext, useEffect } from 'react';
import trackEventManager from 'utils/event-manager/trackEventManager';
import { SidebarOpenerContext } from 'contexts/sidebar-opener/';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { ConnectedPublicRanking } from '../public-ranking';
import theme from './public-user-page-nav.scss';

const classNames = ClassNames(theme);

export const PublicUserPageNaveRanking = () => {
  const { open, close } = useContext(SidebarOpenerContext);

  useEffect(() => {
    close();
  }, [close]);

  const rankingClicked = () => {
    open({
      component: <ConnectedPublicRanking />,
      attributes: {
        sidebarContentClassName: classNames('tabs__padding'),
      },
    });

    trackEventManager.trackEvent('publicRankingButtonClicked', {
      source: 'desktopPageMenu',
    });
  };

  return (
    <button className={classNames('pup-nav__button-action')} type="button" onClick={rankingClicked}>
      <Icon id="cup" />
      <span className={classNames('pup-nav__button-action__label')}>Ranking</span>
    </button>
  );
};
