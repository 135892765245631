import ClassNames from '@streamloots/classnames';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { usePageSubscriptionPlansSelectors } from 'services/page-subscription-plans';
import { useSubscriptionsActions } from 'services/subscriptions';
import { useCultureSelectors } from 'services/culture';
import { useReactAlert } from 'components/react-alert-provider';
import { useCallback, useEffect, useState } from 'react';
import { SubscriptionAlertsItem } from './SubscriptionAlertsItem';
import theme from './subscription-alerts.scss';
import { SubscriptionRewards } from '../subscription-rewards';
import { SubscriptionAlertsHeader } from './SubscriptionAlertsHeader';
import { SubscriptionAlertsEmotes } from './SubscriptionAlertsEmotes';

const DEFAULT_ALERT_COOLDOWN_SECONDS = 60;

export interface SubscriptionAlertsProps {
  slug: string;
  isSubscribed?: boolean;
  remainingAlerts: number;
  initAlertCooldown: number;
  onSubscribe: (cta: string, name?: string) => void;
}

interface ExtendedProps extends SubscriptionAlertsProps, TranslateInterface {}

const classNames = ClassNames(theme);

const SubscriptionAlertsBase = ({
  isSubscribed = false,
  remainingAlerts,
  onSubscribe,
  t,
  slug,
  initAlertCooldown,
}: ExtendedProps): JSX.Element | null => {
  const [cooldownSeconds, setCooldownSeconds] = useState(
    initAlertCooldown > 0 ? initAlertCooldown : DEFAULT_ALERT_COOLDOWN_SECONDS,
  );
  const [isReactionPlaying, setIsReactionPlaying] = useState(initAlertCooldown > 0);
  const [totalAlerts, setTotalAlerts] = useState(remainingAlerts);
  const { showSuccess } = useReactAlert();
  const handleFinishCountDown = useCallback(() => setIsReactionPlaying(false), []);
  const { subscriptionPlan } = usePageSubscriptionPlansSelectors();
  const { language } = useCultureSelectors();
  const { playAlert } = useSubscriptionsActions();

  useEffect(() => {
    setTotalAlerts(remainingAlerts);
  }, [remainingAlerts]);

  const handlePlayAlert = (_id: string): void => {
    if (totalAlerts > 0) {
      playAlert(slug, _id).then(() => {
        setTotalAlerts(prev => prev - 1);
        showSuccess(t('collection:subscriptionsAlert.success'));
      });
      setCooldownSeconds(DEFAULT_ALERT_COOLDOWN_SECONDS);
      setIsReactionPlaying(true);
    }
  };

  if (!subscriptionPlan) {
    return null;
  }

  const hasAlerts = subscriptionPlan.reactionsEnabled && subscriptionPlan.enabledAlerts.length > 0;
  const userAlerts = subscriptionPlan.alerts.filter(alert => subscriptionPlan.enabledAlerts.includes(alert._id));

  return (
    <div className={classNames('subs')}>
      <SubscriptionAlertsHeader
        hasAlertsEnabled={hasAlerts}
        isSubscribed={isSubscribed}
        totalAlerts={totalAlerts}
        t={t}
      />
      <SubscriptionRewards hasAlertsEnabled={hasAlerts} isSubscribed={isSubscribed} />
      <div className={classNames('subs__alerts')}>
        <SubscriptionAlertsEmotes t={t} isSubscribed={isSubscribed} onSubscribe={onSubscribe} />
        {hasAlerts &&
          userAlerts.map(alert => (
            <SubscriptionAlertsItem
              key={alert._id}
              alert={alert}
              isSubscribed={isSubscribed}
              isReactionPlaying={isReactionPlaying}
              onClick={handlePlayAlert}
              onSubscribe={onSubscribe}
              onFinishCountDown={handleFinishCountDown}
              cooldownSeconds={cooldownSeconds}
              remainingAlerts={totalAlerts}
              language={language}
            />
          ))}
      </div>
    </div>
  );
};

export const SubscriptionAlerts = withTranslation('collection')(SubscriptionAlertsBase);
