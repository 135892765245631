import * as React from 'react';
import { UserSearch } from 'components/user-search';
import Toggle from 'components/toggle';
import ClassNames from '@streamloots/classnames';
import { TranslateInterface } from 'utils/translation';
import theme from './gift-options.scss';

interface OwnProps extends TranslateInterface {
  onUserGiftGifteeChange: (giftee: string) => void;
  userGiftee: string;
}

const classNames = ClassNames(theme);

export const UserGiftForm: React.FunctionComponent<OwnProps> = ({
  userGiftee,
  onUserGiftGifteeChange,
  t,
}: OwnProps): JSX.Element => (
  <div className={classNames('gift-options__user')}>
    <Toggle isOpen animationKey="purchase-as-gift">
      <UserSearch
        label={t('pageSubscription:checkout.giftStep.selectUser')}
        searchKey="purchaseAsGift"
        onUserClick={({ _id }) => onUserGiftGifteeChange(_id)}
        selectedValue={userGiftee}
      />
    </Toggle>
  </div>
);
