import React from 'react';
import { connect } from 'react-redux';
import type { CouponRedemptionStatus, RedeemCoupon, ResetCouponRedemption, FetchSingleCoupon } from 'services/coupons';
import type { ChangeSelectedSet } from 'services/sets';
import type { Coupon, UserSet } from 'model/indexTS';
import { authSelectors } from 'services/auth';
import { userSetsSelectors } from 'services/user-sets';
import { setsActions } from 'services/sets';
import { pageSelectors } from 'services/page';
import { couponsActions, couponCookieManager, couponsSelectors } from 'services/coupons';
import RedeemCouponDialog from './RedeemCouponDialog';

interface MapDispatchToProps {
  redeemCoupon: RedeemCoupon;
  resetCouponRedemption: ResetCouponRedemption;
  fetchSingleCoupon: FetchSingleCoupon;
  changeSelectedSet: ChangeSelectedSet;
}

interface MapStateToProps {
  couponRedemptionStatus: CouponRedemptionStatus;
  isUserAuthenticated: boolean;
  setId: string;
  slug: string;
  hasLoadedUserSets: boolean;
  userSets?: UserSet[];
}

interface ConnectedRedeemCouponDialogProps extends MapStateToProps, MapDispatchToProps {}

type ConnectedRedeemCouponDialogState = {
  showDialog: boolean;
  selectedSetId: string;
  couponUserSets: Array<UserSet>;
  isLoading?: boolean;
  error?: string;
};

class ConnectedRedeemCouponDialog extends React.Component<
  ConnectedRedeemCouponDialogProps,
  ConnectedRedeemCouponDialogState
> {
  constructor(props) {
    super(props);
    const {
      couponRedemptionStatus: { couponCode },
      setId,
    } = this.props;

    this.state = {
      showDialog: !!couponCode,
      selectedSetId: setId,
      couponUserSets: [],
    };
  }

  componentDidMount() {
    const {
      couponRedemptionStatus: { couponCode },
      isUserAuthenticated,
    } = this.props;

    /*
     * we invalidate the cookie if the user is authenticated. It doesn't matter whether the user doesn't redeem it.
     * We assume that if a user doesn't redeem it and s/he is still interested in the coupon, will use the url again.
     */
    if (isUserAuthenticated && couponCode) {
      this.handleFetchSingleCoupon();
      couponCookieManager.removeCouponCode();
    }
  }

  handleFetchSingleCoupon = () => {
    const {
      couponRedemptionStatus: { couponCode = '' },
      slug,
      fetchSingleCoupon,
    } = this.props;

    this.setState(prevState => ({ ...prevState, isLoading: true, error: '' }));

    fetchSingleCoupon(slug, couponCode).then(response => {
      if (response.error) {
        this.handleError(response.payload.message);
        return;
      }
      const payload = response.payload as Coupon;
      const { setIds } = payload;

      const filteredSets = this.filteredSets(setIds);
      const filteredSetId = this.filteredSetId(filteredSets);

      if (filteredSets.length === 0) {
        this.handleError('This coupon is not valid for any public collection');
        return;
      }

      this.handleSuccessFetchSingleCoupon(filteredSets, filteredSetId);
    });
  };

  handleRedeemClick = () => {
    const {
      couponRedemptionStatus: { couponCode = '' },
      changeSelectedSet,
      redeemCoupon,
    } = this.props;
    const { selectedSetId } = this.state;
    changeSelectedSet(selectedSetId);
    return redeemCoupon(couponCode, selectedSetId);
  };

  handleSetIdChange = (setId: string) => {
    this.setState({ selectedSetId: setId });
  };

  handleError = (error: string) => {
    this.setState(prevState => ({ ...prevState, isLoading: false, error }));
  };

  handleCloseDialog = () => {
    const { resetCouponRedemption } = this.props;
    resetCouponRedemption();

    this.setState({
      showDialog: false,
    });
  };

  handleSuccessFetchSingleCoupon = (filteredSets, filteredSetId) => {
    this.setState(prevState => ({
      ...prevState,
      isLoading: false,
      error: '',
      couponUserSets: filteredSets,
      selectedSetId: filteredSetId,
    }));
  };

  filteredSets = (setIds: string[]): UserSet[] => {
    const { userSets } = this.props;

    if (userSets === undefined) {
      return [];
    }

    if (setIds.length === 0) {
      return userSets;
    }

    return userSets.filter(currSet => {
      for (let i = 0; i < setIds.length; i += 1) {
        if (currSet._id === setIds[i]) {
          return true;
        }
      }

      return false;
    });
  };

  filteredSetId = sets => {
    const { selectedSetId } = this.state;
    if (sets.length === 0) {
      return selectedSetId;
    }
    const idExistsInSets = sets.find(set => set._id === selectedSetId);

    return idExistsInSets ? selectedSetId : sets[0]._id;
  };

  render() {
    const { couponRedemptionStatus, isUserAuthenticated, slug, hasLoadedUserSets } = this.props;

    const { selectedSetId, showDialog, couponUserSets, error, isLoading } = this.state;

    const { couponCode, couponExpired, chestsReceived } = couponRedemptionStatus;

    if ((!couponCode && !couponExpired && !chestsReceived) || !hasLoadedUserSets) {
      return null;
    }

    return (
      <RedeemCouponDialog
        {...couponRedemptionStatus}
        onSetIdChange={this.handleSetIdChange}
        isUserAuthenticated={isUserAuthenticated}
        showDialog={showDialog}
        slug={slug}
        onClose={this.handleCloseDialog}
        onRedeemClick={this.handleRedeemClick}
        selectedSetId={selectedSetId}
        couponUserSets={couponUserSets}
        errorCoupon={error}
        isLoadingCoupon={isLoading}
      />
    );
  }
}

const mapStateToProps = (state): MapStateToProps => {
  const setId = userSetsSelectors.setId(state);

  return {
    couponRedemptionStatus: couponsSelectors.couponRedemptionStatus(state),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    slug: pageSelectors.slug(state),
    setId,
    hasLoadedUserSets: userSetsSelectors.hasLoadedUserSets(state),
    userSets: userSetsSelectors.userSets(state),
  };
};

export default connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  redeemCoupon: couponsActions.redeemCoupon,
  resetCouponRedemption: couponsActions.resetCouponRedemption,
  fetchSingleCoupon: couponsActions.fetchSingleCoupon,
  changeSelectedSet: setsActions.changeSelectedSet,
})(ConnectedRedeemCouponDialog);
