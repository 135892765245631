import { matchPath } from 'react-router-dom';
import { pages } from 'services/routing';
import { NewPagesModules, PageModules, PageModulesValues } from 'model/pageTS';
import configuration from 'config';

export interface MetadataPageChecker {
  hasMarathonSpecialSet: boolean;
}

export const getDefaultModuleToRender = (
  modules: PageModules | undefined,
  metadata: MetadataPageChecker,
): PageModulesValues | null => {
  if (!modules) {
    return null;
  }

  if (modules.cards.enabled) {
    return NewPagesModules.Cards;
  }

  if (modules.marathons.enabled && metadata.hasMarathonSpecialSet) {
    return NewPagesModules.Cards;
  }

  if (modules.subscriptions.enabled) {
    return NewPagesModules.Subscriptions;
  }

  if (modules.auctions.enabled) {
    return NewPagesModules.Auctions;
  }

  if (configuration.modules.DONATIONS_ENABLED && modules.donations.enabled) {
    return NewPagesModules.Donations;
  }

  if (modules.feeds.enabled) {
    return NewPagesModules.Feeds;
  }

  return null;
};

export const getModuleToRender = (
  currentPathName: string,
  modules: PageModules | undefined,
  metadata: MetadataPageChecker,
): PageModulesValues | null => {
  const defaultToRender = getDefaultModuleToRender(modules, metadata);

  if (!defaultToRender) {
    return null;
  }

  if (matchPath(currentPathName, pages.USER_PAGE_SUBSCRIPTION)) {
    return NewPagesModules.Subscriptions;
  }

  if (matchPath(currentPathName, pages.ALL_AUCTIONS)) {
    return NewPagesModules.Auctions;
  }

  if (configuration.modules.DONATIONS_ENABLED && matchPath(currentPathName, pages.USER_PAGE_DONATIONS)) {
    return NewPagesModules.Donations;
  }

  if (
    matchPath(currentPathName, pages.USER_PAGE_COLLECTIONS) ||
    matchPath(currentPathName, pages.USER_PAGE_COLLECTION)
  ) {
    return NewPagesModules.Cards;
  }

  if (matchPath(currentPathName, pages.USER_PAGE_FEED)) {
    return NewPagesModules.Feeds;
  }

  return defaultToRender;
};

export const getModulesRequired = (): PageModulesValues[] => {
  const modules: PageModulesValues[] = [
    NewPagesModules.Cards,
    NewPagesModules.Subscriptions,
    NewPagesModules.Marathons,
    NewPagesModules.Feeds,
  ];

  if (configuration.modules.DONATIONS_ENABLED) {
    modules.push(NewPagesModules.Donations);
  }

  return modules;
};
