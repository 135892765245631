import { useMemo } from 'react';
import { PagesModules } from '@streamloots/streamloots-types';
import { NewPagesModules, PageModules, PageModulesValues } from 'model/pageTS';
import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import configuration from 'config';
import { NAV_TABS } from './nav-tabs-config';
import { NavTabsItem } from './NavTabsItem';
import theme from './nav-tabs.scss';

export interface NavTabsProps {
  slug: string;
  activeAuctionsCount?: number;
  newFeedsCount?: number;
  modules?: PageModules;
  moduleRendered: PageModulesValues | null;
  hasSpecialMarathonCollection: boolean;
}

const classNames = ClassNames(theme);

interface TabsState {
  activeModules: number;
  modules: PageModules;
  hasMarathonCollection: boolean;
}

const showTab = (state: TabsState, key: string) => {
  switch (key as PageModulesValues) {
    case NewPagesModules.Auctions:
    case NewPagesModules.Cards:
    case NewPagesModules.Subscriptions:
    case NewPagesModules.Feeds:
      return { ...state, activeModules: state.modules[key].enabled ? state.activeModules + 1 : state.activeModules };
    case NewPagesModules.Marathons:
      if (!state.modules[PagesModules.Cards].enabled && state.hasMarathonCollection) {
        return { ...state, activeModules: state.activeModules + 1 };
      }
      return state;
    case NewPagesModules.Donations:
      return {
        ...state,
        activeModules:
          configuration.modules.DONATIONS_ENABLED && state.modules[key].enabled
            ? state.activeModules + 1
            : state.activeModules,
      };
    default:
      return state;
  }
};

export const NavTabs = ({
  slug,
  activeAuctionsCount = 0,
  newFeedsCount = 0,
  moduleRendered,
  modules,
  hasSpecialMarathonCollection,
}: NavTabsProps): JSX.Element | null => {
  const { t } = useTranslation('collection');
  const showAuctionsModule = modules?.auctions.enabled && (moduleRendered === 'auctions' || activeAuctionsCount > 0);

  const numActive = useMemo(() => {
    if (!modules) {
      return 0;
    }

    const initialState: TabsState = {
      activeModules: 0,
      modules,
      hasMarathonCollection: hasSpecialMarathonCollection,
    };

    const state = Object.keys(modules).reduce(showTab, initialState);

    return state.activeModules;
  }, [hasSpecialMarathonCollection, modules]);

  if (!moduleRendered || !modules || numActive < 2) {
    return null;
  }

  const { subscriptions, cards, auctions, donations, feeds } = NAV_TABS;
  const showCards = modules?.cards.enabled || hasSpecialMarathonCollection;

  return (
    <div className={classNames('nav-tabs')}>
      <div className={classNames('nav-tabs__container')}>
        <div className={classNames('nav-tabs__items')}>
          {showCards && (
            <NavTabsItem
              {...cards}
              label={t(cards.label)}
              tabId={cards.key}
              isSelected={cards.key === moduleRendered}
              slug={slug}
            />
          )}
          {modules?.subscriptions.enabled && (
            <NavTabsItem
              {...subscriptions}
              label={t(subscriptions.label)}
              tabId={subscriptions.key}
              isSelected={subscriptions.key === moduleRendered}
              slug={slug}
            />
          )}
          {showAuctionsModule && (
            <NavTabsItem
              {...auctions}
              unreadCount={activeAuctionsCount}
              unreadTitle={t('collection:navBar.activeAuctionsTitle', { count: activeAuctionsCount })}
              label={t(auctions.label)}
              tabId={auctions.key}
              isSelected={auctions.key === moduleRendered}
              slug={slug}
            />
          )}
          {configuration.modules.DONATIONS_ENABLED && modules?.donations?.enabled && (
            <NavTabsItem
              {...donations}
              label={t(donations.label)}
              tabId={donations.key}
              isSelected={donations.key === moduleRendered}
              slug={slug}
            />
          )}
          {modules?.feeds.enabled && (
            <NavTabsItem
              {...feeds}
              label={t(feeds.label)}
              tabId={feeds.key}
              isSelected={feeds.key === moduleRendered}
              slug={slug}
              unreadCount={newFeedsCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};
