import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { FixMe } from 'types/indexTS';
import type { UserCard, UserSet } from 'model/indexTS';
import type { CraftCards, DisenchantCards } from 'services/crafting-mode';
import { craftingModeActions } from 'services/crafting-mode';
import type { WithAlertProviderProps } from 'components/react-alert-provider/typesTS';
import { TranslateInterface, withTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager/trackEventManager';
import { userSetsSelectors } from 'services/user-sets';
import { withAlertProvider } from 'components/react-alert-provider';
import { CraftingViewModes, optionsPanelProps } from './constants';
import CraftingViewOptionPanel from './CraftingViewOptionPanel';

interface MapStateToProps {
  userSet: UserSet | null;
}

interface MapDispatchToProps {
  craftCards: CraftCards;
  disenchantCards: DisenchantCards;
}

interface ConnectedCraftingViewOptionPanelOwnProps {
  card: UserCard;
  cost: number;
  dustBalance: number;
  mode: CraftingViewModes;
}

interface ConnectedCraftingViewOptionPanelProps
  extends ConnectedCraftingViewOptionPanelOwnProps,
    MapStateToProps,
    WithAlertProviderProps,
    MapDispatchToProps,
    TranslateInterface {}

type ConnectedCraftingViewOptionPanelState = {
  count: number;
  showConfirmation: boolean;
  isLoading: boolean;
};

class ConnectedCraftingViewOptionPanelInternal extends React.Component<
  ConnectedCraftingViewOptionPanelProps,
  ConnectedCraftingViewOptionPanelState
> {
  state = {
    count: 1,
    showConfirmation: false,
    isLoading: false,
  };

  handleCountChange = (newValue: number | '') => {
    this.setState({ count: newValue || 1 });
  };

  getAction = (): FixMe => {
    const { mode, craftCards, disenchantCards } = this.props;
    return mode === CraftingViewModes.CRAFTING ? craftCards : disenchantCards;
  };

  getMax = () => {
    const {
      mode,
      card: {
        craftingCost,
        count: { availableFragments, redeemable },
        dropLimited,
        dropLimitRemaining = 0,
      },
      dustBalance,
    } = this.props;

    if (this.isBlocked()) {
      return 0;
    }

    if (mode === CraftingViewModes.CRAFTING) {
      const maxCraftable = Math.round(dustBalance / craftingCost);
      return dropLimited && dropLimitRemaining && maxCraftable > dropLimitRemaining ? dropLimitRemaining : maxCraftable;
    }

    return availableFragments || redeemable;
  };

  getBlockPrefix = () => {
    const {
      mode,
      card: { dropLimited, dropLimitRemaining, craftable },
    } = this.props;
    if (mode !== CraftingViewModes.CRAFTING) {
      return 'block';
    }

    if (!craftable) {
      return 'notCraftable';
    }

    return dropLimited && dropLimitRemaining === 0 ? 'dropLimitReached' : 'block';
  };

  getI18nKeys = () => {
    const {
      mode,
      card: { fragmented },
    } = this.props;
    const itemName = fragmented ? 'fragment' : 'card';
    const blockPrefix = this.getBlockPrefix();
    return {
      block: `${mode}.${itemName}.${blockPrefix}`,
      title: `${mode}.title`,
      button: `${mode}.button`,
      summaryAction: `${mode}.summaryAction`,
      summaryResult: `${mode}.summaryResult`,
      itemCount: `${mode}.${itemName}.itemCount`,
      error: `${mode}.${itemName}.error`,
      itemsFailed: `${mode}.${itemName}.itemsFailed`,
      success: `${mode}.${itemName}.success`,
    };
  };

  isBlocked = (): boolean => {
    const {
      dustBalance,
      mode,
      card: { count, craftingCost, craftable, fragmented, dropLimited, dropLimitRemaining },
    } = this.props;

    if (mode === CraftingViewModes.CRAFTING) {
      return Boolean(!craftable || dustBalance < craftingCost || (dropLimited && dropLimitRemaining === 0));
    }

    if (fragmented) {
      return count.availableFragments === 0;
    }

    return count.redeemable === 0;
  };

  handleActionClick = () => {
    const { card, mode, userSet, showErrorAlert, showSuccessAlert, t } = this.props;

    if (!userSet) {
      return;
    }

    const { count } = this.state;
    const { collectionId, _id: setId } = userSet;
    const action = this.getAction();
    const i18nKeys = this.getI18nKeys();

    this.setState({ isLoading: true });

    action(card._id, count, collectionId, setId).then(response => {
      const { error, payload } = response;
      this.toggleConfirmation();

      if (error) {
        showErrorAlert(payload.message);
        return;
      }

      const { collectionCardIds } = payload;
      showSuccessAlert(t(i18nKeys.success, { count: collectionCardIds.length }));

      trackEventManager.cardCraftingEvent({
        card,
        count,
        mode,
        setId,
        source: 'Crafting mode',
      });
    });
  };

  toggleConfirmation = () => {
    const { showConfirmation } = this.state;
    this.setState({ showConfirmation: !showConfirmation, isLoading: false });
  };

  render() {
    const { cost, mode, t } = this.props;
    const { isLoading, count, showConfirmation } = this.state;
    const modeProps = optionsPanelProps[mode];
    return (
      <CraftingViewOptionPanel
        {...modeProps}
        isDisenchantingMode={mode === CraftingViewModes.DISENCHANTING}
        i18nKeys={this.getI18nKeys()}
        onActionClick={this.handleActionClick}
        onValueChange={this.handleCountChange}
        showConfirmation={showConfirmation}
        toggleConfirmation={this.toggleConfirmation}
        t={t}
        value={count}
        cost={cost || 0}
        isLoading={isLoading}
        isBlocked={this.isBlocked()}
        max={this.getMax()}
      />
    );
  }
}

const mapStateToProps = (state): MapStateToProps => {
  return {
    userSet: userSetsSelectors.selectedUserSet(state),
  };
};

export const ConnectedCraftingViewOptionPanel = compose<
  React.FunctionComponent<ConnectedCraftingViewOptionPanelOwnProps>
>(
  connect(mapStateToProps, {
    craftCards: craftingModeActions.craftCards,
    disenchantCards: craftingModeActions.disenchantCards,
  }),
  withAlertProvider,
  withTranslation('disenchant'),
)(ConnectedCraftingViewOptionPanelInternal);
