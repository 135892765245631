import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import ClassNames from '@streamloots/classnames';
import ScrollBarPanel from 'components/scrollbar-panel';
import { pageSelectors } from 'services/page';
import {
  ClaimSubscriptionGift,
  FetchSubscriptionGifts,
  subscriptionGiftsActions,
  SubscriptionGiftsSelectorOutput,
  subscriptionGiftsSelectors,
} from 'services/page-subscription-gifts';
import { useReactAlert } from 'components/react-alert-provider';
import { authSelectors } from 'services/auth';
import { FetchSubscriptions, subscriptionsActions } from 'services/subscriptions';
import trackEventManager from 'utils/event-manager';
import type { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { overloadedContentActions } from 'services/overloaded-content';
import { AuthDialog } from 'scenes/auth';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { isActionError } from 'types/indexTS';
import theme from './subscription-claim.scss';
import { SubscriptionGifter } from './SubscriptionGifter';

interface OwnProps {
  isSubscribed: boolean;
}

interface MapStateToProps {
  slug: string;
  isLoading: boolean;
  isUserAuthenticated: boolean;
  subscriptionGifts: SubscriptionGiftsSelectorOutput;
}

interface MapDispatchToProps {
  fetchSubscription: FetchSubscriptions;
  fetchSubscriptionGifs: FetchSubscriptionGifts;
  openOverloadedContent: OpenOverloadedContent;
  claimSubscriptionGift: ClaimSubscriptionGift;
}

interface ExtendedProps extends OwnProps, MapStateToProps, MapDispatchToProps, TranslateInterface {}

const classNames = ClassNames(theme);

const SubscriptionClaimBase: React.FunctionComponent<ExtendedProps> = ({
  fetchSubscription,
  fetchSubscriptionGifs,
  isLoading,
  isUserAuthenticated,
  openOverloadedContent,
  slug,
  subscriptionGifts,
  claimSubscriptionGift,
  t,
  isSubscribed,
}: ExtendedProps): JSX.Element | null => {
  const [isClaiming, setIsClaiming] = React.useState(false);
  const { showSuccess, showError, showWarning } = useReactAlert();

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchSubscriptionGifs(slug);
    }, 15_000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchSubscriptionGifs, slug]);

  React.useEffect(() => {
    if (!isLoading && subscriptionGifts === null && !!slug) {
      fetchSubscriptionGifs(slug);
    }
  }, [fetchSubscriptionGifs, isLoading, slug, subscriptionGifts]);

  if (!slug || !subscriptionGifts || subscriptionGifts.length === 0) {
    return null;
  }

  const handleOnClaimGift = async (subscriptionGiftId: string) => {
    if (!isUserAuthenticated) {
      const componentKey = 'subscriptionGiftClaim';
      trackEventManager.trackEvent('loginBtnClick', { componentKey });
      openOverloadedContent({
        component: AuthDialog,
        componentProps: {
          componentKey,
          location: 'community gift subscription claim',
        },
      });
      return;
    }
    setIsClaiming(true);
    const response = await claimSubscriptionGift(subscriptionGiftId);
    if (isActionError(response)) {
      const { metadata } = response;
      if (metadata.businessCode === 'SUBSCRIPTION_GIFT_NOT_EXISTS') {
        showError(t('pageSubscription:gifts.notExists'));
      }
      if (metadata.businessCode === 'SUBSCRIPTION_GIFT_DEPLETED') {
        fetchSubscriptionGifs(slug);
        showWarning(t('pageSubscription:gifts.depleted'));
      }
      if (metadata.businessCode === 'ALREADY_SUBSCRIBED') {
        showWarning(t('pageSubscription:gifts.alreadySubs'));
      }
    } else {
      showSuccess(t('pageSubscription:gifts.claimSuccess'));
      fetchSubscriptionGifs(slug);
      fetchSubscription(slug);
    }

    setIsClaiming(false);
  };

  return (
    <div className={classNames('subscription-claim')}>
      <ScrollBarPanel
        autoHeight
        autoHeightMin={38}
        autoHeightMax={150}
        contentClassName={classNames('subscription-claim__panel')}
      >
        {subscriptionGifts.map(subscriptionGift => (
          <SubscriptionGifter
            key={subscriptionGift._id}
            subscriptionGiftId={subscriptionGift._id}
            avatarUrl={subscriptionGift.gifterImageUrl}
            username={subscriptionGift.gifterUsername}
            onClaimGift={handleOnClaimGift}
            remainingSubscriptionsQuantity={subscriptionGift.remainingSubscriptionsQuantity}
            purchasedSubscriptionsQuantity={subscriptionGift.purchasedSubscriptionsQuantity}
            totalPrepaidMonths={subscriptionGift.totalPrepaidMonths}
            t={t}
            isSubscribed={isSubscribed}
            isClaiming={isClaiming}
          />
        ))}
      </ScrollBarPanel>
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  slug: pageSelectors.slug(state),
  isLoading: subscriptionGiftsSelectors.isLoadingData(state),
  isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  subscriptionGifts: subscriptionGiftsSelectors.getSubscriptionGifts(state),
});

const mapDispatchToProps = {
  fetchSubscription: subscriptionsActions.fetchSubscriptions,
  fetchSubscriptionGifs: subscriptionGiftsActions.fetchSubscriptionGifts,
  claimSubscriptionGift: subscriptionGiftsActions.claimSubscriptionGift,
  openOverloadedContent: overloadedContentActions.openOverloadedContent,
};

export const SubscriptionClaim = compose(
  withTranslation('pageSubscription'),
  connect(mapStateToProps, mapDispatchToProps),
)(SubscriptionClaimBase) as React.FunctionComponent<OwnProps>;
