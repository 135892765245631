import { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { Emote } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { isEmpty } from 'lodash';
import Button, { ButtonIcon } from 'ui-library/button';
import { useReactAlert } from 'components/react-alert-provider';
import { useTranslation } from 'utils/translation';
import { useSubscriptionAlertsEmotes } from './useSubscriptionAlertsEmotes';
import theme from './subscription-alerts.scss';

export interface SubscriptionAlertsEmotesFooterProps {
  isSubscribed: boolean;
  onSubscribe: (cta: string, name: string) => void;
  currentEmotes: Emote[];
  setCurrentEmotes: Dispatch<SetStateAction<Emote[]>>;
  emotesDiv: RefObject<HTMLDivElement>;
}

const classNames = ClassNames(theme);

export const SubscriptionAlertsEmotesFooter: FC<SubscriptionAlertsEmotesFooterProps> = ({
  isSubscribed,
  currentEmotes,
  setCurrentEmotes,
  onSubscribe,
  emotesDiv,
}: SubscriptionAlertsEmotesFooterProps): JSX.Element => {
  const { playEmotes, pageId, emoteLinkCopied } = useSubscriptionAlertsEmotes();
  const { showSuccess } = useReactAlert();
  const { t } = useTranslation();

  // Handlers
  const handleClearEmotes = () => {
    setCurrentEmotes([]);
  };

  const handleClickCTA = () => {
    if (isSubscribed && pageId) {
      playEmotes(
        pageId,
        currentEmotes.map(({ name }) => name),
      ).then(() => {
        showSuccess(t('collection:subscriptionsAlert.emotes.success', { count: currentEmotes.length }));
        setCurrentEmotes([]);
      });
    } else {
      onSubscribe('ctaEmotes', 'emotes');
    }
  };

  // Render
  if (isSubscribed && emoteLinkCopied) {
    return (
      <>
        <div className={classNames('subs__emotes__footer--text')}>
          <div className={classNames('subs__emotes__footer--text__content')} ref={emotesDiv}>
            {isEmpty(currentEmotes)
              ? t('collection:subscriptionsAlert.emotes.placeholder')
              : currentEmotes.map(emote => <img key={emote.name} src={emote.imageUrl} alt={emote.name} />)}
          </div>
          {!isEmpty(currentEmotes) && (
            <ButtonIcon
              icon="circle-close"
              className={classNames('subs__emotes__footer--text__clear')}
              onClick={handleClearEmotes}
            />
          )}
        </div>
        <Button
          primary
          onClick={handleClickCTA}
          disabled={isSubscribed && isEmpty(currentEmotes)}
          className={classNames('subs__emotes__footer__send')}
        >
          {t('collection:subscriptionsAlert.emotes.cta')}
        </Button>
      </>
    );
  }

  if (isSubscribed) {
    return (
      <Button
        primary
        disabled
        block
        className={classNames(
          'subs__emotes__footer__send subs__emotes__footer__send--full subs__emotes__footer__send--coming-soon',
        )}
      >
        {t('collection:subscriptionsAlert.emotes.askForActivation')}
      </Button>
    );
  }

  return (
    <Button
      primary
      onClick={handleClickCTA}
      className={classNames('subs__emotes__footer__send subs__emotes__footer__send--full')}
    >
      {t('collection:subscriptionsAlert.emotes.subscribe')}
    </Button>
  );
};
