import * as React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import { withTranslation, TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { overloadedContentActions, OpenOverloadedContent } from 'services/overloaded-content';
import { DialogBuyLootPacksPicker } from 'components/buy-loot-packs-picker';
import MobileNavItem from '../../MobileNavItem';
import theme from './buy-packs-button.scss';

const classNames = ClassNames(theme);

interface DispatchProps {
  openOverloadedContent: OpenOverloadedContent;
}

interface BuyPacksButtonProps extends TranslateInterface, DispatchProps {}

const BuyPacksButton = ({ openOverloadedContent, t }: BuyPacksButtonProps) => {
  const handleBuyPacks = () => {
    trackEventManager.buyPacksStarted({
      location: 'mobilePageMenu',
      type: null,
    });

    return openOverloadedContent({
      component: DialogBuyLootPacksPicker,
      wrapperProps: {
        type: 'large',
        opacity: true,
        overflowH: true,
        noCentered: true,
      },
      componentProps: {
        startLocation: 'mobilePageMenu',
      },
    });
  };

  return (
    <MobileNavItem
      data-testid="buy-packs-button"
      iconId="treasure"
      label={t('collection:userPageMobileNav.buyPacks')}
      cssClass={classNames('buy-packs-button')}
      onClick={handleBuyPacks}
    />
  );
};

export default compose<React.FunctionComponent>(
  connect(null, {
    openOverloadedContent: overloadedContentActions.openOverloadedContent,
  }),
  withTranslation('collection'),
)(BuyPacksButton);
