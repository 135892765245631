import { useEffect, FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { OpenOverloadedContent } from 'services/overloaded-content';
import { User } from 'model/indexTS';
import urlUtils from 'utils/urlUtils';
import { userSelectors } from 'services/user';
import { getUrlWithParams, pages, PUBLIC_PAGE_LOCATIONS } from 'services/routing';
import { Checkout } from 'scenes/loot-checkout';
import { DialogBuyLootPacksPicker } from 'components/buy-loot-packs-picker';

type OwnProps = {
  isUserAuthenticated: boolean;
  slug: string;
  openOverloadedContent: OpenOverloadedContent;
};

interface MapStateToProps {
  user: User | null;
  hasPage: boolean;
}

interface OpenCheckoutFromLoginProps extends OwnProps, MapStateToProps {}

export const ConnectedOpenCheckoutFromLogin = ({
  isUserAuthenticated,
  slug,
  user,
  hasPage,
  openOverloadedContent,
}: OpenCheckoutFromLoginProps): null => {
  const account = user?.settings?.account ?? {
    ACCEPTED_PRIVACY_POLICY: false,
    ACCEPTED_STREAMER_TERMS_AND_CONDITIONS: false,
  };
  const location = useLocation();
  const history = useHistory();

  const { ACCEPTED_PRIVACY_POLICY, ACCEPTED_STREAMER_TERMS_AND_CONDITIONS } = account;

  const userMustSign = !ACCEPTED_PRIVACY_POLICY || (hasPage && !ACCEPTED_STREAMER_TERMS_AND_CONDITIONS);

  useEffect(() => {
    const { loot, openAsGift, startLocation } = urlUtils.urlToParams(location.search) || {};

    if (startLocation === PUBLIC_PAGE_LOCATIONS.CHECKOUT_SELECT_COLLECTION) {
      history.replace({
        pathname: getUrlWithParams(pages.USER_PAGE, { slug }),
      });

      openOverloadedContent({
        component: DialogBuyLootPacksPicker,
        wrapperProps: {
          type: 'large',
          opacity: true,
          overflowH: true,
          noCentered: true,
        },
        componentProps: {
          openAsGift: Boolean(openAsGift),
        },
      });

      return;
    }

    if (!loot || userMustSign) {
      return;
    }

    history.replace({
      pathname: getUrlWithParams(pages.USER_PAGE, { slug }),
    });

    openOverloadedContent({
      component: Checkout,
      componentProps: {
        lootId: loot,
        isUserAuthenticated,
        slug,
        openAsGift: JSON.parse(openAsGift ?? 'false'),
        startLocation,
      },
      wrapperProps: {
        type: 'auth',
      },
    });
  }, [history, isUserAuthenticated, location.search, openOverloadedContent, slug, userMustSign]);
  return null;
};

const mapStateToProps = state => {
  return {
    user: userSelectors.user(state),
    hasPage: !userSelectors.showOnboarding(state),
  };
};

export const OpenCheckoutForTesting = ConnectedOpenCheckoutFromLogin;

export const OpenCheckoutFromLogin = compose<FunctionComponent<OwnProps>>(connect(mapStateToProps))(
  ConnectedOpenCheckoutFromLogin,
);
