import * as React from 'react';
import { GiftSubscriptionType } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import { RadioButtonIcon } from 'ui-library/radio-button-card/RadioButtonIcon';
import Icon from 'ui-library/icons/Icons';
import theme from './gift-options.scss';

const classNames = ClassNames(theme);

export interface RadioItemProps {
  label: string;
  selected?: boolean;
  value: GiftSubscriptionType;
  icon: string;
  onSelect: (value: GiftSubscriptionType) => void;
}

export const RadioItem = ({ label, value, icon, selected = false, onSelect }: RadioItemProps): JSX.Element => {
  return (
    <div className={classNames({ 'gift-options__radio--selected': selected })}>
      <label htmlFor={label} className={classNames('gift-options__radio__check')}>
        <input
          id={label}
          type="radio"
          checked={selected}
          value={value}
          onChange={() => {
            onSelect(value);
          }}
        />
        <span className="visuallyhidden">{value}</span>
      </label>
      <div>
        <RadioButtonIcon selectable checked={selected} />
      </div>
      <div>
        <div className={classNames('gift-options__radio__icon')}>
          <Icon id={icon} />
        </div>
        <div
          className={classNames({
            'gift-options__radio__label': true,
            'gift-options__radio__label--selected': selected,
          })}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
