import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import type { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import type { UserSet, Auctions } from 'model/indexTS';
import type { GetAchievements } from 'services/achievement';
import { SidebarOpenerContext } from 'contexts/sidebar-opener';
import { appSelectors } from 'services/app';
import { authSelectors } from 'services/auth';
import { pageSelectors, PageState, usePageActions } from 'services/page';
import { useSetsActions } from 'services/sets';
import { achievementActions } from 'services/achievement';
import { userSetsSelectors, useUserSetsSelectors } from 'services/user-sets';
import PublicUserPage from './PublicUserPage';

interface MapDispatchToProps {
  getAchievements: GetAchievements;
}

interface MapStateToProps {
  auctions?: Auctions;
  page: PageState;
  currentPathname: string;
  isUserAuthenticated: boolean;
  userSets?: UserSet[];
}

interface ConnectedPublicUserPageProps extends RouteConfigComponentProps, MapStateToProps, MapDispatchToProps {}

const ConnectedPublicUserPage = (props: ConnectedPublicUserPageProps) => {
  const { close } = useContext(SidebarOpenerContext);
  const { selectedUserSetId, marathonSpecialSet } = useUserSetsSelectors();
  const { changeSelectedSet } = useSetsActions();
  const { isUserAuthenticated, getAchievements, page, route, ...rest } = props;
  const { changePublicPageContext } = usePageActions();

  useEffect(() => {
    changePublicPageContext(true);

    return () => {
      changePublicPageContext(false);
    };
  }, [changePublicPageContext]);

  useEffect(() => {
    if (marathonSpecialSet && marathonSpecialSet._id !== selectedUserSetId && page.modules?.marathons.enabled) {
      changeSelectedSet(marathonSpecialSet._id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      getAchievements();
    }
  }, [getAchievements, isUserAuthenticated]);

  useEffect(() => {
    close();
    return () => {
      close();
    };
  }, [close]);

  if (!page.owner) {
    return null;
  }

  return (
    <PublicUserPage {...rest} route={route as RouteConfig} isUserAuthenticated={isUserAuthenticated} page={page} />
  );
};

const mapStateToProps = (state): MapStateToProps => {
  return {
    page: pageSelectors.page(state),
    currentPathname: appSelectors.currentPathname(state),
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    userSets: userSetsSelectors.userSets(state),
  };
};

export default connect<MapStateToProps, MapDispatchToProps>(mapStateToProps, {
  getAchievements: achievementActions.getAchievements,
})(ConnectedPublicUserPage);
