import { useContext } from 'react';
import Render from 'components/render';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { TranslateInterface } from 'utils/translation';
import { OpenOverloadedContent } from 'services/overloaded-content/typesTS';
import { useUserSetLootsSelectors } from 'services/user-set-loots';
import { ConnectedCraftingCard } from 'scenes/collection-craft-mode';
import { CraftingModeProviderContext } from 'contexts/crafting-mode-provider';
import { UserSet } from 'model/setsTS';
import { MOBILE_MENU_BREAKPOINT } from 'constants/layout';
import useMedia from 'hooks/use-media';
import { ClaimCommunityGift } from './components/claim-community-gift';
import { PublicUserPageNav } from './components/public-user-page-nav';
import { RedeemCouponDialog } from '../coupons-redemption';
import theme from './public-user-page.scss';
import { OpenCheckoutFromLogin } from './components/open-checkout-from-login';
import { OpenCardFromUrl } from './components/open-card-from-url';
import { MobileNav } from './components/mobile-nav';

interface OwnProps {
  _id: string;
  isUserAuthenticated: boolean;
  handleOpenLoots: () => void;
  openOverloadedContent: OpenOverloadedContent;
  route: RouteConfig;
  slug: string;
  userSets?: UserSet[];
}

interface ExtendedProps extends OwnProps, TranslateInterface {}

export const PublicUserPageCollections = ({
  _id,
  isUserAuthenticated,
  handleOpenLoots,
  openOverloadedContent,
  route,
  slug,
  t,
  userSets = [],
}: ExtendedProps): JSX.Element => {
  const { totalUnopenedLootsCount } = useUserSetLootsSelectors();
  const isMobileResolution = useMedia(`(max-width: ${MOBILE_MENU_BREAKPOINT}px)`);
  const { isCraftingModeOpen, craftingModeViewForCardId } = useContext(CraftingModeProviderContext);

  return (
    <>
      {userSets.length > 0 && (
        <Render.Client>
          <PublicUserPageNav t={t} onOpenLoots={handleOpenLoots} />
          <ClaimCommunityGift pageId={_id} slug={slug} t={t} />
          <RedeemCouponDialog />
        </Render.Client>
      )}
      <div className={theme.publicpage__content}>{renderRoutes(route.routes)}</div>
      {userSets.length > 0 && (
        <>
          <OpenCardFromUrl />
          <Render.Client>
            {craftingModeViewForCardId && <ConnectedCraftingCard cardId={craftingModeViewForCardId} />}
            <OpenCheckoutFromLogin
              isUserAuthenticated={isUserAuthenticated}
              slug={slug}
              openOverloadedContent={openOverloadedContent}
            />
            {isMobileResolution && !isCraftingModeOpen && (
              <MobileNav
                onOpenLoots={handleOpenLoots}
                openOverloadedContent={openOverloadedContent}
                userSets={userSets}
                unopenedLootsCount={totalUnopenedLootsCount}
                isUserAuthenticated={isUserAuthenticated}
                t={t}
              />
            )}
          </Render.Client>
        </>
      )}
    </>
  );
};
