import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import ClassNames from '@streamloots/classnames';
import { useTranslation } from 'utils/translation';
import { RenewalPeriod } from 'model/indexTS';
import { RadioInputButton, RadioInputButtonGroup } from 'ui-library/form-elements';
import { Badge } from 'ui-library/badge';
import theme from './months-selector.scss';

interface MonthsSelectorProps {
  renewalPeriod?: RenewalPeriod;
  onRenewalPeriodChange: (renewalPeriod: RenewalPeriod | undefined) => void;
  availableRenewalPeriods: RenewalPeriod[];
}

const classNames = ClassNames(theme);

export const MonthsSelector: FC<MonthsSelectorProps> = ({
  renewalPeriod,
  onRenewalPeriodChange,
  availableRenewalPeriods,
}: MonthsSelectorProps): JSX.Element => {
  const { t } = useTranslation();

  const renewalPeriodsByMonths = useMemo(() => {
    return availableRenewalPeriods.reduce((acc, period) => {
      return acc.set(period.totalPrepaidMonths.toString(), period);
    }, new Map());
  }, [availableRenewalPeriods]);

  return (
    <RadioInputButtonGroup
      label={t('pageSubscription:checkout.secondStep.months.title')}
      value={renewalPeriod?.totalPrepaidMonths.toString() ?? ''}
      onChange={evt => onRenewalPeriodChange(renewalPeriodsByMonths.get(evt.target.value))}
    >
      <div className={classNames('months-selector-option')}>
        <Badge
          className={classNames('months-selector-option__badge months-selector-option__badge--hidden')}
          label="&nbsp;"
          color="notification"
        />
        <RadioInputButton label={t('pageSubscription:checkout.secondStep.months.option', { count: 1 })} value="" />
      </div>

      {availableRenewalPeriods.map(period => (
        <div key={period.totalPrepaidMonths} className={classNames('months-selector-option')}>
          <Badge
            color="notification"
            className={classNames('months-selector-option__badge')}
            label={t('pageSubscription:checkout.secondStep.months.discount', {
              percentage: Math.round(period.discount * 100),
            })}
          />
          <RadioInputButton
            label={t('pageSubscription:checkout.secondStep.months.option', { count: period.totalPrepaidMonths })}
            value={period.totalPrepaidMonths.toString()}
          />
        </div>
      ))}
    </RadioInputButtonGroup>
  );
};
