import cookies from 'js-cookie';

export const LAST_CLAIM_COMMUNITY_GIFT_DATE_TIME = 'LAST_CLAIM_COMMUNITY_GIFT_DATE_TIME';
export const TIME_TO_CLAIM_GIFT_AGAIN = 180 * 1000; // 3 minutes

export const gitfCommunityCookieManager = {
  gitfClaimed(slug: string, lastClaimedDate: number): void {
    cookies.set(`${LAST_CLAIM_COMMUNITY_GIFT_DATE_TIME}-${slug}`, lastClaimedDate, {
      expires: 0.1,
    });
  },
  getNextClaimGiftAtTime(slug: string): number | null {
    try {
      const time = cookies.get(`${LAST_CLAIM_COMMUNITY_GIFT_DATE_TIME}-${slug}`);

      if (!time) {
        return null;
      }

      const parsedTime = parseInt(time);
      const canClaimGift = parsedTime + TIME_TO_CLAIM_GIFT_AGAIN;
      const nowTime = new Date().getTime();

      return canClaimGift < nowTime ? null : parsedTime;
    } catch {
      return null;
    }
  },
};
